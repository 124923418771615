import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { fetchCustomFields } from 'services/custom-field.service'

// Define the types for your initial state
interface customFieldContainerState {
  isLoading: boolean;
  isError: boolean;
  errMsg: string;
  collection: any;
}

const initialState:customFieldContainerState = {
  isLoading: true,
  isError: false,
  errMsg: "",
  collection: []
}

const customFieldContainerSlice = createSlice({
  name: 'customfield',
  initialState,
  /* eslint-disable no-param-reassign */
  reducers: {
    setDestroy() {
      return initialState
    },
    setLoading(state: customFieldContainerState, action: PayloadAction<boolean>)  {
      state.isLoading = action.payload
    },
    setError(state: customFieldContainerState, action: PayloadAction<{ error: string }>) {
      state.isLoading = false
      state.isError = true
      state.errMsg = action.payload.error
    },
    setData(state: customFieldContainerState, action: PayloadAction<any>) {
      state.collection = action.payload
      state.isLoading = false
    },
  },
})

const { actions, reducer } = customFieldContainerSlice

export const { setDestroy, setLoading, setError, setData } = actions
export default reducer

export const fetchCustomField = (companyId:string) => async (dispatch:any) => {
  dispatch(setLoading(true))
  try {
    const data = await fetchCustomFields(companyId)
    dispatch(setData(data))
  } catch (e) {
    const error = "not_fetch";
    dispatch(setError({ error }));
  } finally{
    dispatch(setLoading(false));
  }
}
