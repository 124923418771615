import React, { useState } from 'react';
import './styles.scss';
import { sections } from './orgChartData';
import { useNavigate, useParams } from "react-router-dom";
import dummy from '../../assets/images/dummy.svg';


function OrgCat({ companyList }) {
    const history = useNavigate();
    const handleCompanyRedirect = (id) => {
        if (id) history(`/app/${id}`);

    };
    const validCompnayIds = companyList.reduce((acc, curr) => {
        acc[curr.id] = curr;
        return acc;
    }, {});

    const checkCompanyAccess = (id) => {
        if (validCompnayIds.hasOwnProperty(id)) {
            return true
        }
        return false
    }


    return (
        <section className="main-section">
            <div className="container">
                <div className="followChart">

                    {/* Section One */}
                    <div className={`one common-class ${checkCompanyAccess(sections.one.id) ? 'cursor_pointer' : ''}`} onClick={() => handleCompanyRedirect(sections.one.id)}>
                        {sections.one.imgSrc ? <img style={{width: '358px'}} src={sections.one.imgSrc} alt="" className="logo" /> : <img src={dummy} alt="" className="logo" />}
                        <p></p>
                    </div>

                    {/* Section Two (Underlined) */}
                    <div className="two-underline">
                        <div className={`two common-class ${checkCompanyAccess(sections.twoUnderline.id) ? 'cursor_pointer' : ''}`} onClick={() => handleCompanyRedirect(sections.twoUnderline.id)}>
                            {sections.twoUnderline.imgSrc ? <img src={sections.twoUnderline.imgSrc} alt="" className="logo" /> : <img src={dummy} alt="" className="logo" />}
                            <p></p>
                        </div>
                    </div>

                    {/* Section Three */}
                    <div className="three common-class">
                        {sections.three.map((item, index) => (
                            <div key={index} className={index === 0 ? `textt ${checkCompanyAccess(sections.twoUnderline.id) ? 'cursor_pointer' : ''}` : `text ${checkCompanyAccess(sections.twoUnderline.id) ? 'cursor_pointer' : ''}`} onClick={() => handleCompanyRedirect(item.id)}>
                                {item.imgSrc ? <img src={item.imgSrc} alt="" className="logo" /> : <img src={dummy} alt="" className="logo" />}
                                <p></p>
                            </div>
                        ))}
                    </div>

                    {/* Section Four */}
                    <div className="for common-class">
                        {sections.four.map((item, index) => (
                            <div key={index} className={index === 0 ? `textt ${checkCompanyAccess(item.id) ? 'cursor_pointer' : ''}` : `text ${checkCompanyAccess(item.id) ? 'cursor_pointer' : ''}`} onClick={() => handleCompanyRedirect(item.id)}>
                                {item.imgSrc ? <img src={item.imgSrc} alt="" className="logo" /> : <img src={dummy} alt="" className="logo" />}
                                <p></p>
                            </div>
                        ))}
                    </div>

                    {/* Section Five */}
                    <div className="five common-class">
                        {sections.five.map((item, index) => (
                            <div key={index} className={index === 0 ? `textt ${checkCompanyAccess(item.id) ? 'cursor_pointer' : ''}` : `text ${checkCompanyAccess(item.id) ? 'cursor_pointer' : ''}`} onClick={() => handleCompanyRedirect(item.id)}>
                                {item.imgSrc ? <img src={item.imgSrc} alt="" className="logo" /> : <img src={dummy} alt="" className="logo" />}
                                <p></p>
                            </div>
                        ))}
                    </div>

                    {/* Section Six and Seven */}
                    <div className="doubleBox">
                        <div className="six common-class">
                            <div className={`text ${checkCompanyAccess(sections.six.id) ? 'cursor_pointer' : ''}`} onClick={() => handleCompanyRedirect(sections.six.id)}>
                                {sections.six.imgSrc ? <img src={sections.six.imgSrc} alt="" className="logo" /> : <img src={dummy} alt="" className="logo" />}
                                <p></p>
                            </div>
                        </div>
                        <div className="sevan common-class">
                            <div className="mix-box">
                                <div className="extra-line">
                                    <div className={`texttt ${checkCompanyAccess(sections.seven[0].id) ? 'cursor_pointer' : ''}`} onClick={() => handleCompanyRedirect(sections.seven[0].id)}>
                                        {sections.seven[0].imgSrc ? <img src={sections.seven[0].imgSrc} alt="" className="logo" /> : <img src={dummy} alt="" className="logo" />}
                                        <p></p>
                                    </div>
                                </div>
                                {sections.seven.slice(1).map((item, index) => (
                                    <div key={index} className={index === 0 ? `text ${checkCompanyAccess(item.id) ? 'cursor_pointer' : ''}` : `textt ${checkCompanyAccess(item.id) ? 'cursor_pointer' : ''}`} onClick={() => handleCompanyRedirect(item.path)}>
                                        {item.imgSrc ? <img src={item.imgSrc} alt="" className="logo" /> : <img src={dummy} alt="" className="logo" />}
                                        <p className='lastText_box'></p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    );
}

export default OrgCat;
