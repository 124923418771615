/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/prop-types */
import { useEffect } from "react";
import {
  Layout,
  Page,
  Card,
  InlineGrid,
  Text,
  IndexTable,
  BlockStack,
  ButtonGroup,
} from "@shopify/polaris";
import { useSelector, useDispatch } from "react-redux";
import { fetchCustomer, setDestroy } from "./customer-container.slice";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import CustomerDetails from 'components/customer/customer-details/customer-details'
import UploadFile from "features/upload-file/upload-file";
import Products from 'components/customer/products/products';
import Files from "features/files/files";
import "./style.scss";
import Invoices from "components/invoices/Invoices";
import BuildPdf from "components/customer/build-pdf/BuildPdf";
import Contacts from "components/contacts/Contacts";
import CustomerQuestionnaire from "components/customer-questionnaire/customer-questionnaire";

function CustomerContainer() {
  const { customer } = useSelector(
    (state) => state.customerReducer
  );
  
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { customerId, companyId } = useParams();

  const loadCustomerData = () => {
    dispatch(fetchCustomer({ companyId, customerId }));
  }

  useEffect(() => {
    loadCustomerData()

    return () => {
      setDestroy(true);
    };
  }, [customerId]);

  if (!customer) {
    return null
  }

  const finvaldaNode = (() => {
    const rowMarkup = Object.entries(customer.meta).map(([key, value], index) => {
      return (
        <IndexTable.Row id={key} key={key} position={index}>
          <IndexTable.Cell>
            <div>{key}</div>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <div>{value}</div>
          </IndexTable.Cell>
        </IndexTable.Row>);
    });

    return (
      <IndexTable
        resourceName={{
          singular: "customer",
          plural: "customers",
        }}
        itemCount={Object.entries(customer.meta).length}
        headings={[
          { title: t("title") },
          { title: t("value") },
        ]}
        selectable={false}
      >
        {rowMarkup}
      </IndexTable>
    )
  })()

  const handleOnFileChange = (data) => {

  }

  return (
    <Page
      fullWidth
      backAction={{content: 'Customers', url: `/app/${companyId}/customers`}}
      title={customer.name}
      subtitle={customer.address}
      // primaryAction={{ content: "Save" }}
    >
      <Layout>
        <Layout.Section>
          <BlockStack gap="500">
            <Card roundedAbove="sm">
              <div className="flex">
                <Text as="h2" variant="headingSm">
                  {t("files")}
                </Text>
                <div className="flex-grow"></div>
                <ButtonGroup>
                  <UploadFile onChange={handleOnFileChange} subject="customer" subjectId={customerId} />
                  <BuildPdf customerId={customerId} />
                </ButtonGroup>
              </div>
              <Files files={customer.files} />
            </Card>
            <Card roundedAbove="sm">
              <InlineGrid columns="1fr auto">
                <Text as="h2" variant="headingSm">
                  {t("products")}
                </Text>
              </InlineGrid>
              <Products products={customer.products} />
            </Card>
            <Card roundedAbove="sm">
              <InlineGrid columns="1fr auto">
                <Text as="h2" variant="headingSm">
                  {t("invoices")}
                </Text>
              </InlineGrid>
              <Invoices invoices={customer.invoices} />
            </Card>
          </BlockStack>
        </Layout.Section>
        <Layout.Section variant="oneThird">
          <BlockStack gap="500">
            <CustomerDetails customer={customer} onSubmit={loadCustomerData} />
            <CustomerQuestionnaire formKeyCreatedAt={customer.formKeyCreatedAt} customerId={customer.id} email={customer.email} formSignature={customer.formSignature} />
            <Contacts contacts={customer.contacts} customer={customer.id} />
            <Card roundedAbove="sm">
              <InlineGrid columns="1fr auto">
                <Text as="h2" variant="headingSm">
                  {t("finvalda")}
                </Text>
              </InlineGrid>
              {finvaldaNode}
            </Card>
          </BlockStack>
        </Layout.Section>
      </Layout>
    </Page>
  )
}

export default CustomerContainer
