import { BlockStack, Button, Card, InlineGrid, Text, TextField, ResourceList, ResourceItem, Avatar } from '@shopify/polaris';
import { sendCreateCustomerQuestionnaire } from "features/customers/customers.service";
import { useAppDispatch } from "hooks";
import { PlusIcon } from '@shopify/polaris-icons';
import { useParams } from "react-router-dom";
import { fetchCustomer } from 'containers/customer/customer-container.slice';
import PrettyDate from 'components/format/PrettyDate';

import { useTranslation } from "react-i18next";

const CustomerQuestionnaire = ({ customerId, formKeyCreatedAt, email, formSignature }: { customerId: string, formKeyCreatedAt: string | null, email: string | undefined, formSignature: any }) => {
  const { t } = useTranslation();
  const { companyId } = useParams();
  const dispatch = useAppDispatch();

  const handleCreate = async () => {
    try {
      await sendCreateCustomerQuestionnaire(companyId, customerId)
      dispatch(fetchCustomer({ customerId, companyId: companyId! }));
    } catch (e) {
      console.error('Error creating customer questionnaire', e);
    }
  }

  return (
    <Card roundedAbove="sm">
      <BlockStack gap="200">
        <InlineGrid columns="1fr auto">
          <Text as="h2" variant="headingSm">
          { t('customer_questionnaire') }
          </Text>
          {!formKeyCreatedAt && !formSignature && email && (
            <Button
              onClick={handleCreate}
              accessibilityLabel={ t('customer_questionnaire-generate') }
              icon={PlusIcon}
            >
              { t('customer_questionnaire-generate') }
            </Button>
          )}
        </InlineGrid>
        { formKeyCreatedAt && (
          <Text as="p" tone="success">
            {t('customer_questionnaire-generated')} <PrettyDate showTime={true} dateString={formKeyCreatedAt} />
          </Text>
        )}
        { formSignature && (<Text as="p" tone="success">
          {t('customer_questionnaire-signed')}<br/> {formSignature.signerFullName}<br/> <PrettyDate showTime={true} dateString={formSignature.datetime} />
        </Text>
        )}
      </BlockStack>
    </Card>
  )
}

export default CustomerQuestionnaire
