import axios from "axios";
import { API_URL } from "../../constants";

export const fetch = ({ filtersValue, customerId }) => {
  const url = `${API_URL}/orders/fetch`;
  return axios
    .post(url, { filtersValue, customerId })
    .then((response) => response.data);
};

export const post = (data) => {
  const url = `${API_URL}/orders/post`;
  return axios.post(url, data).then((response) => response.data);
};

export const fetchById = (orderId) => {
  const url = `${API_URL}/orders/fetchById/${orderId}`;
  return axios.get(url).then((response) => response.data);
};
