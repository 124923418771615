import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  ResourceList,
  ResourceItem,
  Text,
  Link
} from "@shopify/polaris";
import { useParams } from "react-router-dom";
import { setModalxComponent, setModalxComponentProps } from "features/modalx/modalx.slice";
import { API_URL } from "../../constants";
import PrettyDate from 'components/format/PrettyDate'

function Files({ files }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { companyId } = useParams();


  const handleOnClick = (fileId) => {
    dispatch(setModalxComponent("FileForm"))
    dispatch(setModalxComponentProps({ fileId, companyId }))
  }

  return <ResourceList
    resourceName={{singular: t('file'), plural: t('files')}}
    items={files}
    renderItem={(item) => {
      const { id, createdAt, title, name, category, user } = item;
      const downloadUrl = `${API_URL}/${companyId}/files/download/${id}`

      return (
        <ResourceItem
          id={id}
          onClick={(id) => handleOnClick(id)}
        >
          <div className="flex justify-space">
              <Text variant="bodyMd" fontWeight="bold" as="h3">
              [{category?.title || '-'}] - {name || title}
              </Text>
            <Link url={downloadUrl} target="_blank">
              {t('download')}
            </Link>
          </div>
          <div><PrettyDate dateString={createdAt} showTime={true} /> {user?.name}</div>
        </ResourceItem>
      );
    }}
  />
}

export default Files;

Files.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  files: PropTypes.array.isRequired,
};
