import UNDERHILL from '../../assets/images/UNDERHILL.png';
import MNPK from '../../assets/images/MNPK.png';
import eximag from '../../assets/images/eximag-logo_v2.png';
import UHBAGRO from '../../assets/images/UHB_AGRO.png';
import UHBSERVE from '../../assets/images/UHB_SERVE.png';
import UHBLOGO from '../../assets/images/UHB_LOGO.png';
import TRASKIJA from '../../assets/images/TRAŠKIJA.png';
import UHBFOOD from '../../assets/images/UHB_FOOD.png';
import UHBCHEM from '../../assets/images/UHB_CHEM.png';
import UHBGROUP from '../../assets/images/UHB_GROUP.png';


export const sections = {
    one: { id:'7a06b316-6350-4454-adea-8c176a27b872', imgSrc: UNDERHILL, text: 'Underhill Baltic, UAB.' },
    twoUnderline: { id:'726a4ffe-1e1b-4084-b976-285bedcd59e8', imgSrc: UHBLOGO, text: 'UHB, UAB.' },
    three: [
        { id:'2bc99788-d127-48e5-9296-8a4b6b05f789', imgSrc: UHBAGRO, text: 'UHB AGRO, UAB.' },
        { id:'26440f77-3230-46e7-8cd2-45749447ca40', imgSrc: eximag, text: 'EXIMAG, UAB.' }
    ],
    four: [
        { id:'ffc23cf6-1819-473c-8e8d-192f3e48d1e2', imgSrc: MNPK, text: 'MARIJAMPOLES NPK, UAB.' },
        { id:'8c1d50dc-85b8-48df-be85-fa680d698f9f', imgSrc: UHBSERVE, text: 'UHB SERVICE, UAB.' }
    ],
    five: [
        { id:'25c810b7-2313-4cd5-9ade-8a97c3ff93eb', imgSrc: TRASKIJA, text: 'TRASKIJA, UAB.' },
        { id:'a7ace0f3-8c39-4080-a5f0-ea9c4485d587', imgSrc: UHBFOOD, text: 'UHB FOOD, UAB.' }
    ],
    six: { id:'f1961398-f878-4078-b38d-837f7261b418', imgSrc: UHBCHEM, text: 'UHB CHEMICALS, UAB.' },
    seven: [
        { id:'', imgSrc: '', text: 'Uzbekistansas' },
        { id:'', imgSrc: '', text: 'Uzbekistansas' },
        { id:'eef6deb8-a9f6-4423-901b-71d73d7e00e2', imgSrc: '', text: 'TRADING HOUSE UZKIMYOIMPEKS- LITHUANIA, UAB.' }
    ]
};
