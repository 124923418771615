import axios from "axios";
import { API_URL } from "../../constants";

export const createFileTemplate = async (formData, companyId) => {
  const url = `${API_URL}/${companyId}/files/templates/create-file-template`;
  return axios.post(url, formData).then((response) => response.data);
}

export const updateFileTemplate = async (formData, companyId) => {
  const url = `${API_URL}/${companyId}/files/templates/update-file-template/${formData.id}`;
  return axios.patch(url, formData).then((response) => response.data);
}

export const fetchFileTemplateById = async (companyId, id) => {
  const url = `${API_URL}/${companyId}/files/templates/fetch-file-template-by-id/${id}`;
  return axios.get(url).then((response) => response.data);
}
 