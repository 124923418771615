import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { edit } from "../company-form/company-form.service";

const initialState = {
  isLoading: false,
  isError: false,
  isSuccessfullySaved: false,
  errMsg: null,
  successMessage: null,
};
const companyFormSlice = createSlice({
  name: "companyForm",
  initialState,
  /* eslint-disable no-param-reassign */
  reducers: {
    setLoading(state) {
      state.isLoading = true;
    },
    setError(state, action) {
      state.isLoading = false;
      state.isError = true;
      state.errMsg = action.payload.error;
    },
    setSuccessfullySaved(state, action) {
      state.isLoading = false;
      state.isError = false;
      state.errMsg = null;
      state.successMessage = action.payload;
    },
    destroy(state) {
      _.assign(state, initialState);
    },
  },
  /* eslint-enable */
});

// Extract the action creators object and the reducer
const { actions, reducer } = companyFormSlice;

// Extract and export each action creator by name
export const { setLoading, setError, setSuccessfullySaved, destroy } = actions;

// Export the reducer, either as a default or named export
export default reducer;

export const submit = (data) => async (dispatch) => {
  dispatch(setLoading());
  try {
    await edit(data);
    dispatch(setSuccessfullySaved("saved"));

    return true;
  } catch (e) {
    dispatch(setError("not_saved"));
    return false;
  }
};
