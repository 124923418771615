import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  FormLayout,
  TextField,
  Form,
  Button,
  InlineStack,
} from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import { submit } from "./file-form.slice";
import { fetchById } from "services/upload-file.service";
import FileCategorySelect from "components/file-category-select/file-category-select";
import Loading from "components/loading/Loading";
import CustomerSelect from 'components/customer-select/CustomerSelect';
import { post } from "features/customers/customers.service";
import _ from "lodash";
import DatePickerx from "features/datepicker/date-picker";
import './style.scss';
import UserSelect from 'features/user-select/user-select';
import CreateCustomField from 'components/custom-fields/custom-field';



function FileForm({ fileId, isCatRequired, onChange, companyId, projectId, customerId, isCustomerFormRequired, categoryId, parentFileId, isDateTo, isOptionalField }) {
  const dispatch = useDispatch();
  const [id, setId] = useState();
  const [name, setName] = useState('');
  const [category, setCategory] = useState(null);
  const [dateTo, setDateTo] = useState('');
  const [subject, setSubject] = useState('');
  const [subjectId, setSubjectId] = useState(null);
  const [customer, setCustomer] = useState();
  const [toggleBox, setToggelBox] = useState(false)
  const [relatedTo, setRelatedTo] = useState()
  const [number, setNumber] = useState()
  const [date, setDate] = useState()
  const [serial, setSerial] = useState('')
  const [assignedUser, setAssignedUser] = useState('')
  const [customFields, setCustomFields] = useState([])


  const { t } = useTranslation();
  const { isLoading } = useSelector((state) => state.fileFormReducer);
  const isFormValid = () => {
    if (isCustomerFormRequired && isCatRequired && (!customer || !category)) return false
    if (isCustomerFormRequired && customer) return true
    if (!isCustomerFormRequired && (category || !isCatRequired)) return true;
    return false
  }

  const handleFormSubmit = async () => {
    // convert customField data array to an object
    const customizedCustomFieldData = customFields.reduce((acc, field) => {
      acc[field.code] = field.value;
      return acc;
    }, {});

    const formData = {
      id,
      name,
      category,
      dateTo,
      subject,
      subjectId: isCustomerFormRequired ? customer : subjectId,
      customerId,
      companyId,
      projectId,
      isCustomerFormRequired,
      relatedTo: parentFileId ? parentFileId : relatedTo,
      number,
      date,
      serial,
      user: assignedUser ? assignedUser : null,
      customFields: customizedCustomFieldData
    };

    await dispatch(submit(formData));
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const { name, category, dateTo, subject, subjectId, relatedTo, number, date, serial, user, customFields } = await fetchById(fileId, companyId);
        setName(name)
        setCategory(category)
        setDateTo(dateTo)
        setSubject(subject)
        setSubjectId(subjectId)
        category ? setCategory(category?.id) : setCategory(categoryId);
        setRelatedTo(relatedTo)
        setNumber(number)
        setDate(date || new Date())
        setSerial(serial)
        setAssignedUser(user.id)
        setCustomFields(customFields)
        if (isCustomerFormRequired) {
          setCustomer(subjectId)
          setSubject('customer')
          setSubjectId(customer)
        }
      } catch (e) {
        // console.error(e);
      }
    }
    if (fileId) {
      setId(fileId)
      fetchData();
    }
  }, [fileId]);

  if (isLoading || !subject) {
    return <Loading />
  }

  const handleSwitchIpBox = () => {
    setToggelBox(!toggleBox)
    setCustomer()
  }

  const handleCreateCustomer = async () => {
    if (toggleBox) {
      const formData = {
        name: customer,
        companyId,
        address: "",
        bankIban: "",
        bankName: "",
        companyCode: "",
        contactPerson: "",
        customerId: "",
        email: "",
        fine: "",
        maxAllowedDebt: "",
        paymentDelay: "",
        phone: "",
        prepaymentPercentage: "",
        vatCode: ""
      }
      const result = await post(companyId, formData);
      setToggelBox(!toggleBox)
      setCustomer(_.get(result, "createdCustomer.id"))
    }
  }

  const handleDueDateChange = ((value) => setDateTo(value));

  const handleCustomField = (value, code) => {
    setCustomFields((prevFields) =>
      prevFields.map((field) =>
        field.code === code ? { ...field, value } : field
      )
    );
  };

  return (
    <div className="user-form">
      <Form className="user-form_form" onSubmit={handleFormSubmit}>
        <FormLayout>
          <TextField
            disabled={isLoading}
            label={t("file_name")}
            type="text"
            autoComplete="off"
            value={name}
            onChange={setName}
          />
          {isCatRequired ?
            <FileCategorySelect
              onChange={setCategory}
              value={category}
              subject={subject}
              isRequired={true}
            /> :
            <></>
          }
          {isCustomerFormRequired && (
            <>
              {!toggleBox && (
                <>
                  <span onClick={handleSwitchIpBox}><Button>{t('create_customer')}</Button></span>
                  <CustomerSelect
                    value={customer}
                    onChange={setCustomer}
                  />
                </>
              )}
              {toggleBox && (
                <>
                  <span onClick={handleSwitchIpBox}><Button>{t('select_existing_customer')}</Button></span>
                  <TextField
                    label="customer"
                    value={customer}
                    onChange={setCustomer}
                    autoComplete="off"
                  />
                  <div className="marginTop">
                    <Button loading={isLoading} disabled={!isFormValid()} onClick={handleCreateCustomer}>
                      {t("save_customer")}
                    </Button>
                  </div>
                </>
              )}
            </>
          )}
          {isOptionalField &&
            <>
              <UserSelect
                onChange={setAssignedUser}
                value={assignedUser}
              />
              <div>
                <TextField
                  label="Number"
                  type="number"
                  value={number}
                  onChange={setNumber}
                  autoComplete="off"
                />
              </div>
              <div>
                <TextField
                  label="Serial"
                  value={serial}
                  onChange={setSerial}
                  autoComplete="off"
                />
              </div>
            </>
          }

          <InlineStack align="space-between">
            {isOptionalField &&
              <DatePickerx
                name="dueDate"
                title={t("date")}
                onChange={setDate}
                selected={date}
              />
            }
            {isDateTo &&
              <DatePickerx
                name="dueDate"
                title={t("dateTo")}
                onChange={handleDueDateChange}
                selected={dateTo}
              />
            }
          </InlineStack>
          {isOptionalField &&
            <CreateCustomField
              type='file'
              fetchId={id}
            />
          }
          <div className="column-2">
            {
              isOptionalField && customFields?.map((field) => {
                return (
                  <div style={{ width: "48%" }}>
                    <TextField
                      key={field.code}
                      label={field.label}
                      value={field?.value}
                      onChange={(value) => handleCustomField(value, field.code)}
                      autoComplete="off"
                    />
                  </div>
                )
              })
            }
          </div>

          
          <Button loading={isLoading} disabled={!isFormValid() || toggleBox} submit>
          {t("save")}
          </Button>

        </FormLayout>
      </Form>
    </div>
  );
}

export default FileForm;
