import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "configureStore";
import {
  FormLayout,
  TextField,
  Form,
  Button,
  Banner,
  Select,
} from "@shopify/polaris";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { fetchCustomField } from "containers/custom-field/custom-field-container.slice";
import {
  createCustomField,
  updateCustomField,
  fetchCustomFieldById,
} from "services/custom-field.service";

interface CustomFieldFormProps {
  // onSubmit?: (result: any) => void;
  id?: string;
}

const CustomFieldForm: React.FC<CustomFieldFormProps> = ({ id }) => {
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();
  const { companyId } = useParams<{ companyId: string }>();
  const [label, setLabel] = useState<string>("");
  const [code, setCode] = useState<string>("");
  const [type, setType] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errMsg, setErrMsg] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleFormSubmit = async () => {
    const formData = {
      id,
      label,
      type,
      code,
    };
    const action = id ? updateCustomField : createCustomField;
    try {
      setIsLoading(true);
      await action(formData, companyId);
      if (companyId) dispatch(fetchCustomField(companyId));
      setSuccessMessage("ok");
      setIsLoading(false);
    } catch (e) {
      setErrMsg("error");
      setIsLoading(false);
    }
  };

  const typeOptions = [
    { label: " - ", value: "" },
    { label: t("files"), value: "file" },
    { label: t("user"), value: "user" },
    { label: t("customer"), value: "customer" },
  ];

  const fetchData = async (id: string) => {
    const data = await fetchCustomFieldById(companyId!, id);
    setLabel(data.label);
    setCode(data.code);
    setType(data.type);
  };

  useEffect(() => {
    if (id) {
      fetchData(id);
    }
  }, [id]);

  return (
    <Form onSubmit={handleFormSubmit}>
      <FormLayout>
        <div className="form-warnings">
          {errMsg && (
            <div>
              <Banner tone="critical">{t(errMsg)}</Banner>
            </div>
          )}
          {successMessage && (
            <div>
              <Banner tone="info">{t(successMessage)}</Banner>
            </div>
          )}
        </div>
        <TextField
          label={t("custom_field_label")}
          id="label"
          name="label"
          value={label}
          onChange={(value) => setLabel(value)}
          autoComplete="off"
        />
        <TextField
          label={t("custom_field_code")}
          id="code"
          name="code"
          value={code}
          onChange={(value) => setCode(value)}
          autoComplete="off"
        />
        <Select
          label={t("custom_field_type")}
          options={typeOptions}
          onChange={(value) => setType(value)}
          value={type}
        />
        <Button
          onClick={handleFormSubmit}
          disabled={!label || !code || !type}
          loading={isLoading}
        >
          {t("save")}
        </Button>
      </FormLayout>
    </Form>
  );
};

export default CustomFieldForm;
