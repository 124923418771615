import axios from "axios";
import { API_URL } from "../../constants";

export const fetch = (filtersValue) => {
  const url = `${API_URL}/tasks/fetch`;
  return axios.post(url, { filtersValue }).then((response) => response.data);
};

export const create = (data) => {
  const url = `${API_URL}/tasks/create`;
  return axios.post(url, data).then((response) => response.data);
};
