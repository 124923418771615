import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import {
  FormLayout,
  TextField,
  Form,
  Button,
  Banner,
  Checkbox,
  Select,
  Page,
} from "@shopify/polaris";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import VatSelect from "../vat-select/vat-select";
import { submit, setError, destroy } from "./product-form.slice";
import { fetchById } from "../products/products.service";
import "./style.scss";

function ProductForm({ onSubmit, isInPopover }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { productId } = useParams();
  const { isLoading, errMsg, successMessage } = useSelector(
    (state) => state.productFormReducer
  );
  const [title, setTitle] = useState("");
  const [titleError, setTitleError] = useState("");
  const [sku, setSku] = useState("");
  const [skuError, setSkuError] = useState("");
  const [description, setDescription] = useState("");
  const [unitPrice, setUnitPrice] = useState("");
  const [type, setType] = useState("product");
  const [isActive, setIsActive] = useState(true);
  const [vat, setVat] = useState("");

  const handleFormSubmit = async () => {
    const formData = {
      productId,
      title,
      sku,
      description,
      unitPrice,
      type,
      isActive,
      vat,
    };

    const id = await dispatch(submit(formData));
    onSubmit(id);
  };

  const handleTitleChange = (value) => {
    setTitle(value);

    if (!value) {
      setTitleError("field_is_required");
    } else {
      setTitleError("");
    }
  };

  const handleSkuChange = (value) => {
    setSku(value);

    if (!value) {
      setSkuError("field_is_required");
    } else {
      setSkuError("");
    }
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const { productEntity } = await fetchById(productId);
        setTitle(productEntity.title);
        setSku(productEntity.sku);
        setDescription(productEntity.description);
        setUnitPrice(productEntity.unitPrice);
        setType(productEntity.type);
        setVat(productEntity.vat);
      } catch (e) {
        dispatch(setError("error_getting_data"));
      }
    }

    if (productId) {
      fetchData();
    }

    return () => {
      dispatch(destroy());
    };
  }, [productId, dispatch]);

  return (
    <Page fullWidth title={t("product")}>
      <div className="product-form">
        <Form className="product-form_form">
          <FormLayout>
            <div className="form-warnings">
              {errMsg && (
                <div className="">
                  <Banner status="critical">{t(errMsg)}</Banner>
                </div>
              )}
              {successMessage && (
                <div className="">
                  <Banner status="info">{t(successMessage)}</Banner>
                </div>
              )}
            </div>
            <TextField
              label={t("title")}
              id="title"
              name="title"
              value={title}
              error={t(titleError)}
              onChange={handleTitleChange}
              requiredIndicator
            />
            <Checkbox
              label={t("is_active")}
              checked={isActive}
              onChange={setIsActive}
            />
            <TextField
              label={t("sku")}
              id="sku"
              name="sku"
              value={sku}
              error={t(skuError)}
              onChange={handleSkuChange}
              requiredIndicator
            />
            <TextField
              label={t("description")}
              id="description"
              name="description"
              value={description}
              onChange={setDescription}
            />
            <TextField
              label={t("unitPrice")}
              id="unitPrice"
              name="unitPrice"
              value={unitPrice}
              onChange={setUnitPrice}
            />
            <Select
              label={t("type")}
              value={type}
              options={[
                { label: t("product"), value: "product" },
                { label: t("service"), value: "service" },
              ]}
              onChange={setType}
            />
            <VatSelect
              label="vat"
              value={vat}
              isRequired
              onChange={setVat}
              isInPopover={isInPopover}
            />
            <Button
              primary
              onClick={handleFormSubmit}
              disabled={!title || !sku || !vat}
              loading={isLoading}
            >
              {t("save")}
            </Button>
          </FormLayout>
        </Form>
      </div>
    </Page>
  );
}

export default ProductForm;

ProductForm.propTypes = {
  onSubmit: PropTypes.func,
  isInPopover: PropTypes.bool,
};

ProductForm.defaultProps = {
  onSubmit: () => {},
  isInPopover: false,
};
