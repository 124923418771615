import React, { useEffect, useState, useCallback } from "react";
import _ from "lodash";
import { Select, Popover, Button } from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import { Props, VatOption } from "./types";
import VatForm from "../vat-form/vat-form";
import { fetch as fetchVats } from "../vats/vats.service";

function VatSelect({ label, value, onChange, isRequired, isInPopover }: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [vatOptions, setVatsOptions] = useState<Array<VatOption>>([]);
  const { t } = useTranslation();
  const [error, setError] = useState("");
  const [isActiveVatForm, setIsActiveVatForm] = useState(false);
  const toggleActiveVatForm = useCallback(
    () => setIsActiveVatForm((isActiveVatForm) => !isActiveVatForm),
    []
  );
  const [selected, setSelected] = useState("");

  const fetchData = useCallback(
    async (id?: string) => {
      try {
        setIsLoading(true);

        const { vatsCollection } = await fetchVats();
        const vatOptions: Array<VatOption> = _.map(
          vatsCollection,
          ({ id, percent }) => ({ label: percent, value: id })
        );

        vatOptions.unshift({ label: "-", value: "" });

        setVatsOptions(vatOptions);
        if (id) {
          setSelected(id);
          onChange(id);
        }
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
      }
    },
    [onChange]
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleChange = (val: string) => {
    if (isRequired && !val) {
      setError("field_is_required");
    } else {
      setError("");
    }

    onChange(val);
  };

  const activator = (
    <Button onClick={toggleActiveVatForm}>
      {t("create_vat")}
    </Button>
  );

  return (
    <div className="vat-select">
      <Select
        label={label ? t(label) : ""}
        options={vatOptions}
        onChange={handleChange}
        value={selected || value}
        disabled={isLoading}
        requiredIndicator={isRequired}
        error={t(error)}
      />
      {!isInPopover && (
        <div className="vat-select__add">
          <Popover
            active={isActiveVatForm}
            activator={activator}
            autofocusTarget="first-node"
            onClose={toggleActiveVatForm}
          >
            <VatForm onSubmit={(id) => fetchData(id)} />
          </Popover>
        </div>
      )}
    </div>
  );
}

export default VatSelect;
