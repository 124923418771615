/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/prop-types */
import { useEffect } from "react";
import {
  Page,
  Card,
} from "@shopify/polaris";
import { useSelector, useDispatch } from "react-redux";
import { fetchFilesCategories, setDestroy } from "./files-categories-container.slice";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import DataTable from "components/datatable/Datatable"
import { setModalxComponent, setModalxComponentProps } from "features/modalx/modalx.slice";

function FilesCategoriesContainer() {
  const { collection } = useSelector(
    (state) => state.filesCategoriesReducer
  );
  
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { companyId } = useParams();

  const fetchData = (companyId) => {
    dispatch(fetchFilesCategories(companyId));
  }

  const handleCreateFilesCategory = () => {
    dispatch(setModalxComponent("FilesCategoryForm"));
    // dispatch(setModalxComponentProps({ onSubmit: onFormSubmit }))
  };

  const onRowClick = ({ id }) => {
    dispatch(setModalxComponent("FilesCategoryForm"));
    dispatch(setModalxComponentProps({ id }))
  }

  useEffect(() => {
    fetchData(companyId)

    return () => {
      setDestroy(true);
    };
  }, [companyId]);

  if (!companyId || !collection) {
    return null
  }

  return (
    <Page
      fullWidth
      primaryAction={{
        content: t("files_categories_create"),
        onAction: handleCreateFilesCategory,
      }}
      title={t("files_categories")}
    >
      <Card>
        <DataTable
          collection={collection}
          columns={[
            { key: '#' },
            { key: 'subject', name: t('files_categories_subject') },
            { key: 'title', name: t('files_categories_title'), onClick: onRowClick},
          ]}
        />
      </Card>
    </Page>
  )
}

export default FilesCategoriesContainer
