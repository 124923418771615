import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { post } from "../orders/orders.service";
import { fetchOrders } from "../orders/orders.slice";

const initialState = {
  isLoading: false,
  isError: false,
  isSuccessfullySaved: false,
  errMsg: null,
  successMessage: null,
};
const orderFormSlice = createSlice({
  name: "ordersForm",
  initialState,
  /* eslint-disable no-param-reassign */
  reducers: {
    setLoading(state) {
      state.isLoading = true;
    },
    setError(state, action) {
      state.isLoading = false;
      state.isError = true;
      state.errMsg = action.payload;
    },
    setSuccessfullySaved(state, action) {
      state.isLoading = false;
      state.isError = false;
      state.errMsg = null;
      state.successMessage = action.payload;
    },
    destroy(state) {
      _.assign(state, initialState);
    },
  },
  /* eslint-enable */
});

// Extract the action creators object and the reducer
const { actions, reducer } = orderFormSlice;

// Extract and export each action creator by name
export const { setLoading, setError, setSuccessfullySaved, destroy } = actions;

// Export the reducer, either as a default or named export
export default reducer;

export const submit = (data) => async (dispatch) => {
  dispatch(setLoading());
  try {
    const dateSplited = data.date.split("-");
    const year = dateSplited[0];
    const monthIndex = parseInt(dateSplited[1]) - 1;
    const day = dateSplited[2];
    data.date = new Date(year, monthIndex, day).toISOString();

    const expiresSplited = data.expires.split("-");
    const expiresYear = expiresSplited[0];
    const expiresMonthIndex = parseInt(expiresSplited[1]) - 1;
    const expiresDay = expiresSplited[2];
    data.expires = new Date(
      expiresYear,
      expiresMonthIndex,
      expiresDay
    ).toISOString();

    await post(data);
    dispatch(fetchOrders());
    dispatch(setSuccessfullySaved("saved"));

    return true;
  } catch (e) {
    if (_.get(e.response, "data.isNumberValid") === false) {
      dispatch(setError(`order_with_provided_number_allready_exist`));
    } else {
      dispatch(setError("unexpected_error"));
    }

    return false;
  }
};
