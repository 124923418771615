import { useState } from 'react';
import {
    TextField,
    Button,
    InlineStack,
} from "@shopify/polaris";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CustomFieldSelect from 'components/custom-field-select/CustomFiledSelect';
import './style.scss';
import {
    createCustomField
} from "services/custom-field.service";
import { fetchCustomField } from 'containers/custom-field/custom-field-container.slice'
import _ from "lodash";
import { fetchFileDetails } from "containers/file/file.Slice";  



const CreateCustomField = ({ type, fetchId }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { companyId } = useParams();
    const [label, setLabel] = useState('')
    const [code, setCode] = useState()
    const [isFormVisible, setIsFormVisible] = useState(false)

    const handleFormSubmit = async () => {
        const formData = {
            label,
            type,
            code,
        };
        try {
            // setIsLoading(true);
            const result = await createCustomField(formData, companyId);
            switch(type){
                case 'file':
                    dispatch(fetchFileDetails({fileId:fetchId, companyId }))
                    break
                case 'user':
                    break
                case 'customer':
                    break
                default:
                    break;
            }
            setIsFormVisible(!isFormVisible)
            setLabel('')
            setCode('')
        } catch (e) {
        }
    }

    const handleSwitchIpBox = () => {
        setIsFormVisible(!isFormVisible)
    }

    return (
        <div style={{ marginTop: '5px' }}>

            <span onClick={handleSwitchIpBox}><Button>{t('add_Field')}</Button></span>
            {isFormVisible && (
                <div style={{ marginTop: '5px' }}>
                    <InlineStack align='space-between'>
                        <div className='input_box_size'>
                            <TextField
                                label={t("custom_field_label")}
                                id="label"
                                name="label"
                                value={label}
                                onChange={(value) => setLabel(value)}
                                autoComplete="off"
                            />
                        </div>
                        <div className='input_box_size'>
                            <TextField
                                label={t("custom_field_code")}
                                id="code"
                                name="code"
                                value={code}
                                onChange={(value) => setCode(value)}
                                autoComplete="off"
                            />
                        </div>
                        <span style={{ marginTop: '25px' }}><Button
                            onClick={handleFormSubmit}
                            disabled={!label || !code || !type}
                        // loading={isLoading}
                        >
                            {t("save_field")}
                        </Button></span>
                    </InlineStack>
                </div>
            )}
        </div>
    );
};

export default CreateCustomField;