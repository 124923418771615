/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/prop-types */
import { useEffect } from "react";
import {
  Page,
  Text,
  BlockStack,
  InlineStack,
  InlineGrid,
  Scrollable,
  Button,
} from "@shopify/polaris"
import {
  MenuHorizontalIcon
} from '@shopify/polaris-icons';
import { useAppSelector, useAppDispatch } from "hooks"
import { fetchProjectBoard, setDestroy, setFilterValue } from "./project-board-container.slice"
import { setModalxComponent, setModalxComponentProps } from "components/modalx/modalx.slice";
import ProjectContainer from "containers/project/project-container";
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import UserSelect from "features/user-select/user-select"
import "./style.scss"
import Loading from 'components/loading/Loading'

function ProjectBoardContainer() {
  const { projectBoard, isLoading, filtersValue } = useAppSelector(
    (state) => state.projectBoardReducer
  );
  
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { projectBoardId, companyId } = useParams();

  useEffect(() => {
    const loadProjectBoardData = (projectBoardId) => {
      dispatch(fetchProjectBoard(companyId, projectBoardId, filtersValue));
    }

    loadProjectBoardData(projectBoardId)

    return () => {
      setDestroy(true);
    };
  }, [companyId, dispatch, projectBoardId, filtersValue]);

  if (!projectBoard) {
    return <Loading />
  }

  const columnsNode = projectBoard?.columns?.map((column, indexColumn) => {
    const projectsNode = column?.projects?.map((project, index) => {
      return (
        <ProjectContainer key={index} project={project} />
      )
    })
    return (
      <BlockStack key={indexColumn} className="project-board-column">
        <InlineStack>
          <Text variant="headingMd" as="h4">
            {column.name}
          </Text>
          <div className="flex-grow"></div>
          <Button onClick={() => handleEditColumn(column.id)} icon={MenuHorizontalIcon} variant="plain"></Button>
        </InlineStack>
        {projectsNode}
      </BlockStack>
    )
  })

  const handleCreateProject = () => {
    dispatch(setModalxComponent("ProjectForm"));
    dispatch(setModalxComponentProps({ projectBoardId }));
  }

  const handleCreateProjectBoardColumn = () => {
    dispatch(setModalxComponent("ProjectBoardColumnForm"));
    dispatch(setModalxComponentProps({ projectBoardId }));
  }

  const handleEditColumn = (id) => {
    dispatch(setModalxComponent("ProjectBoardColumnForm"));
    dispatch(setModalxComponentProps({ projectBoardId, id }));
  }

  const handleFilterChange = (value, key) => {
    dispatch(setFilterValue({ key, value }));
  };


  return (
    <Page
      fullWidth
      title={projectBoard.name}
      // subtitle={customer.address}
      primaryAction={{
        content: t("project-create"),
        onAction: handleCreateProject,
      }}
      secondaryActions={[
        {
          content: t("project-board-column-create"),
          onAction: handleCreateProjectBoardColumn,
        },
      ]}
    >
      <div>
        <InlineStack>
          <UserSelect
            value={filtersValue.user}
            name="user"
            onChange={handleFilterChange}
            isRequired={false}
            allowMultiple={true}
          />
          {isLoading && <Loading size="small" />}
        </InlineStack>
      </div>
      <Scrollable vertical={false} horizontal={true} className="layout-row" shadow focusable>
        {columnsNode}
      </Scrollable>
    </Page>
  )
}

export default ProjectBoardContainer
