/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/prop-types */
import { Modal, LegacyStack } from "@shopify/polaris"
import { useSelector, useDispatch } from "react-redux"
import UserForm from "../user-form/user-form"
import CustomerForm from "../customer-form/customer-form"
import CompanyCreate from "../company-create/company-create"
import TaskCreate from "../task-create/task-create"
import OrderForm from "../order-form/order-form"
import VatForm from "../vat-form/vat-form"
import ProductForm from "../product-form/product-form"
import FilesCategoryForm from "containers/files-category-form/files-category-form"
import FilesTemplateForm from "containers/files-template-form/files-template-form"
import ProjectBoardForm from 'containers/project-board-form/project-board-form'
import ProjectBoardColumnForm from 'containers/project-board-column-form/project-board-column-form'
import ProjectForm from 'containers/project-form/project-form'
import ProjectFormView from 'containers/project-view-form/project-view'
import BuildPdfForm from "components/customer/build-pdf/BuildPdfForm"
import FileForm from 'containers/file-form/file-form'
import ContactForm from 'containers/contact-form/contact-form'
import Loading from 'components/loading/Loading'
import { setIsModalxOpen } from "./modalx.slice"
import AddFilesCategoryForm from 'containers/files-categories-add-form/files-category-form';
// import './style.scss';
import CustomFieldForm from 'containers/custom-field-form/custom-field-form';

function Modalx() {
  const {
    title,
    isOpen,
    component,
    primaryAction,
    secondaryActions,
    size,
    componentProps,
  } = useSelector((state) => state.modalxReducer);
  const dispatch = useDispatch();

  const closeModal = () => {
    dispatch(setIsModalxOpen(false));
  };

  const components = {
    CompanyCreate,
    TaskCreate,
    UserForm,
    CustomerForm,
    OrderForm,
    VatForm,
    ProductForm,
    ProjectForm,
    FileForm,
    Loading,
    FilesCategoryForm,
    FilesTemplateForm,
    BuildPdfForm,
    ProjectBoardForm,
    ProjectBoardColumnForm,
    ContactForm,
    ProjectFormView,
    AddFilesCategoryForm,
    CustomFieldForm
  };

  const ComponentNode = components[component];
  if (!ComponentNode) return null;
  return (
    <Modal
      title={title || null}
      primaryAction={primaryAction || null}
      secondaryActions={secondaryActions || null}
      open={isOpen}
      onClose={closeModal}
      large={size === "large"}
      small={size === "small"}
    >
      <Modal.Section>
        <LegacyStack vertical>
          <ComponentNode {...componentProps} />
        </LegacyStack>
      </Modal.Section>
    </Modal>
  );
}

export default Modalx;
