import { Routes, Route, Navigate } from "react-router-dom";
import { Frame } from "@shopify/polaris";
import AppLayout from "./pages/app/app-layout";
import LoginPage from "./pages/auth/login/login";
import RegPage from "./pages/auth/reg/reg-page";
import RestorePage from "./features/restore-page/restore-page";
import Users from "./features/users/users";
import UserForm from "./features/user-form/user-form";
import UserContainer from "containers/user/user-container";
import Customers from "./features/customers/customers";
import "App.scss";
import Dashboard from "./pages/app/dashboard/Dashboard";
import CompanyDashboard from './pages/app/dashboard/CompanyDashboard';
import CustomerContainer from "containers/customer/customer-container";
import Products from 'containers/products/products'
import FilesCategoriesContainer from "containers/files-categories/files-categories-container"
import FilesTemplatesContainer from "containers/files-templates/files-templates-container"
import Reservations from "containers/reservations/reservations";
import ProjectBoards from "containers/project-boards/project-boards"
import ProjectBoardContainer from "containers/project-board/project-board-container";
import ProductContainer from 'containers/product/product-container'
import CustomerForm from "pages/customer-form/customer-form";
import ProjectContainer from "containers/project-view-container/project-view-container";
import Documents from "containers/document/category-tree/tree-container";
import FileContainer from "containers/file/file-view-container";
import CustomFieldContainer from "containers/custom-field/custom-field-container";

function App() {
  return (
    <Frame>
      <Routes>
        <Route path="/" element={<Navigate to="/app" />} />
        <Route path="auth/login" element={<LoginPage />} />
        <Route path="auth/signup" element={<RegPage />} />
        <Route path="auth/restore" element={<RestorePage />} />
        <Route path="customer-form/:formKey" element={<CustomerForm />} />
        <Route path="app" element={<AppLayout />}>
          <Route path=":companyId" element={<Navigate to="customers" replace />} />
          <Route path=":companyId" element={<Dashboard />}>
            <Route path="company" element={<CompanyDashboard />} />
            <Route path="customers" element={<Customers />}>
              <Route path=":customerId" element={<CustomerContainer />} />
            </Route>
            <Route path="products" element={<Products />} >
              <Route path=":productId" element={<ProductContainer />} />
            </Route>
            <Route path="reservations" element={<Reservations />} />
            <Route path="project-boards" element={<ProjectBoards />} />
            <Route path="project-boards/:projectBoardId" element={<ProjectBoardContainer />} />
            <Route path="project-boards/:projectBoardId/project/:projectId" element={<ProjectContainer />} />
            <Route path="users" element={<Users />}/>
            <Route path="users/:userId" element={<UserContainer />} />
            <Route path="files/categories" element={<FilesCategoriesContainer />} />
            <Route path="files/templates" element={<FilesTemplatesContainer />} />
            <Route path="documents/:catId/:fileId" element={<FileContainer />} />
            <Route path="documents/*" element={<Documents />} />
            <Route path="customfields" element={<CustomFieldContainer />} />
          </Route>
        </Route>
      </Routes>
    </Frame>
  );
}

export default App;
