import { useSelector, useDispatch } from "react-redux";
import changeHandler from "../../common/form/changeHandler";
import submitHandler from "../../common/form/submitHandler";
import {
  FormLayout,
  TextField,
  Form,
  Card,
  TextContainer,
} from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import { setFormData, submit } from "./restore-page.slice";
import "./restore-page.scss";

function Restore() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { formControls, isLoading, isSuccessful, isFormValid } = useSelector(
    (state) => state.restorePageReducer
  );
  const handleFormChange = (formData) => dispatch(setFormData(formData));
  const handleFormSubmit = async (formData) => {
    await dispatch(submit(formData));
  };

  if (isSuccessful) {
    return (
      <div className="restore-page">
        <Card title={t("restore_page")} sectioned>
          <TextContainer>
            <p>{t("password_restore_email")}</p>
          </TextContainer>
        </Card>
      </div>
    );
  }
  return (
    <div className="reg-page">
      <Card
        title={t("restore")}
        primaryFooterAction={{
          content: t("restore"),
          loading: isLoading,
          disabled: !isFormValid,
          onAction: submitHandler(formControls, handleFormSubmit),
        }}
        secondaryFooterActions={[
          { content: t("password_restore"), url: "/auth/restore" },
          { content: t("signup"), url: "/auth/signup" },
        ]}
        sectioned
      >
        <Form
          className="rrestore-page_form"
          onSubmit={submitHandler(formControls, handleFormSubmit)}
        >
          <FormLayout>
            <TextField
              label={t("Email")}
              autoComplete="off"
              type="email"
              id="email"
              value={formControls.email.value}
              onChange={changeHandler(formControls, handleFormChange)}
            />
          </FormLayout>
        </Form>
      </Card>
    </div>
  );
}

export default Restore;
