import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import {
  FormLayout,
  TextField,
  Form,
  Button,
  Banner,
  Page,
} from "@shopify/polaris";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { submit, setError, destroy } from "./customer-form.slice";
import "./style.scss";

function CustomerForm({ onSubmit, customer }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { companyId } = useParams();
  const { isLoading, errMsg, successMessage } = useSelector(
    (state) => state.customerFormReducer
  );
  const [customerId, setCustomerId] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [phone, setPhone] = useState("");
  const [contactPerson, setContactPerson] = useState("");
  const [vatCode, setVatCode] = useState("");
  const [companyCode, setCompanyCode] = useState("");
  const [address, setAddress] = useState("");
  const [maxAllowedDebt, setMaxAllowedDebt] = useState("");
  const [bankIban, setBankIban] = useState("");
  const [bankName, setBankName] = useState("");
  const [paymentDelay, setPaymentDelay] = useState("");
  const [prepaymentPercentage, setPrepaymentPercentage] = useState("");
  const [fine, setFine] = useState("");

  const handleFormSubmit = async () => {
    const formData = {
      customerId,
      email,
      name,
      phone,
      contactPerson,
      vatCode,
      companyCode,
      address,
      companyId,
      maxAllowedDebt,
      bankIban,
      bankName,
      paymentDelay,
      prepaymentPercentage,
      fine,
    };

    const id = await dispatch(submit(formData));
    if (onSubmit) {
      onSubmit(id);
    }
  };

  const handleNameChange = (value) => {
    setName(value);

    if (!value) {
      setNameError("field_is_required");
    } else {
      setNameError("");
    }
  };

  useEffect(() => {
    if (customer) {
      setCustomerId(customer.id);
      setEmail(customer.email);
      setName(customer.name);
      setPhone(customer.phone);
      setContactPerson(customer.contactPerson);
      setVatCode(customer.vatCode);
      setCompanyCode(customer.companyCode);
      setAddress(customer.address);
      setMaxAllowedDebt(customer.maxAllowedDebt);
      setBankIban(customer.bankIban);
      setBankName(customer.bankName);
      setPaymentDelay(customer.paymentDelay);
      setPrepaymentPercentage(customer.prepaymentPercentage);
      setFine(customer.fine);
    }
  }, [customer, dispatch]);

  return (
    <Page fullWidth title={t("customer")}>
      <div className="customer-form">
        <Form className="customer-form_form">
          <FormLayout>
            <div className="form-warnings">
              {errMsg && (
                <div className="">
                  <Banner status="critical">{t(errMsg)}</Banner>
                </div>
              )}
              {successMessage && (
                <div className="">
                  <Banner status="info">{t(successMessage)}</Banner>
                </div>
              )}
            </div>
            <TextField
              label={t("Email")}
              id="email"
              name="email"
              value={email}
              onChange={setEmail}
            />
            <TextField
              label={t("title")}
              id="name"
              name="name"
              value={name}
              error={t(nameError)}
              onChange={handleNameChange}
            />
            <TextField
              label={t("phone")}
              id="phone"
              name="phone"
              value={phone}
              onChange={setPhone}
            />
            <TextField
              label={t("contact_person")}
              id="contactPerson"
              name="contactPerson"
              value={contactPerson}
              onChange={setContactPerson}
            />
            <TextField
              label={t("company_code")}
              id="companyCode"
              name="companyCode"
              value={companyCode}
              onChange={setCompanyCode}
            />
            <TextField
              label={t("vat_code")}
              id="vatCode"
              name="vatCode"
              value={vatCode}
              onChange={setVatCode}
            />
            <TextField
              label={t("address")}
              id="address"
              name="address"
              value={address}
              onChange={setAddress}
            />
            <TextField
              label={t("max_allowed_debt")}
              id="maxAllowedDebt"
              name="maxAllowedDebt"
              value={maxAllowedDebt}
              onChange={setMaxAllowedDebt}
            />
            <TextField
              label={t("bank_iban")}
              id="bankIban"
              name="bankIban"
              value={bankIban}
              onChange={setBankIban}
            />
            <TextField
              label={t("bank_name")}
              id="bankName"
              name="bankName"
              value={bankName}
              onChange={setBankName}
            />
            <TextField
              label={t("payment_delay")}
              id="paymentDelay"
              name="paymentDelay"
              type="number"
              value={paymentDelay}
              onChange={setPaymentDelay}
            />
            <TextField
              label={t("prepayment_percentage")}
              id="prepaymentPercentage"
              name="prepaymentPercentage"
              type="number"
              value={prepaymentPercentage}
              onChange={setPrepaymentPercentage}
            />
            <TextField
              label={t("fine")}
              id="fine"
              name="fine"
              type="number"
              value={fine}
              onChange={setFine}
            />
            <Button
              primary
              onClick={handleFormSubmit}
              disabled={!name}
              loading={isLoading}
            >
              {t("save")}
            </Button>
          </FormLayout>
        </Form>
      </div>
    </Page>
  );
}

export default CustomerForm;

CustomerForm.propTypes = {
  onSubmit: PropTypes.func,
  customer: PropTypes.object
};

CustomerForm.defaultProps = {
  onSubmit: null,
  customer: null
};
