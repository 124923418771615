import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, Outlet, useParams } from "react-router-dom";
import { Frame, TopBar, Navigation, Loading } from "@shopify/polaris";
import {
  ExitIcon,
} from "@shopify/polaris-icons";
import { useTranslation } from "react-i18next";
import CompanySelect from "features/company-select/company-select.jsx";
import {
  checkSession,
  logout,
  toogleUserMenu,
  toogleNav,
} from "features/auth/authSlice";
import Modalx from "features/modalx/modalx";
import Welcome from "features/welcome/Welcome"

function AppLayout() {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { companyId } = useParams()
  const { session, isLoading, userMenuActive, isError } =
    useSelector((state) => state.authReducer);

  useEffect(() => {
    if (isError) {
      window.asist = { redirect: window.location.pathname };
      navigate("/auth/login");
    } else if (!session && !isLoading) {
      dispatch(checkSession());
    }
  }, [navigate, dispatch, session, isLoading, isError]);

  if (!session) {
    return (
      <Frame>
        <Loading />
      </Frame>
    );
  }

  const handleLogOut = async () => {
    await dispatch(logout());
  };

  const handleToogleUserMenu = async () => {
    await dispatch(toogleUserMenu());
  };

  const handleNavigationToggle = async () => {
    await dispatch(toogleNav());
  };

  const handleCompanyChange = (id) => {
    if (companyId) {
      navigate(`/app/${id}`);
    }
  };

  const userMenuActions = [
    {
      items: [
        { content: t("log_out"), icon: ExitIcon, onAction: handleLogOut },
      ],
    },
  ];

  const userMenuMarkup = (
    <TopBar.UserMenu
      actions={userMenuActions}
      name={session.email}
      open={userMenuActive}
      onToggle={handleToogleUserMenu}
    />
  );

  const topBarMarkup = (
    <div className="company_select_box">
      <TopBar
        secondaryMenu={<CompanySelect onChange={handleCompanyChange} />}
        userMenu={userMenuMarkup}
        onNavigationToggle={handleNavigationToggle}
      />
    </div>
  );

  if (companyId) {
    return <Outlet />
  } else {
    return (
      <Frame
        topBar={topBarMarkup}
        onNavigationDismiss={handleNavigationToggle}
      >
        <Welcome />
        <Modalx />
      </Frame>
    );
  }
}

export default AppLayout;
