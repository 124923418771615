import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { post } from "../vats/vats.service";
import { fetchVats } from "../vats/vats.slice";

const initialState = {
  isLoading: false,
  isError: false,
  isSuccessfullySaved: false,
  errMsg: null,
  successMessage: null,
  id: "",
};
const vatFormSlice = createSlice({
  name: "vatForm",
  initialState,
  /* eslint-disable no-param-reassign */
  reducers: {
    setLoading(state) {
      state.isLoading = true;
    },
    setError(state, action) {
      state.isLoading = false;
      state.isError = true;
      state.errMsg = action.payload.error;
    },
    setSuccessfullySaved(state, action) {
      state.isLoading = false;
      state.isError = false;
      state.errMsg = null;
      state.successMessage = action.payload;
    },
    setId(state, action) {
      state.id = action.payload;
    },
    destroy(state) {
      _.assign(state, initialState);
    },
  },
  /* eslint-enable */
});

// Extract the action creators object and the reducer
const { actions, reducer } = vatFormSlice;

// Extract and export each action creator by name
export const { setLoading, setError, setSuccessfullySaved, destroy, setId } =
  actions;

// Export the reducer, either as a default or named export
export default reducer;

export const submit = (data) => async (dispatch) => {
  dispatch(setLoading());
  try {
    const result = await post(data);
    dispatch(fetchVats());
    dispatch(setSuccessfullySaved("saved"));

    const id = _.get(result, "createdVat.id") || _.get(result, "updatedVat.id");
    if (id) {
      return id;
    }
  } catch (e) {
    dispatch(setError("not_saved"));
    return false;
  }
};
