import { createSlice } from "@reduxjs/toolkit";
import { fetch } from "./orders.service";

const OrdersSlice = createSlice({
  name: "orders",
  initialState: {
    ordersCollection: [],
    isLoading: false,
    isError: false,
    errMsg: null,
    queryValue: null,
    filtersValue: {},
  },
  /* eslint-disable no-param-reassign */
  reducers: {
    setLoading(state) {
      state.isLoading = true;
      state.isError = false;
    },
    setOrders(state, action) {
      state.isLoading = false;
      state.ordersCollection = action.payload.ordersCollection;
    },
    setError(state, action) {
      state.isError = true;
      state.errMsg = action.payload.errMsg;
    },
    setFilterValue(state, action) {
      const { key, value } = action.payload;
      state.filtersValue[key] = value;
    },
  },
  /* eslint-enable */
});

// Extract the action creators object and the reducer
const { actions, reducer } = OrdersSlice;

// Extract and export each action creator by name
export const { setLoading, setOrders, setError, setFilterValue } = actions;

// Export the reducer, either as a default or named export
export default reducer;

export const fetchOrders = (filtersValue) => async (dispatch) => {
  dispatch(setLoading());
  try {
    const { ordersCollection } = await fetch(filtersValue);
    dispatch(setOrders({ ordersCollection }));
  } catch (e) {
    dispatch(setError("unexpected_error"));
  }
};
