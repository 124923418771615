import axios from "axios";
import { API_URL } from "../../constants";

export const fetch = (companyId, filtersValue) => {
  const url = `${API_URL}/${companyId}/customers/fetch`;
  return axios.post(url, { filtersValue, companyId }).then((response) => response.data);
};

export const post = (companyId, data) => {
  const url = `${API_URL}/${companyId}/customers`;
  return axios.post(url, data).then((response) => response.data);
};

export const fetchById = (companyId, customerId) => {
  const url = `${API_URL}/${companyId}/customers/${customerId}`;
  return axios.get(url).then((response) => response.data);
};

export const sendCreateCustomerQuestionnaire = (companyId, customerId) => {
  const url = `${API_URL}/${companyId}/customers/${customerId}/post-questionnaire`;
  return axios.post(url).then((response) => response.data);
};