import _ from "lodash";

const minLengthValidator = (value, minLength) =>
  value ? value.length >= minLength : true;
const maxLengthValidator = (value, maxLength) =>
  value ? value.length <= maxLength : true;
const matchValidator = (value1, value2) =>
  value1 && value2 && value1 === value2;

const requiredValidator = (value) => {
  if (_.isArray(value)) {
    return _.size(value) > 0;
  }
  if (typeof value === "string") {
    return value ? value.trim() !== "" : false;
  }
  return !!value;
};

const emailValidator = (value) => {
  const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return regex.test(value);
};

const emailsCommaSepValidator = (value) =>
  _.countBy(value.split(""), (char) => char === "@").true >= 2
    ? value.includes(",")
    : true;

const validate = (value, rules, formControls) => {
  let isValid = true;
  let error = "";
  if (!rules) {
    return { isValid, error };
  }
  Object.keys(rules).forEach((rule) => {
    switch (rule) {
      case "match":
        isValid =
          isValid &&
          _.get(formControls, rules[rule]) &&
          matchValidator(
            _.get(_.get(formControls, rules[rule]), "value"),
            value
          );
        error = "Field doesn't match";
        break;
      case "minLength":
        isValid = isValid && minLengthValidator(value, rules[rule]);
        error = `Field must be at least ${rules[rule]} characters`;
        break;
      case "maxLength":
        isValid = isValid && maxLengthValidator(value, rules[rule]);
        error = `Field must be maximum ${rules[rule]} characters`;
        break;
      case "isRequired": {
        if (rules[rule]) {
          isValid = isValid && requiredValidator(value);
          error = "field_is_required";
        }
        break;
      }
      case "isEmail":
        isValid = isValid && emailValidator(value);
        error = "Please enter a valid email address";
        break;
      case "isEmailsCommaSeparated":
        isValid = isValid && emailsCommaSepValidator(value);
        error = "Emails must be comma separated";
        break;
      default:
        isValid = true;
    }
  });
  return { isValid, error };
};

export default validate;
