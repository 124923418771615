import axios from "axios";
import { API_URL } from "constants";

export const createCustomField = async (formData, companyId) => {
  const url = `${API_URL}/${companyId}/custom-fields`;
  return axios.post(url, formData).then((response) => response.data);
}

export const updateCustomField = async (formData, companyId) => {
  const url = `${API_URL}/${companyId}/custom-fields/${formData.id}`;
  return axios.patch(url, formData).then((response) => response.data);
}

export const fetchCustomFieldById = async (companyId, id) => {
  const url = `${API_URL}/${companyId}/custom-fields/${id}`;
  return axios.get(url).then((response) => response.data);
}

export const fetchCustomFields = async (companyId) => {
  const url = `${API_URL}/${companyId}/custom-fields`;
  return axios.get(url).then((response) => response.data);
};

export const fetchCustomFieldsByQuery = async (companyId, type) => {
  const url = `${API_URL}/${companyId}/custom-fields?type=${type}`;
  return axios.get(url).then((response) => response.data);
};