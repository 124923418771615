import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Link } from "react-router-dom";
import axios from "axios";
import _ from "lodash";
import { AppProvider } from "@shopify/polaris";
import "@shopify/polaris/build/esm/styles.css";
import ltTranslations from "./polaris-lt.json";
import store from "./configureStore.ts";
import "./i18n";
import App from "./App.tsx";
import "./index.css";

axios.interceptors.request.use(
  (config) => ({ ...config, withCredentials: true }),
  Promise.reject
);

// Add a response interceptor
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      _.get(error, "response.status") === 403 &&
      _.get(window, "location.pathname") !== "/login" &&
      _.get(window, "location.pathname") !== "/signup"
    ) {
      // window.location = '/auth/login';
    }
    return Promise.reject(error);
  }
);

// eslint-disable-next-line react/prop-types
function AdapterLink({ url, ...rest }) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Link to={url} {...rest} />;
}

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <Router>
      <AppProvider i18n={ltTranslations} linkComponent={AdapterLink}>
        <App />
      </AppProvider>
    </Router>
  </Provider>
);
