import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Page,
  Layout,
  Modal,
  Filters,
} from "@shopify/polaris";
import DataTable from "components/datatable/Datatable";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, Outlet } from "react-router-dom";
import _ from "lodash";
import { fetchProducts, setFilterValue } from "./products.slice";
import { setModalxComponent } from "features/modalx/modalx.slice";

function Products() {
  const dispatch = useDispatch();
  const history = useNavigate();
  const { t } = useTranslation();
  const { productId, companyId } = useParams();
  const {
    productsReducer: { productsCollection, filtersValue },
  } = useSelector((state) => state);

  const handleCreateProduct = () => {
    dispatch(setModalxComponent("ProductForm"));
  };

  const handleProductClose = () => {
    history(`/app/${companyId}/products`);
  };
  const disambiguateLabel = (key, value) => {
    switch (key) {
      case "textSearch":
        return `${t("search")}: ${t(value)}`;
      default:
        return value;
    }
  };

  useEffect(() => {
    dispatch(fetchProducts(companyId, filtersValue));
  }, [dispatch, companyId, filtersValue]);

  const handleFilterChange = (key, value) => {
    dispatch(setFilterValue({ key, value }));
  };
  const handleQueryChange = (value) => {
    dispatch(setFilterValue({ key: "textSearch", value }));
  };
  const handleQueryValueRemove = () => {
    dispatch(setFilterValue({ key: "textSearch", value: "" }));
  };
  const handleClearAll = () => {};
  const appliedFilters = [];
  Object.keys(filtersValue).forEach((key) => {
    if (filtersValue[key] && filtersValue[key].length > 0) {
      appliedFilters.push({
        key,
        label: disambiguateLabel(key, filtersValue[key]),
        onRemove: () => handleFilterChange(key, null),
      });
    }
  });

  const filters = [];

  return (
    <Layout>
      <Layout.Section>
        <Page
          fullWidth
          primaryAction={{
            content: t("create_product"),
            onAction: handleCreateProduct,
          }}
          title={t("products")}
        >
            <div style={{ padding: "16px", display: "flex" }}>
              <div style={{ flex: 1 }}>
                <Filters
                  queryValue={filtersValue.textSearch}
                  filters={filters}
                  appliedFilters={appliedFilters}
                  onQueryChange={handleQueryChange}
                  onQueryClear={handleQueryValueRemove}
                  onClearAll={handleClearAll}
                />
              </div>
            </div>

           <DataTable
              collection={productsCollection}
              columns={[
                { key: '#' },
                { key: 'title', name: t('title'), url: 'id' },
                { key: 'sku', name: t('sku') },
                { key: 'quantity', suffix: 'unit.title', type: 'number', name: t('quantity'), isDisabledTotal: true },
                { key: '_reservedQuantity', type: 'number', name: t('reserved'), isDisabledTotal: true },
                { key: 'unitPrice', type: 'currency', name: t('unitPrice') },
              ]}
            />
        </Page>
      </Layout.Section>
      {productId && (
        <Modal open titleHidden size="large" onClose={handleProductClose}>
          <Modal.Section>
            <Outlet />
          </Modal.Section>
        </Modal>
      )}
    </Layout>
  );
}

export default Products;
