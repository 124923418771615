import React, { useState, useEffect, useCallback } from 'react';
import {
  Autocomplete,
  TextField,
  ResourceList,
  ResourceItem,
  Text,
  InlineGrid,
} from '@shopify/polaris';
import { useTranslation } from "react-i18next";
import Currency from "components/format/currency/currency";
import { useParams } from "react-router-dom";
import { fetch } from 'containers/products/products.service'

// interface Product {
//   id: string;
//   title: string;
//   price: number;
// }

// interface SelectedProduct extends Product {
//   quantity: number;
//   customPrice: number;
// }

type Product = {
  id?: string,
  title: string,
  price: number,
  quantity: number | '',
  product: string,
  notes?: string,
}

interface ProductsSelectProps {
  onChange: (products: Product[]) => void;
  initialProducts?: Product[];
}

const ProductsSelect: React.FC<ProductsSelectProps> = ({ onChange, initialProducts = [] }) => {
  const [selectedProducts, setSelectedProducts] = useState<Product[]>(initialProducts);
  const [searchResults, setSearchResults] = useState<Product[]>([]);
  const [query, setQuery] = useState('');
  const { companyId } = useParams();
  const { t } = useTranslation();

  useEffect(() => {
    let isValid = true
    selectedProducts.forEach(product => {
      if (!product.quantity || !product.price) {
        isValid = false;
        return;
      }
    })
    if (isValid) {
      onChange(selectedProducts);
    }
  }, [selectedProducts, onChange]);

  const handleSearchChange = useCallback(async (value: string) => {
    setQuery(value);
    if (value.length > 2 && companyId) {
      const results: Product[] | undefined = await searchProducts(companyId, value);
      if (!results) return
      setSearchResults(results);
    } else {
      setSearchResults([]);
    }
  }, []);

  const handleAddProduct = useCallback((product: Product) => {
    setSelectedProducts((prev) => {
      if (prev.some(p => p.product === product.product)) {
        return prev; // Product already selected, do not add
      }
      return [...prev, { ...product, quantity: 1, price: product.price }];
    });
  }, []);


  const handleRemoveProduct = useCallback((productId: string) => {
    setSelectedProducts((prev) => prev.filter((product) => product.product !== productId));
  }, []);

  const handleQuantityChange = useCallback((productId: string, quantity: number | '') => {
    setSelectedProducts((prev) =>
      prev.map((product) => (product.product === productId ? { ...product, quantity } : product))
    );
  }, []);

  const handlePriceChange = useCallback((productId: string, price: number) => {
    setSelectedProducts((prev) =>
      prev.map((product) => (product.product === productId ? { ...product, price } : product))
    );
  }, []);

  const handleNotesChange = useCallback((productId: string, notes: string | '') => {
    setSelectedProducts((prev) =>
      prev.map((product) => (product.product === productId ? { ...product, notes } : product))
    );
  }, []);

  const renderProductItem = useCallback(
    (product: Product) => {
      const quantity = product.quantity.toString();
      return (
        <ResourceItem
          key={product.product}
          id={product.product}
          onClick={() => {}}
          accessibilityLabel={`View details for ${product.title}`}
          shortcutActions={[
            {
              content: t('remove'),
              onAction: () => handleRemoveProduct(product.product),
            },
          ]}
        >
          <Text as="p" fontWeight="bold">{product.title}</Text>
          <InlineGrid gap="400" columns={4}>
            <TextField
              label={t('quantity')}
              value={quantity}
              autoComplete="off"
              onChange={(value) => {
                const parsedValue = parseInt(value, 10);
                if (value && !isNaN(parsedValue)) {
                  handleQuantityChange(product.product, parsedValue)
                } else {
                  handleQuantityChange(product.product, '');
                }
              }}
            />
            <TextField
              label={t('price')}
              type="number"
              autoComplete="off"
              value={product.price ? product.price.toString() : ''}
              onChange={(value) => handlePriceChange(product.product, parseFloat(value))}
            />
            <div>
              <Text variant="bodyMd" as="p">{t('totalPrice')}</Text>
              <Currency isDecimal={true} value={parseInt(quantity) * product.price} isSymbol={true} />
            </div>
              <TextField
                label={t('notes')}
                type="text"
                autoComplete="off"
                value={product.notes || ''}
                onChange={(value) => handleNotesChange(product.product, value)}
              />
          </InlineGrid>
        </ResourceItem>
      );
    },
    [t, handleRemoveProduct, handleQuantityChange, handlePriceChange]
  );

  return (
    <>
      <Autocomplete
        options={searchResults.map((product) => ({
          value: product.product,
          label: product.title,
        }))}
        selected={[]}
        onSelect={(selected) => {
          const product = searchResults.find((p) => p.product === selected[0]);
          if (product) handleAddProduct(product);
        }}
        textField={
          <Autocomplete.TextField
            label={t('search_products')}
            value={query}
            autoComplete="off"
            onChange={handleSearchChange}
          />
        }
      />
      <ResourceList
        items={selectedProducts}
        renderItem={(item) => renderProductItem(item)}
      />
    </>
  );
};

// Simulated AJAX search function
const searchProducts = async (companyId: string, query: string): Promise<Product[] | undefined> => {
  const filter = { textSearch: query }
  try {
    const { productsCollection } = await fetch(companyId, filter);
    return productsCollection.map((product: any) => ({ product: product.id, value: product.id, title: product.title, quantity: 0, price: product.price }))
  } catch (e) {
    return undefined
  }
};

export default ProductsSelect;