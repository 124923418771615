import { createSlice } from "@reduxjs/toolkit";
import { fetch } from "./project-boards.service";

const ProjectBoardsSlice = createSlice({
  name: "projectboards",
  initialState: {
    projectBoardsCollection: [],
    isLoading: false,
    isError: false,
    errMsg: null,
    queryValue: null,
    filtersValue: {},
  },
  /* eslint-disable no-param-reassign */
  reducers: {
    setLoading(state) {
      state.isLoading = true;
      state.isError = false;
    },
    setProjectBoards(state, action) {
      state.isLoading = false;
      state.projectBoardsCollection = action.payload.projectBoardsCollection;
    },
    setError(state, action) {
      state.isError = true;
      state.errMsg = action.payload.errMsg;
    },
    setFilterValue(state, action) {
      const { key, value } = action.payload;
      state.filtersValue[key] = value;
    },
  },
  /* eslint-enable */
});

// Extract the action creators object and the reducer
const { actions, reducer } = ProjectBoardsSlice;

// Extract and export each action creator by name
export const { setLoading, setProjectBoards, setError, setFilterValue } = actions;

// Export the reducer, either as a default or named export
export default reducer;

export const fetchProjectBoards = (companyId) => async (dispatch) => {
  dispatch(setLoading());
  try {
    const projectBoardsCollection = await fetch(companyId);
    dispatch(setProjectBoards({ projectBoardsCollection }));
  } catch (e) {
    dispatch(setError(""));
  }
};
