import { useEffect, useState } from "react";
import { useAppDispatch } from "hooks";
import { Page, Grid, LegacyCard, Text, InlineStack, Button, Badge } from '@shopify/polaris';
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { setModalxComponent, setModalxComponentProps } from "components/modalx/modalx.slice";
import { fetchProjectById } from "services/project.service";
import ProjectColumnSelect from 'components/project-column-select/project-column-select'
import CustomerSelect from 'components/customer-select/CustomerSelect'
import UserSelect from 'features/user-select/user-select'
import Loading from 'components/loading/Loading'
import ProjectComment from "containers/project-comment/project-comment";
import './style.scss';

function ProjectFormView({ projectBoardId, projectBoardColumnId, id }) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { companyId } = useParams();
  const [name, setName] = useState();
  const [description, setDescription] = useState();
  const [projectBoardColumn, setProjectBoardColumn] = useState(projectBoardColumnId);
  const [user, setUser] = useState();
  const [customer, setCustomer] = useState();
  const [order, setOrder] = useState();
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false)
  const [isImportant, setIsImportant] = useState(false);

  const totalProjectValue = products.reduce(
    (accumulator, product) => accumulator + (parseInt(product.quantity) * product.price),
    0,
  );

  const fetchData = async (id) => {
    setIsLoading(true)
    const data = await fetchProjectById(companyId, id)
    setName(data.name)
    setDescription(data.description)
    setProjectBoardColumn(data.projectBoardColumn)
    setUser(data.user)
    setCustomer(data.customer)
    setOrder(data.order)
    setProducts(data.products)
    setIsImportant(data.isImportant)
    setIsLoading(false)
  }


  useEffect(() => {
    if (id) {
      fetchData(id)
    }
  }, [id])

  const handleEditProjectClick = () => {
    dispatch(setModalxComponent('ProjectForm'))
    dispatch(setModalxComponentProps({ id: id, projectBoardId }))
  }

  if (isLoading && !name) {
    return <Loading />
  }

  return (
    <div className="viewModal">
      <Page fullWidth>
        <Grid >
          <Grid.Cell columnSpan={{ xs: 4, sm: 12, md: 12, lg: 8, xl: 8 }}>
            <Text as="h4" variant="bodyMd" fontWeight="semibold">
              {t("project-name")} : <Text as="span" variant="bodyMd">
                {name}
              </Text>
            </Text>

            <Text as="h4" variant="bodyMd" fontWeight="semibold"> {t("project-description")} : <Text as="span" variant="bodyMd">
              {description}
            </Text>
            </Text>

            <div className="content">
              {products.map((product, idx) => {
                return (
                  <>
                    <Text as="h4" variant="bodyMd" fontWeight="semibold">
                      {product.title}
                    </Text>
                    <Grid key={idx}>
                      <Grid.Cell columnSpan={{ xs: 2, sm: 2, md: 2, lg: 4, xl: 4 }}>
                        <div className="marginBottom_10">
                          <label>{t('quantity')}</label>
                          <Text as="h2" variant="bodyMd" borderRadius="50">
                            {product.quantity}
                          </Text>
                        </div>
                      </Grid.Cell>
                      <Grid.Cell columnSpan={{ xs: 2, sm: 2, md: 2, lg: 4, xl: 4 }}>
                        <div className="marginBottom_10">
                          <label>{t('price')}</label>
                          <Text as="h2" variant="bodyMd" borderRadius="50">
                            {product.price}
                          </Text>

                        </div>
                      </Grid.Cell>

                      <Grid.Cell columnSpan={{ xs: 2, sm: 2, md: 2, lg: 4, xl: 4 }}>
                        <div className="marginBottom_10">
                          <label>{t('totalPrice')}</label>
                          <Text as="h2" variant="bodyMd" borderRadius="50">
                            {parseInt(product.quantity) * product.price}
                          </Text>
                        </div>
                      </Grid.Cell>
                    </Grid>
                  </>
                )
              })}
              {products.length > 0 &&
              <Grid>
                <Grid.Cell columnSpan={{ xs: 2, sm: 2, md: 2, lg: 4, xl: 4 }}>
                </Grid.Cell>
                <Grid.Cell columnSpan={{ xs: 2, sm: 2, md: 2, lg: 4, xl: 4 }}>
                  <div className="marginBottom_10">
                  <Text as="h4" variant="bodyMd" fontWeight="semibold">
                  {t('totalPrice')}
                    </Text>
                  </div>
                </Grid.Cell>

                <Grid.Cell columnSpan={{ xs: 2, sm: 2, md: 2, lg: 4, xl: 4 }}>
                  <div className="marginBottom_10">
                    <Text as="h2" variant="bodyMd" fontWeight="semibold">
                      {totalProjectValue}
                    </Text>
                  </div>
                </Grid.Cell>
              </Grid>}
              
              
              {/* comment component */}
              <ProjectComment 
                id={id}
              />
            </div>
          </Grid.Cell>

          <Grid.Cell columnSpan={{ xs: 2, sm: 12, md: 12, lg: 4, xl: 4 }}>
            <div className="height">
              <LegacyCard>
                <div>
                  <InlineStack InlineStack wrap={false} align={'space-between'}>
                  {isImportant && (
                    <Badge tone="critical">{t("important")}</Badge>
                  )}
                    <Button onClick={handleEditProjectClick}>Edit</Button>
                  </InlineStack>
                </div>
                <ProjectColumnSelect
                  value={projectBoardColumn}
                  onChange={setProjectBoardColumn}
                  projectBoardId={projectBoardId}
                  isRequired={true}
                  isViewOnly={true}
                />
                <CustomerSelect
                  value={customer}
                  onChange={setCustomer}
                  isViewOnly={true}
                />
                <UserSelect
                  onChange={setUser}
                  value={user}
                  isViewOnly={true}
                />
              </LegacyCard>
            </div>
          </Grid.Cell>
        </Grid>
      </Page>
    </div>
  );
}

export default ProjectFormView;
