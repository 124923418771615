import React, { useState, useEffect } from 'react';
import { Text, Card, TextContainer, InlineStack, TextField, Button } from '@shopify/polaris';
import { EditIcon } from '@shopify/polaris-icons';
import { createComment, updateComment } from "./project-comment.service";
import { fetchProjectById } from "services/project.service";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import PrettyDate from "components/format/PrettyDate";
import './style.scss';

interface ProjectCommentProps {
    id: string;
}

interface Comment {
    id: string;
    comment: string;
    user: {
        id: string;
        name: string;
        email: string;
        externalCode: string;
        isDisabled: boolean;
        phone: string;
        createdAt: string;
        updatedAt: string;
    };
    project:string;
    createdAt: string;
    updatedAt: string;
}


const ProjectComment: React.FC<ProjectCommentProps> = ({ id }) => {
    const { t } = useTranslation();
    const { companyId } = useParams<{ companyId: string }>();
    const [comment, setComment] = useState<string>('');
    const [newComment, setNewComment] = useState<string>('');
    const [commentList, setCommentList] = useState<Comment[]>([]);
    const [targetComment, setTargetComment] = useState<Comment | null>(null);
    const [successMessage, setSuccessMessage] = useState<string | null>(null);
    const [errMsg, setErrMsg] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState<string | undefined>();
    const { session } = useSelector((state: any) => state.authReducer);

    const handleFormSubmit = async (commentId?: string) => {
        const formData = {
            comment: commentId ? newComment : comment,
        };
        const action = commentId ? updateComment : createComment;
        try {
            setIsLoading(commentId ? 'update' : 'save');
            await action(formData, companyId!, id, commentId);
            fetchData(id);
            setComment('');
            setTargetComment(null);
            setSuccessMessage('ok');
            setIsLoading(undefined);
        } catch (e) {
            setErrMsg('error');
            setIsLoading(undefined);
        }
    };

    const fetchData = async (id: string) => {
        setIsLoading('fetch');
        const data = await fetchProjectById(companyId!, id);
        setCommentList(data.comments);
        setIsLoading(undefined);
    };

    useEffect(() => {
        if (id) {
            fetchData(id);
        }
    }, [id]);

    const editHandler = (comment: Comment | null) => {
        setTargetComment(comment);
        setNewComment(comment?.comment || '');
    };

    return (
        <>
            <div className='textarea-marginBottom_20'>
                <TextField
                    label={t("comment")}
                    id="description"
                    name="description"
                    multiline={4}
                    value={comment}
                    onChange={setComment}
                    autoComplete='off'
                />
                <div className='cmt-marginTop_10'>
                    <InlineStack wrap={false} align={'end'}>
                        <Button 
                            onClick={() => handleFormSubmit()}
                            disabled={!comment}
                            loading={isLoading === 'save'}
                        >
                            {t('make_a_comment')}
                        </Button>
                    </InlineStack>
                </div>
            </div>

            {commentList.map((comment, idx) => (
                <div className='cmt-marginBottom_10' key={idx}>
                    {targetComment?.id === comment.id ? (
                        <TextField
                            label={comment.user.name}
                            id="comment"
                            name="comment"
                            multiline={1}
                            value={newComment}
                            onChange={setNewComment}
                            autoComplete='off'
                        />
                    ) : (
                        <Card >
                            <TextContainer>
                                <InlineStack wrap={false} align={'space-between'}>
                                    <div style={{ display: 'flex', alignItems: 'center', gap:'10px' }}>
                                        <Text as="span"> {comment.user.name}</Text>
                                        <Text as="span"> 
                                            <PrettyDate dateString={comment.createdAt} showTime={true}/>
                                        </Text>
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', gap:'10px' }}>
                                        {comment.createdAt !== comment.updatedAt && (
                                            <Text as="span">
                                                ({t('last_edit')}: <PrettyDate dateString={comment.updatedAt} showTime={true}/>)
                                            </Text>
                                        )}
                                        {session.id === comment.user.id && (
                                            <Button 
                                                icon={EditIcon} 
                                                onClick={() => editHandler(comment)} 
                                                accessibilityLabel="Edit comment"
                                            />
                                        )}
                                    </div>
                                </InlineStack>
                                <TextField
                                    label='comment box'
                                    labelHidden
                                    id="comment_view"
                                    name="comment_view"
                                    multiline={1}
                                    value={comment.comment}
                                    autoComplete='off'
                                    readOnly
                                    />
                            </TextContainer>
                        </Card>
                    )}                
                    <div>
                        {targetComment?.id === comment.id && session.id === comment.user.id && (
                            <div className='cmt-marginTop_5'>
                                <InlineStack wrap={false} align={'start'} gap='300'>
                                    <Button 
                                        loading={isLoading === 'update'} 
                                        onClick={() => handleFormSubmit(comment.id)}
                                        disabled={!newComment}
                                    >
                                        {t('save')}
                                    </Button>
                                    <Button onClick={() => editHandler(null)}>{t('cancel')}</Button>
                                </InlineStack>
                            </div>
                        )}
                    </div>
                </div>
            ))}
        </>
    );
};

export default ProjectComment;
