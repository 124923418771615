import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchFileCategoryById } from "services/files-category.service"

// Define the types for your initial state
interface DocumentContainerState {
  isLoading: boolean;
  isError: boolean;
  errMsg: string;
  catFilesData: any | null;
}

// Define the initial state
const initialState: DocumentContainerState = {
  isLoading: false,
  isError: false,
  errMsg: "",
  catFilesData: null
};


// Create the slice
const documentFilesContainerSlice = createSlice({
  name: "categoryFiles",
  initialState,
  reducers: {
    setDestroy() {
      return initialState;
    },
    setLoading(state: DocumentContainerState, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setError(state: DocumentContainerState, action: PayloadAction<{ error: string }>) {
      state.isLoading = false;
      state.isError = true;
      state.errMsg = action.payload.error;
    },
    setFilesContainer(state: DocumentContainerState, action: PayloadAction<any>) {
      state.catFilesData = action.payload;
      state.isLoading = false;
    },
  },
});

// Extract the action creators object and the reducer
const { actions, reducer } = documentFilesContainerSlice;

// Extract and export each action creator by name
export const { setDestroy, setLoading, setError, setFilesContainer } = actions;

// Export the reducer, either as a default or named export
export default reducer;

// Define the type for the dispatch function
export const fetchCategoryFilesDetails = ({ companyId, catId }: { companyId: string , catId: string}) => async (dispatch: any) => {
  dispatch(setLoading(true));
  try {
    const data = await fetchFileCategoryById(companyId, catId);
    dispatch(setFilesContainer(data));
  } catch (e) {
    const error = "not_fetch";
    dispatch(setError({ error }));
    return false;
  }finally{
    dispatch(setLoading(false));
  }
};
