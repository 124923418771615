import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import changeHandler from "../../../common/form/changeHandler";
import submitHandler from "../../../common/form/submitHandler";
import {
  FormLayout,
  TextField,
  Form,
  Card,
  TextContainer,
  Banner,
} from "@shopify/polaris";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { setFormData, submit, fetchInvitation } from "./reg-page.slice";
import "./reg-page.scss";

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function Reg() {
  const query = useQuery();
  const invitation = query.get("invitation");
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    formControls,
    isLoading,
    isSuccessful,
    isSuccessfulInvitation,
    isFormValid,
    isError,
    errMsg,
  } = useSelector((state) => state.regPageReducer);

  useEffect(() => {
    if (invitation) dispatch(fetchInvitation(invitation));
  }, [dispatch, invitation]);

  const handleFormChange = (formData) => dispatch(setFormData(formData));
  const handleFormSubmit = async (formData) => {
    await dispatch(submit(formData, invitation));
  };

  if (isSuccessful) {
    return (
      <div className="reg-page">
        <Card title={t("Sign up")} sectioned>
          <TextContainer>
            <p>{t("user_created_successfully")}</p>
          </TextContainer>
        </Card>
      </div>
    );
  }
  if (isSuccessfulInvitation) {
    return (
      <div className="reg-page">
        <Card
          title={t("Sign up")}
          sectioned
          primaryFooterAction={{
            content: t("Login"),
            loading: isLoading,
            url: "/auth/login",
          }}
        >
          <TextContainer>
            <p>{t("user_created_from_invitation_successfully")}</p>
          </TextContainer>
        </Card>
      </div>
    );
  }
  return (
    <div className="reg-page">
      <Card
        title={t("signup")}
        primaryFooterAction={{
          content: t("form_reg_page_submit_title"),
          loading: isLoading,
          disabled: !isFormValid,
          onAction: submitHandler(formControls, handleFormSubmit),
        }}
        secondaryFooterActions={[
          { content: t("password_restore"), url: "/auth/restore" },
          { content: t("Login to application"), url: "/auth/login" },
        ]}
        sectioned
      >
        <Form
          className="reg-page_form"
          onSubmit={submitHandler(formControls, handleFormSubmit)}
        >
          <FormLayout>
            <TextField
              label={t("Email")}
              autoComplete="off"
              type="email"
              id="email"
              value={formControls.email.value}
              onChange={changeHandler(formControls, handleFormChange)}
              disabled={!!invitation}
              requiredIndicator
              helpText={t("field_email_help_confirm")}
            />
            {/* <TextField
              label={t('name')}
              type="text"
              id="name"
              value={formControls.name.value}
              onChange={changeHandler(formControls, handleFormChange)}
            />
            <TextField
              label={t('phone')}
              type="text"
              id="phone"
              value={formControls.phone.value}
              onChange={changeHandler(formControls, handleFormChange)}
            /> */}
            {/* <TextField
              label={t("field_company_name")}
              type="text"
              id="companyName"
              value={formControls.companyName.value}
              onChange={changeHandler(formControls, handleFormChange)}
              error={t(formControls.companyName.validationError)}
              requiredIndicator
              helpText={t("field_company_name_help")}
            /> */}
          </FormLayout>
        </Form>
        <div className="reg-page__warnings">
          {isError && <Banner status="critical">{t(errMsg)}</Banner>}
        </div>
      </Card>
    </div>
  );
}

export default Reg;
