import { configureStore } from "@reduxjs/toolkit"
import { combineReducers } from "redux"

import authReducer from "./features/auth/authSlice"
import loginPageReducer from "./pages/auth/login/login.slice"
import regPageReducer from "./pages/auth/reg/reg-page.slice"
import restorePageReducer from "./features/restore-page/restore-page.slice"
import companySelectReducer from "./features/company-select/company-select.slice"
import companyCreateReducer from "./features/company-create/company-create.slice"
import modalxReducer from "./features/modalx/modalx.slice"
import tasksReducer from "./features/tasks/tasks.slice"
import taskCreateReducer from "./features/task-create/task-create.slice"
import taskReducer from "./features/task/task.slice"
import usersReducer from "./features/users/users.slice"
import userFormReducer from "./features/user-form/user-form.slice"
import customersReducer from "./features/customers/customers.slice"
import customerFormReducer from "./features/customer-form/customer-form.slice"
import ordersReducer from "./features/orders/orders.slice"
import orderFormReducer from "./features/order-form/order-form.slice"
import vatsReducer from "./features/vats/vats.slice"
import vatFormReducer from "./features/vat-form/vat-form.slice"
import productsReducer from "./features/products/products.slice"
import productFormReducer from "./features/product-form/product-form.slice"
import customerViewReducer from "./features/customer-view/customer-view.slice"
import companyFormReducer from "./features/company-form/company-form.slice"
import invoicesReducer from './features/invoices/invoices.slice'
import customerReducer from './containers/customer/customer-container.slice'
import debtInvoicesReducer from './containers/debt-invoices/debt-invoices-container.slice'
import fileFormReducer from './containers/file-form/file-form.slice'
import filesCategoriesReducer from './containers/files-categories/files-categories-container.slice'
import filesTemplatesReducer from './containers/files-templates/files-templates-container.slice'
import reservationsReducer from "containers/reservations/reservations.slice"
import projectBoardsReducer from "containers/project-boards/project-boards.slice"
import projectBoardReducer from "containers/project-board/project-board-container.slice"
import productReducer from 'containers/product/product-container.slice'
import projectReducer from 'containers/project-view-container/project-container.slice';
import documentCategoryReducer from 'containers/document/category-tree/category-tree.Slice';
import documentFilesReducer from 'containers/document/category-files/catFiles.Slice';
import fileContainerReducer from 'containers/file/file.Slice';
import userContainerReducer from 'containers/user/user-container.slice';
import customFieldReducer from 'containers/custom-field/custom-field-container.slice';

const reducer = combineReducers({
  authReducer,
  restorePageReducer,
  loginPageReducer,
  regPageReducer,
  companySelectReducer,
  companyCreateReducer,
  modalxReducer,
  tasksReducer,
  taskCreateReducer,
  taskReducer,
  usersReducer,
  userFormReducer,
  customersReducer,
  customerFormReducer,
  ordersReducer,
  orderFormReducer,
  vatsReducer,
  vatFormReducer,
  productsReducer,
  productFormReducer,
  customerViewReducer,
  companyFormReducer,
  invoicesReducer,
  customerReducer,
  debtInvoicesReducer,
  fileFormReducer,
  filesCategoriesReducer,
  filesTemplatesReducer,
  reservationsReducer,
  projectBoardsReducer,
  projectBoardReducer,
  productReducer,
  projectReducer,
  documentCategoryReducer,
  documentFilesReducer,
  fileContainerReducer,
  userContainerReducer,
  customFieldReducer
})

const store = configureStore({ reducer })

export default store
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
