import { useTranslation } from "react-i18next";
import {BlockStack, Button, Card, InlineGrid, Text, ResourceList, ResourceItem, Avatar} from '@shopify/polaris';
import { Contact } from "types/Contact"
import { PlusIcon } from '@shopify/polaris-icons';
import { setModalxComponent, setModalxComponentProps } from "components/modalx/modalx.slice";
import { useAppDispatch } from "hooks";

type Props = {
  contacts: Contact[];
  customer: string
};

const Contacts = ({ contacts, customer }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleContactCreate = () => {
    dispatch(setModalxComponent("ContactForm"));
    dispatch(setModalxComponentProps({ customer }));
  };

  const handleContactEdit = (id: string) => {
    dispatch(setModalxComponent("ContactForm"));
    dispatch(setModalxComponentProps({ customer, id }));
  };

  return (
    <Card roundedAbove="sm">
      <BlockStack gap="200">
        <InlineGrid columns="1fr auto">
          <Text as="h2" variant="headingSm">
          { t('contacts') }
          </Text>
          <Button
            onClick={handleContactCreate}
            accessibilityLabel={ t('contact_add') }
            icon={PlusIcon}
          >
            { t('contact_add') }
          </Button>
        </InlineGrid>
        <ResourceList
          resourceName={{singular: t('contact'), plural: t('contacts')}}
          items={contacts}
          renderItem={(item) => {
            const { name, phone, email, position, id } = item;
            const media = <Avatar customer size="md" name={name} />;

            return (
              <ResourceItem
                id={id}
                onClick={handleContactEdit}
                media={media}
                accessibilityLabel={`View details for ${name}`}
              >
                <Text variant="bodyMd" fontWeight="bold" as="h3">
                  {name}
                </Text>
                <div>{position}</div>
                <div>{phone}</div>
                <div>{email}</div>
              </ResourceItem>
            );
          }}
        />
      </BlockStack>
    </Card>
  )
};

export default Contacts;