function formSubmitHandler(formControls, handleFormSubmit) {
  return (event) => {
    const formData = {};
    Object.keys(formControls).forEach((inputIdentifier) => {
      formData[inputIdentifier] = formControls[inputIdentifier].value;
    });
    event.preventDefault();
    handleFormSubmit(formData);
  };
}

export default formSubmitHandler;
