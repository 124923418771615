import axios from "axios";
import { API_URL } from "constants";

export const createFileCategory = async (formData, companyId) => {
  const url = `${API_URL}/${companyId}/files/create-file-category`;
  return axios.post(url, formData).then((response) => response.data);
}

export const updateFileCategory = async (formData, companyId) => {
  const url = `${API_URL}/${companyId}/files/update-file-category/${formData.id}`;
  return axios.patch(url, formData).then((response) => response.data);
}

export const fetchFileCategoryById = async (companyId, id) => {
  const url = `${API_URL}/${companyId}/files/fetch-file-category-by-id/${id}`;
  return axios.get(url).then((response) => response.data);
}

export const fetchTreeFilesCategory = async (companyId) => {
  const url = `${API_URL}/${companyId}/files/fetch-tree-file-categories`;
  return axios.get(url).then((response) => response.data);
};

export const fetchFileById = async (fileId, companyId) => {
  const url = `${API_URL}/${companyId}/files/${fileId}`;
  return axios.get(url).then((response) => response.data);
}