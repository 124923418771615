import { createSlice } from "@reduxjs/toolkit";
import { fetchProjectBoardById } from "containers/project-board-form/project-board-form.service"

const initialState = {
  isLoading: true,
  isError: false,
  errMsg: "",
  projectBoard: null,
  filtersValue: {},
};

const projectBoardContainerSlice = createSlice({
  name: "projectBoard",
  initialState,
  /* eslint-disable no-param-reassign */
  reducers: {
    setDestroy() {
      return initialState;
    },
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
    setError(state, action) {
      state.isLoading = false;
      state.isError = true;
      state.errMsg = action.payload.error;
    },
    setProjectBoard(state, action) {
      state.projectBoard = action.payload;
      state.isLoading = false;
    },
    setFilterValue(state, action) {
      const { key, value } = action.payload;
      if (value) {
        state.filtersValue[key] = value;
      } else {
        delete state.filtersValue[key];
      }
    }
  },
});

// Extract the action creators object and the reducer
const { actions, reducer } = projectBoardContainerSlice;

// Extract and export each action creator by name
export const { setDestroy, setLoading, setError, setProjectBoard, setFilterValue } = actions;
// Export the reducer, either as a default or named export
export default reducer;

export const fetchProjectBoard = (companyId, projectBoardId, filtersValue) => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    const data = await fetchProjectBoardById(companyId, projectBoardId, filtersValue)
    dispatch(setProjectBoard(data));
  } catch (e) {
    dispatch(setError("not_saved"));
    return false;
  }
};
