/* eslint-disable no-useless-computed-key */
import { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { DropZone, Button } from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setModalxComponent, setModalxComponentProps } from "../modalx/modalx.slice";
import { upload } from "services/upload-file.service";
import { patchFile } from "services/upload-file.service";

function UploadFile({ onChange, subject, subjectId, isCatRequired, categoryId, isCustomerFormRequired, parentFileId, isOptionalField }) {
  const dispatch = useDispatch();
  const { companyId, projectId, customerId } = useParams();

  const [openFileDialog, setOpenFileDialog] = useState(false);
  const { t } = useTranslation();

  const handleDropZoneDrop = useCallback(
    // eslint-disable-next-line no-unused-vars
    async (acceptedFiles) => {
      const uploadedFiles = [];
      try {
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < acceptedFiles.length; i++) {
          // eslint-disable-next-line no-await-in-loop
          dispatch(setModalxComponent("Loading"));
          const { data } = await upload(acceptedFiles[i], companyId);
          if (!isCustomerFormRequired) await patchFile({ id: data, subject, subjectId, category:categoryId }, companyId);
          uploadedFiles.push(data);
        }
        if (uploadedFiles.length === 1) {
          dispatch(setModalxComponent("FileForm"));
          dispatch(setModalxComponentProps({
            fileId: uploadedFiles[0],
            isCatRequired,
            customerId,
            companyId, 
            projectId,
            categoryId,
            isCustomerFormRequired,
            parentFileId,
            isOptionalField
          }));
        }
      } catch (e) {
        console.log(e)
      }
    },
    [companyId, dispatch, subject, subjectId]
  );
  const toggleOpenFileDialog = useCallback(
    // eslint-disable-next-line no-shadow
    () => setOpenFileDialog((openFileDialog) => !openFileDialog),
    []
  );

  return (
    <>
    <Button onClick={toggleOpenFileDialog}>{t('upload')}</Button>
      <div style={{ display: 'none'}}>
        <DropZone
          allowMultiple={false}
          openFileDialog={openFileDialog}
          onDropAccepted={handleDropZoneDrop}
          onFileDialogClose={toggleOpenFileDialog}
        >
          <DropZone.FileUpload />
        </DropZone>
      </div>
    </>
  )
}

export default UploadFile;

UploadFile.propTypes = {
  onChange: PropTypes.func.isRequired,
  isFileInputs: PropTypes.bool,
  subject: PropTypes.string,
  isCatRequired:PropTypes.bool,
  isCustomerFormRequired:PropTypes.bool,
  parentFileId: PropTypes.string,
  isOptionalField: PropTypes.bool,
};

UploadFile.defaultProps = {
  isCatRequired:true,
  isCustomerFormRequired:false,
  parentFileId: null,
  isOptionalField:false
}
