import { useNavigate, useLocation, Outlet, useParams } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "hooks";
import { useTranslation } from "react-i18next";
import { Frame, TopBar, Navigation, Loading, Text } from "@shopify/polaris";
import { RootState } from 'configureStore'; // import RootState
import './style.scss';

import {
  checkSession,
  logout,
  toogleUserMenu,
  toogleNav,
  fetchRole,
} from "features/auth/authSlice";
import {
  PersonIcon,
  OrganizationIcon,
  HomeIcon,
  ExitIcon,
  NoteIcon,
  ProductIcon,
  PageAttachmentIcon,
  ProductReferenceIcon,
  CollectionReferenceIcon
} from "@shopify/polaris-icons";
import Modalx from "features/modalx/modalx";
import CompanySelect from "features/company-select/company-select.jsx";
import { useEffect } from "react";

type Role = {
  isMaster: boolean,
  role: {
    name: 'USER' | 'ADMIN'
  }
}

type options = {
  value: string,
  label: string
}

function Dashboard() {
    const { companyId } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const { session, isLoading, userMenuActive, isError, navActive, role }: { session: { email: string } | null, isLoading: boolean, userMenuActive: boolean, isError: boolean, navActive: boolean, role: null | Role } = useAppSelector((state: RootState) => state.authReducer);
    const dispatch = useAppDispatch();
    useEffect(() => {
      dispatch(fetchRole(companyId))
    }, [companyId])

    const { companiesCollection } = useAppSelector((state: RootState) => state.companySelectReducer
    );

    const selectedCompany:options[] = companiesCollection.filter((company:options) => company.value === companyId)

    if (!role) {
      return <Loading />
    }

    const handleCompanyChange = (id: string) => {
    
      const currentPath = location.pathname;
      const parts = currentPath.split('/');
      // Assuming the company ID is the second part of the URL after '/app/'
      if (parts[1] === 'app' && parts[2]) {
        parts[2] = id; // Replace the company ID with the new one
        const newPath = parts.join('/');
        const parentPath = parts.slice(0, 4).join('/');
        navigate(parentPath, { replace: true });  
      }
    };

    const handleLogOut = async () => {
      await dispatch(logout());
    };
  
    const handleToogleUserMenu = async () => {
      await dispatch(toogleUserMenu());
    };
  
    const handleNavigationToggle = async () => {
      await dispatch(toogleNav());
    };

    
    const settingsMenu = [
      {
        url: `/app/${companyId}/users`,
        label: t("users"),
        icon: PersonIcon,
      },
      {
        url: `/app/${companyId}/files/categories`,
        label: t("files_categories"),
        icon: PageAttachmentIcon,
      },
      {
        url: `/app/${companyId}/files/templates`,
        label: t("files_templates"),
        icon: PageAttachmentIcon,
      },
    ];

    const settingsMenuNode = <Navigation.Section
      title={t("menu_item_settings_title")}
      items={settingsMenu}
    />
  
    const userMenuActions = [
      {
        items: [
          { content: t("log_out"), icon: ExitIcon, onAction: handleLogOut },
        ],
      },
    ];
    const userMenuMarkup = (
      <TopBar.UserMenu
        initials="TL"
        actions={userMenuActions}
        name={session!.email}
        open={userMenuActive}
        onToggle={handleToogleUserMenu}
      />
    );

    const DynamicLogoSuffix = (
      <div className="logo_text">
        <Text as="h1" fontWeight="semibold">
          {selectedCompany.length > 0 ? selectedCompany[0].label : ''}
        </Text>
      </div>
    );
    const logo = {
      topBarSource:'/',
      width: 0,
      accessibilityLabel: '',
    };

    const topBarMarkup = (
      <div className="company_select_box">
        <TopBar
          showNavigationToggle
          secondaryMenu={<CompanySelect onChange={handleCompanyChange} isList={false} orgChart={false}/>}
          userMenu={userMenuMarkup}
          onNavigationToggle={handleNavigationToggle}
          logoSuffix={DynamicLogoSuffix}
        />
      </div>
    );
    const navigationMarkup = (
      <Navigation location="/">
        <Navigation.Section
          items={[
            {
              url: `/app`,
              label: t('Dashboard'),
              icon: HomeIcon,
            },
            {
              url: `/app/${companyId}/customers`,
              label: t("customers"),
              icon: OrganizationIcon,
            },
            {
              url: `/app/${companyId}/products`,
              label: t("products"),
              icon: ProductIcon,
            },
            {
              url: `/app/${companyId}/reservations`,
              label: t("reservations"),
              icon: ProductReferenceIcon,
            },
            {
              url: `/app/${companyId}/project-boards`,
              label: t("projects"),
              icon: PageAttachmentIcon,
            },
            {
              url: `/app/${companyId}/documents`,
              label: t("documents"),
              icon: PageAttachmentIcon,
            },
            {
              url: `/app/${companyId}/customfields`,
              label: t("custom_fields"),
              icon: CollectionReferenceIcon,
            },
          ]}
        />
        { // @ts-expect-error
        role.isMaster && settingsMenuNode }
      </Navigation>
    );

    return (
      <Frame
        topBar={topBarMarkup}
        navigation={navigationMarkup}
        showMobileNavigation={navActive}
        onNavigationDismiss={handleNavigationToggle}
        logo={logo}
      >
        <Outlet />
        <Modalx />
      </Frame>
    )
}

export default Dashboard