import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  FormLayout,
  TextField,
  Form,
  Button,
  Banner,
  Select,
} from "@shopify/polaris";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { fetchFilesCategories } from 'containers/files-categories/files-categories-container.slice'
import { createFileCategory, updateFileCategory, fetchFileCategoryById } from "./files-category-form.service"

function FilesCategoryForm({ onSubmit, id }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { companyId } = useParams();
  const [subject, setSubject] = useState();
  const [title, setTitle] = useState();
  const [parentCategory, setParentCategory] = useState();
  const [successMessage, setSuccessMessage] = useState()
  const [errMsg, setErrMsg] = useState()
  const [isLoading, setIsLoading] = useState(false)


  const handleFormSubmit = async () => {
    const formData = {
      id,
      subject,
      title,
      parentCategory,
    };
    const action = id ? updateFileCategory : createFileCategory
    try {
      setIsLoading(true)
      const result = await action(formData, companyId)
      dispatch(fetchFilesCategories(companyId))
      setSuccessMessage('ok')
      if (onSubmit) {
        onSubmit(result);
      }
      setIsLoading(false)
    } catch (e) {
      console.error(e)
      setErrMsg('error')
      setIsLoading(false)
    }
  };

  const subjectOptions = [
    {label: t('files_categories_subject_customer'), value: 'customer'},
  ];

  const fetchData = async (id) => {
    const data = await fetchFileCategoryById(companyId, id)
    setSubject(data.subject)
    setTitle(data.title)
    setParentCategory(data.title)
  }

  useEffect(() => {
    setSubject(subjectOptions[0].value)
  }, [])

  useEffect(() => {
    if (id) {
      fetchData(id)
    }
  }, [id])

  return (
    <Form className="customer-form_form">
      <FormLayout>
        <div className="form-warnings">
          {errMsg && (
            <div className="">
              <Banner status="critical">{t(errMsg)}</Banner>
            </div>
          )}
          {successMessage && (
            <div className="">
              <Banner status="info">{t(successMessage)}</Banner>
            </div>
          )}
        </div>
        <TextField
          label={t("files_category_title")}
          id="title"
          name="title"
          value={title}
          onChange={setTitle}
        />
        <Select
          label={t('files_categories_subject')}
          options={subjectOptions}
          onChange={setSubject}
          value={subject}
        />
        <Button
          primary
          onClick={handleFormSubmit}
          disabled={!title || !subject}
          loading={isLoading}
        >
          {t("save")}
        </Button>
      </FormLayout>
    </Form>
  );
}

export default FilesCategoryForm;
