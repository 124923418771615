import { useEffect, useState } from "react";
import { useAppDispatch } from "hooks";
import {
  FormLayout,
  TextField,
  Form,
  Button,
  Banner,
} from "@shopify/polaris";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { fetchProjectBoards } from 'containers/project-boards/project-boards.slice'
import { createProjectBoard, updateProjectBoard, fetchProjectBoardById } from "./project-board-form.service"

function ProjectBoardForm({ onSubmit, id }) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { companyId } = useParams();
  const [name, setName] = useState();
  const [successMessage, setSuccessMessage] = useState()
  const [errMsg, setErrMsg] = useState()
  const [isLoading, setIsLoading] = useState(false)


  const handleFormSubmit = async () => {
    const formData = {
      id,
      name,
    };
    const action = id ? updateProjectBoard : createProjectBoard
    try {
      setIsLoading(true)
      const result = await action(formData, companyId)
      dispatch(fetchProjectBoards(companyId))
      setSuccessMessage('ok')
      if (onSubmit) {
        onSubmit(result);
      }
      setIsLoading(false)
    } catch (e) {
      console.error(e)
      setErrMsg('error')
      setIsLoading(false)
    }
  };

  const fetchData = async (id) => {
    const data = await fetchProjectBoardById(companyId, id)
    setName(data.name)
  }


  useEffect(() => {
    if (id) {
      fetchData(id)
    }
  }, [id])

  return (
    <Form className="project-board-form">
      <FormLayout>
        <div className="form-warnings">
          {errMsg && (
            <div className="">
              <Banner status="critical">{t(errMsg)}</Banner>
            </div>
          )}
          {successMessage && (
            <div className="">
              <Banner status="info">{t(successMessage)}</Banner>
            </div>
          )}
        </div>
        <TextField
          label={t("project-board-name")}
          id="name"
          name="name"
          value={name}
          onChange={setName}
        />
        <Button
          primary
          onClick={handleFormSubmit}
          disabled={!name}
          loading={isLoading}
        >
          {t("save")}
        </Button>
      </FormLayout>
    </Form>
  );
}

export default ProjectBoardForm;
