import axios from "axios";
import { API_URL } from "../../constants";

export const createProjectBoard = async (formData, companyId) => {
  const url = `${API_URL}/${companyId}/project-boards`;
  return axios.post(url, formData).then((response) => response.data);
}

export const updateProjectBoard = async (formData, companyId) => {
  const url = `${API_URL}/${companyId}/project-boards/${formData.id}`;
  return axios.patch(url, formData).then((response) => response.data);
}

export const fetchProjectBoardById = async (companyId, id, filtersValue) => {
  console.log("fetchProjectBoardById", companyId, id, filtersValue);
  const url = `${API_URL}/${companyId}/project-boards/${id}`;
  return axios.get(url, { params: filtersValue }).then((response) => response.data);
}
 