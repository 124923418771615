import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  FormLayout,
  TextField,
  Form,
  Button,
  Banner,
} from "@shopify/polaris";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { fetchFileCategoryById, createFileCategory, updateFileCategory } from "services/files-category.service";
import { fetchCategoryDetails } from 'containers/document/category-tree/category-tree.Slice';
import { AppDispatch } from "configureStore";


interface AddFilesCategoryFormProps {
  onSubmit?: (result: any) => void;
  id?: string;
  parentId?: string | null
}

const AddFilesCategoryForm: React.FC<AddFilesCategoryFormProps> = ({ onSubmit, id, parentId }) => {
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();
  const { companyId } = useParams<{ companyId: string }>();
  const [subject, setSubject] = useState<string>("customer");
  const [title, setTitle] = useState<string>("");
  const [parentCategory, setParentCategory] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | undefined>();
  const [errMsg, setErrMsg] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchData = async (id:string) => {
    const data = await fetchFileCategoryById(companyId, id)
    setSubject(data.subject)
    setTitle(data.title)
    setParentCategory(data.parentCategory)
  }


  useEffect(() => {
    if (id) {
      fetchData(id)
    }
  }, [id])

  const handleFormSubmit = async () => {
    const formData = {
      id,
      subject,
      title,
      parentCategory: parentId ? parentId : parentCategory,
    };
    const action = id ? updateFileCategory : createFileCategory
    try {
      setIsLoading(true);
      const result = await action(formData, companyId);
      if (companyId) {
        dispatch(fetchCategoryDetails({ companyId }));
      }
      setSuccessMessage('ok');
      if (onSubmit) {
        onSubmit(result);
      }
    } catch (e) {
      console.error(e);
      setErrMsg('error');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Form onSubmit={() =>''}>
      <FormLayout>
        <div className="form-warnings">
          {errMsg && (
            <div className="">
              <Banner >{t(errMsg)}</Banner>
            </div>
          )}
          {successMessage && (
            <div className="">
              <Banner >{t(successMessage)}</Banner>
            </div>
          )}
        </div>
        <TextField
          label={t("files_category_title")}
          id="title"
          name="title"
          value={title}
          onChange={(value) => setTitle(value)}
          autoComplete="off"
        />
        
        <Button
          onClick={handleFormSubmit}
          disabled={!title || !subject}
          loading={isLoading}
        >
          {t("save")}
        </Button>
      </FormLayout>
    </Form>
  );
};

export default AddFilesCategoryForm;
