import { useEffect } from "react";
import { isMobile, isBrowser } from 'react-device-detect';
import { useSelector, useDispatch } from "react-redux";
import {
  Page,
  Layout,
  Card,
  Filters,
  ResourceList,
  Avatar,
  ResourceItem,
  Text,
} from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import { Link, useParams, Outlet } from "react-router-dom";
import View from "../../components/view/View";

import { fetchCustomers, setFilterValue } from "./customers.slice";
import { setModalxComponent } from "../modalx/modalx.slice";
import CustomerView from "../customer-view/customer-view";
import Currency from 'components/format/currency/currency'
import UserSelect from "features/user-select/user-select"
import DataTable from 'components/datatable/Datatable'

function Customers() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { customerId, companyId } = useParams();

  const { customersCollection, isLoading, filtersValue, users } = useSelector((state) => state.customersReducer);

  const handleCreateCustomer = () => {
    dispatch(setModalxComponent("CustomerForm"));
  };

  const disambiguateLabel = (key, value) => {
    switch (key) {
      case "textSearch":
        return `${t("search")}: ${t(value)}`;
      case "user":
        return `${t("assigned_user")} ${users[value]}`;
      default:
        return value;
    }
  };

  useEffect(() => {
    dispatch(fetchCustomers(companyId, filtersValue));
  }, [dispatch, filtersValue, companyId]);

  const handleFilterChange = (value, key) => {
    dispatch(setFilterValue({ key, value }));
  };
  const handleQueryChange = (value) => {
    dispatch(setFilterValue({ key: "textSearch", value }));
  };
  const handleQueryValueRemove = () => {
    dispatch(setFilterValue({ key: "textSearch", value: "" }));
  };
  const handleClearAll = () => {};
  const appliedFilters = [];
  Object.keys(filtersValue).forEach((key) => {
    if (filtersValue[key] && filtersValue[key].length > 0) {
      appliedFilters.push({
        key,
        label: disambiguateLabel(key, filtersValue[key]),
        onRemove: () => handleFilterChange(null, key),
      });
    }
  });
  const filters = [
    {
      key: 'user',
      label: t('assigned_user'),
      filter: (
        <UserSelect
          value={filtersValue.user}
          name="user"
          onChange={handleFilterChange}
          isRequired={false}
          allowMultiple={true}
        />
      )
    }
  ];
  let content = <Outlet />

  const filtersNode = <Filters
    queryValue={filtersValue.textSearch}
    filters={filters}
    appliedFilters={appliedFilters}
    onQueryChange={handleQueryChange}
    onQueryClear={handleQueryValueRemove}
    onClearAll={handleClearAll}
  />

  if (!customerId) {
    content = <Page
      fullWidth
      primaryAction={{
        content: t("create_customer"),
        onAction: handleCreateCustomer,
      }}
      title={t("customers")}
    >
      <Card>
        {isMobile &&
        <ResourceList
          resourceName={{singular: t("customer"), plural: t("customers")}}
          items={customersCollection}
          filterControl={filtersNode}
          flushFilters
          renderItem={(item) => {
            const {id, assignedUser, name, _overdueDebt, _turnover} = item;
            const media = <Avatar customer size="md" name={name} />;

            return (
              <ResourceItem
                id={id}
                url={id}
                media={media}
                accessibilityLabel={`View details for ${name}`}
              >
                <Text variant="bodyMd" fontWeight="bold" as="h3">
                  {name}
                </Text>
                <div>{assignedUser?.name || '-'}</div>
                <div className="flex justify-space">
                  <div>{ _turnover > 0 && <Text alignment="start"><Currency isSymbol={true} value={_turnover} /></Text> }</div>
                  <div>{ _overdueDebt > 0 && <Text alignment="end">{t("debt")}: <Currency isSymbol={true} value={_overdueDebt} /></Text> }</div>
                </div>
              </ResourceItem>
            );
          }}
        />}
        {isBrowser && <div>
          <div style={{ padding: "16px", display: "flex" }}>
              <div style={{ flex: 1 }}>
                {filtersNode}
              </div>
            </div>
            <DataTable
              collection={customersCollection}
              columns={[
                { key: '#' },
                { key: 'name', name: t('title'), url: 'id' },
                { key: 'assignedUser.name', name: t('assigned_user') },
                { key: '_turnover', type: 'currency', name: t('turnover') },
                { key: '_debt', type: 'currency', name: t('debt') },
                { key: '_overdueDebt', type: 'currency', name: t('overdue_debt')}
              ]}
            />
          </div>}
      </Card>
    </Page>
  }

  return (
    <Layout>
      <Layout.Section>
        {content}
      </Layout.Section>
    </Layout>
  )
}

export default Customers;
