import { createSlice } from "@reduxjs/toolkit";
import { restore } from "../auth/auth.service";

const RestoreSlice = createSlice({
  name: "restore",
  initialState: {
    isFormValid: false,
    formControls: {
      email: {
        value: "",
        isValid: false,
        validationRules: {
          isRequired: true,
        },
        isTouched: false,
      },
    },
    isLoading: false,
    isError: false,
    errMsg: null,
  },
  /* eslint-disable no-param-reassign */
  reducers: {
    setLoading(state) {
      state.isLoading = true;
    },
    setError(state, action) {
      state.isLoading = false;
      state.isSuccessful = false;
      state.isError = true;
      state.errMsg = action.payload.error;
    },
    setFormData(state, action) {
      state.formControls = action.payload.validatedFormControls;
      state.isFormValid = action.payload.isFormValid;
    },
    setSuccess(state) {
      state.isSuccessful = true;
    },
  },
  /* eslint-enable */
});

// Extract the action creators object and the reducer
const { actions, reducer } = RestoreSlice;

// Extract and export each action creator by name
export const { setLoading, setError, setFormData, setSuccess } = actions;

// Export the reducer, either as a default or named export
export default reducer;

export const submit =
  ({ email }) =>
  async (dispatch) => {
    dispatch(setLoading());
    try {
      await restore({ email });
      dispatch(setSuccess(true));

      return true;
    } catch (e) {
      dispatch(setError(e));
      return false;
    }
  };
