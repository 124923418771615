import { useTranslation } from "react-i18next";
import DataTable from 'components/datatable/Datatable';

type Product = {
    productId: string,
    productName: string,
    totalQuantity: number,
    totalAmount: number,
    invoiceCount: number,
    lastInvoiceDate: string,
    lastInvoiceQuantity: number,
    lastInvoiceAmount: number,
    lastInvoiceId: string
}

function Products({ products, isProjectTbl }: { products: Product[], isProjectTbl?: boolean }) {
  const { t } = useTranslation();

  return (
    <>
      {isProjectTbl != true ?
        <DataTable
          collection={products}
          columns={[
            { key: '#' },
            { key: 'productName', name: t('name') },
            { key: 'totalQuantity', isDisabledTotal: true, type: 'number', name: t('total_quantity') },
            { key: 'totalAmount', type: 'currency', name: t('total_amount') },
            { key: 'invoiceCount', isDisabledTotal: true, type: 'number', name: t('invoice_count') },
          ]}
        />
        : <DataTable
          collection={products}
          columns={[
            { key: 'title', name: t('name') },
            { key: 'quantity', isDisabledTotal: true, type: 'number', name: t('total_quantity') },
            { key: 'price', type: 'currency', name: t('price') },
            { key: 'totalPrice', type: 'currency', name: t('total_amount') },
          ]}
        />}
    </>
  );


}


export default Products;