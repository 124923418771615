import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { Select, Text } from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { fetch } from "../../services/user-select.service";

function UserSelect({ value, onChange, isRequired, name }) {
  const { t } = useTranslation();
  const [collection, setCollection] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { companyId } = useParams();
  const [error, setError] = useState("");

  const handleChange = (selectedValue) => {
    if (isRequired && !selectedValue) {
      setError("field_is_required");
    } else {
      setError("");
    }

    onChange(selectedValue, name);
  };

  useEffect(() => {
    async function fetchData() {
      try {
        setIsLoading(true);
        const data = await fetch(companyId);
        const dataCollection = _.map(data.usersCollection, (row) => ({
          label: row.name || row.email,
          value: row.id,
        }));
        dataCollection.unshift({ label: " - ", value: "" });
        setCollection(dataCollection);
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
      }
    }
    fetchData();
  }, [companyId]);

  return (
    <div className="user-select">
      <Select
          label={t("select_user")}
          options={collection}
          value={value}
          disabled={isLoading}
          onChange={handleChange}
          error={error}
        />
    </div>
  );
}

export default UserSelect;

UserSelect.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  // eslint-disable-next-line react/require-default-props
  value: PropTypes.string,
  isRequired: PropTypes.bool,
  allowMultiple: PropTypes.bool,
};

UserSelect.defaultProps = {
  isRequired: false,
};
