import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// @ts-ignore
import { useAppDispatch } from "hooks.ts";
import _ from "lodash";
import { setIsModalxOpen } from "../modalx/modalx.slice";
import { create } from "../tasks/tasks.service";
import { fetchTasks } from "../tasks/tasks.slice";

interface TaskCreateState {
  isLoading: Boolean;
  isError: Boolean;
  isSuccessfullySaved: Boolean;
  errMsg: Error | null;
}

type FormData = {
  taskId: string;
  title: string;
  assignedTo: string;
  description: string;
  files: { id: string }[];
  dueDate: Date;
};

const initialState: TaskCreateState = {
  isLoading: false,
  isError: false,
  isSuccessfullySaved: false,
  errMsg: null,
};
const LoginSlice = createSlice({
  name: "taskCreate",
  initialState,
  /* eslint-disable no-param-reassign */
  reducers: {
    setLoading: (state: TaskCreateState) => {
      state.isLoading = true;
    },
    setError: (state: TaskCreateState, action: PayloadAction<Error>) => {
      state.isLoading = false;
      state.isError = true;
      state.errMsg = action.payload;
    },
    setSuccessfullySaved: (state: TaskCreateState) => {
      state.isLoading = false;
      state.isError = false;
      state.errMsg = null;
      state.isSuccessfullySaved = true;
    },
    destroy: (state: TaskCreateState) => {
      _.assign(state, initialState);
    },
  },
  /* eslint-enable */
});

// Extract the action creators object and the reducer
const { actions, reducer } = LoginSlice;

// Extract and export each action creator by name
export const { setLoading, setError, setSuccessfullySaved, destroy } = actions;

// Export the reducer, either as a default or named export
export default reducer;

export const submit = (data: FormData) => async (dispatch: useAppDispatch) => {
  dispatch(setLoading());
  try {
    await create(data);
    dispatch(destroy());
    dispatch(setIsModalxOpen(false));
    dispatch(fetchTasks());
    return true;
  } catch (e: any) {
    dispatch(setError(e));
    return false;
  }
};
