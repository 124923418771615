import { LegacyCard, InlineGrid, EmptyState } from '@shopify/polaris';
import React, { useEffect } from 'react';
import { API_URL } from "constants.js";
import { useParams, useNavigate } from "react-router-dom"
import { fetchCategoryFilesDetails, setDestroy } from "containers/document/category-files/catFiles.Slice";
import { RootState, AppDispatch } from "configureStore";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import HeaderLine from "components/routePath/HeaderLine";
import UploadFile from "features/upload-file/upload-file";
import { setModalxComponent, setModalxComponentProps } from "features/modalx/modalx.slice";
import DataTable from 'components/datatable/Datatable';
import Loading from "components/loading/Loading";


interface DocumentDataTableProps {
  id: string;
}

const CategoryFileContainer: React.FC<DocumentDataTableProps> = ({ id }) => {
  const { t } = useTranslation();
  const history = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const { companyId } = useParams<{ companyId: string }>();


  const loadCategoryFilesData = () => {
    if(id && companyId){
      dispatch(fetchCategoryFilesDetails({companyId, catId:id}));
    }
  }

  const { catFilesData, isLoading } = useSelector(
    (state: RootState) => state.documentFilesReducer
  );

  useEffect(() => {
    loadCategoryFilesData()
    return () => {
      setDestroy();
    };
  }, [companyId, id]);

  const handleEditClick = (fileId:string) => {
    dispatch(setModalxComponent("FileForm"))
    dispatch(setModalxComponentProps({ 
      fileId,
      companyId,
      isCatRequired:true,
      isCustomerFormRequired:true,
      isDateTo:true,
      isOptionalField:true
    }))
  }

  const handleRowClick = (companyId:string | undefined, cateId:string, fileId:string) => {
    history(`/app/${companyId}/documents/${cateId}/${fileId}`);
  }

  const collection = catFilesData?.files?.map((file: any) => ({
    id: file.id,
    name: file.name,
    customerName: file.customer?.name,
    createdAt: file.createdAt,
    dateTo: file.dateTo,
    downloadUrl: `${API_URL}/${companyId}/files/download/${file.id}`,
  })) || [];

  const handleOnFileChange = () => {
  }

  if(isLoading){
    return <Loading size="small" /> 
  }

  return (
    id  ?
    <>
      <div style={{ marginBottom: '20px' }}>
        <InlineGrid columns="1fr auto">
          <HeaderLine catId={id}/>
          <UploadFile 
            onChange={handleOnFileChange} 
            subject="customer" 
            subjectId={id} 
            isCatRequired={false} 
            categoryId={id} 
            isCustomerFormRequired={true}
            isOptionalField={true}
            />
        </InlineGrid>
      </div>
      {!catFilesData?.files?.length ?
      (
        <EmptyState
          heading={t('no_files')}
          image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"
        >
          <UploadFile 
            onChange={handleOnFileChange} 
            subject="customer" 
            subjectId={id} 
            isCatRequired={false} 
            categoryId={id} 
            isCustomerFormRequired={true}
            isOptionalField={true}
            />
        </EmptyState>
      ):
      <LegacyCard>
        <DataTable
          collection={collection}
          columns = {[
            { key: 'name', name: t('name'), onClick: (item: any) => handleRowClick(companyId, id, item.id), type:'clickableColumn' },
            { key: 'customerName', name: t('customer'), onClick: (item: any) => handleRowClick(companyId, id, item.id), type:'clickableColumn' },
            { key: 'createdAt', name: t('createdAt'), onClick: (item: any) => handleRowClick(companyId, id, item.id), type: 'clickabledate' },
            { key: 'dateTo', name: t('dateTo'), onClick: (item: any) => handleRowClick(companyId, id, item.id), type:'clickabledate' },
            {
              key: 'Edit',
              name: t('edit'),
              onClick: (item: any) => handleEditClick(item.id),
              type: 'actionBtn'
            },
            {
              key: 'downloadUrl',
              name: t('download'),
              onClick: (item: any) => window.open(item.downloadUrl, '_blank'),
              url: 'downloadUrl',
              type: 'downloadurl'
            }
          ]}
        />
      </LegacyCard>
      }      
    </> : <></>
  )   
}

export default CategoryFileContainer;