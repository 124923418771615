import React from "react";
import { useSelector, useDispatch } from "react-redux";
import changeHandler from "common/form/changeHandler";
import submitHandler from "common/form/submitHandler";
import {
  FormLayout,
  TextField,
  Form,
  Button,
  Card,
  TextContainer,
  Text
} from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import { setFormData, submit } from "./company-create.slice";

function CompanyCreate() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { formControls, isLoading, isFormValid, isSuccessfullySaved } =
    useSelector((state) => state.companyCreateReducer);

  const handleFormChange = (formData) => dispatch(setFormData(formData));
  const handleFormSubmit = async (formData) => {
    await dispatch(submit(formData));
  };
  if (isSuccessfullySaved) {
    return (
      <div className="company-create">
        <TextContainer>
          <Text variant="headingMd" as="h2">
            {t("company_created_successfully", {
              companyName: formControls.name.value,
            })}
          </Text>
        </TextContainer>
      </div>
    );
  }

  return (
    <div className="company-create">
      <Card title={t("create_company")} sectioned>
        <Form
          className="company-create_form"
          onSubmit={submitHandler(formControls, handleFormSubmit)}
        >
          <FormLayout>
            <TextField
              label={t("company_name")}
              type="text"
              id="name"
              autoComplete="off"
              value={formControls.name.value}
              onChange={changeHandler(formControls, handleFormChange)}
            />
            <TextField
              label={t("company_code")}
              type="text"
              id="code"
              autoComplete="off"
              value={formControls.code.value}
              onChange={changeHandler(formControls, handleFormChange)}
            />
            <TextField
              label={t("company_vat")}
              type="text"
              id="vat"
              autoComplete="off"
              value={formControls.vat.value}
              onChange={changeHandler(formControls, handleFormChange)}
            />
            <TextField
              label={t("company_address")}
              type="text"
              id="address"
              autoComplete="off"
              value={formControls.address.value}
              onChange={changeHandler(formControls, handleFormChange)}
            />
            <TextField
              label={t("company_description")}
              type="text"
              id="description"
              autoComplete="off"
              multiline={4}
              value={formControls.description.value}
              onChange={changeHandler(formControls, handleFormChange)}
            />
            <Button loading={isLoading} disabled={!isFormValid} submit>
              {t("create_company")}
            </Button>
          </FormLayout>
        </Form>
      </Card>
    </div>
  );
}

export default CompanyCreate;
