import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { setIsModalxOpen } from "features/modalx/modalx.slice";
import { fetchCustomer } from 'containers/customer/customer-container.slice';
import { fetchProject } from 'containers/project-view-container/project-container.slice';
import { fetchCategoryFilesDetails } from 'containers/document/category-files/catFiles.Slice';
import { fetchCategoryDetails } from 'containers/document/category-tree/category-tree.Slice';
import { fetchFileDetails } from "containers/file/file.Slice";
import {patchFile} from "services/upload-file.service";

const initialState = {
  isLoading: false,
  isError: false,
  isSuccessfullySaved: false,
  errMsg: null,
};
const fileFormSlice = createSlice({
  name: "fileForm",
  initialState,
  /* eslint-disable no-param-reassign */
  reducers: {
    setLoading(state) {
      state.isLoading = true;
    },
    setError(state, action) {
      state.isLoading = false;
      state.isError = true;
      state.errMsg = action.payload.error;
    },
    setSuccessfullySaved(state) {
      state.isLoading = false;
      state.isError = false;
      state.errMsg = null;
      state.isSuccessfullySaved = true;
    },
    destroy(state) {
      _.assign(state, initialState);
    },
  },
  /* eslint-enable */
});

// Extract the action creators object and the reducer
const { actions, reducer } = fileFormSlice;

// Extract and export each action creator by name
export const { setLoading, setError, setSuccessfullySaved, destroy } = actions;

// Export the reducer, either as a default or named export
export default reducer;

export const submit = (data) => async (dispatch) => {
  dispatch(setLoading());
  try {
    const { isCustomerFormRequired, companyId, ...apiParams } = data
    await patchFile(apiParams, companyId);
    switch (data.subject) {
      case "customer":
        if(data.relatedTo) dispatch(fetchFileDetails({fileId:data.relatedTo, companyId }));
        else if(data.isCustomerFormRequired){
          dispatch(fetchCategoryFilesDetails({companyId, catId:data.category })) 
          dispatch(fetchCategoryDetails({companyId }))
          dispatch(fetchFileDetails({fileId:data.id, companyId }))
        }
        else dispatch(fetchCustomer({companyId, customerId:data.customerId }))
        break;
      case "project":
        dispatch(fetchProject({ companyId, projectId:data.projectId }))
        break;
      default:
    }
    dispatch(destroy());
    dispatch(setIsModalxOpen(false));
    return true;
  } catch (e) {
    dispatch(setError("400"));
    return false;
  }
};