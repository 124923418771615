/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import { Sheet, DatePicker, TextField } from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import { format, endOfDay } from "date-fns";
import { Props } from "./types";

function DatePickerInput({ name, value, onChange }: Props) {
  const [{ month, year }, setDate] = useState<{ month: number; year: number }>({
    month: Number(format(endOfDay(new Date()), "M")),
    year: Number(format(endOfDay(new Date()), "yyyy")),
  });
  const [selectedDates, setSelectedDates] = useState<{
    start: Date;
    end: Date;
  }>();
  const { t } = useTranslation();
  const [isActive, setIsActive] = useState(false);

  const handleMonthChange = useCallback(
    (monthVal: number, yearVal: number) =>
      setDate({ month: monthVal, year: yearVal }),
    []
  );

  const onCloseDatePicker = () => {
    setIsActive(false);
  };

  const handleDatePicker = () => {
    setIsActive(true);
  };

  return (
    <>
      <div
        onClick={handleDatePicker}
        onKeyDown={handleDatePicker}
        role="button"
        tabIndex={0}
      >
        <TextField
          label={name}
          id={name}
          name={name}
          autoComplete="off"
          value={
            value ||
            (selectedDates ? format(selectedDates.start, "yyyy-MM-dd") : "")
          }
        />
      </div>
      <Sheet
        open={isActive}
        accessibilityLabel={t("date_picker")}
        onClose={onCloseDatePicker}
      >
        <DatePicker
          month={month}
          year={year}
          onChange={(e) => {
            setSelectedDates(e);
            onChange(format(e.start, "yyyy-MM-dd"));
          }}
          onMonthChange={handleMonthChange}
          selected={
            value
              ? { start: new Date(value), end: new Date(value) }
              : selectedDates
          }
        />
      </Sheet>
    </>
  );
}

DatePickerInput.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

DatePickerInput.defaultProps = {
  name: "Date",
  value: "",
  onChange: () => {},
};

export default DatePickerInput;
