import { useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { Select, Spinner, Button, ResourceList, ResourceItem, Text, Avatar, Card } from "@shopify/polaris";
import { PlusCircleIcon } from "@shopify/polaris-icons";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { fetchCompanies } from "./company-select.slice";
import { setModalxComponent } from "../modalx/modalx.slice";
import "./company-select.scss";
import OrgChart from "components/orgChart/orgChart";


function CompanySelect({ onChange, isList, orgChart }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { companyId } = useParams();
  const { companiesCollection, companiesCollectionRaw, isLoading } = useSelector(
    (state) => state.companySelectReducer
  );

  const handleCreateCompany = () => {
    dispatch(setModalxComponent("CompanyCreate"));
  };

  const handleCompanyChange = (companyToSelect) => {
    onChange(companyToSelect);
  };

  const createButton = (
    <Button onClick={handleCreateCompany} size="slim" icon={PlusCircleIcon} />
  );

  useEffect(() => {
    dispatch(fetchCompanies());
  }, [dispatch]);

  if (isLoading && companiesCollection.length === 0) {
    return <Spinner accessibilityLabel="" size="small" />;
  }

  if (companiesCollection.length === 0 && !isLoading) {
    return createButton;
  }

  if(orgChart){
    return (
      <OrgChart companyList={companiesCollectionRaw}/>
    )
  }

  if (isList) {
    return <Card>
        <ResourceList
    resourceName={{singular: 'customer', plural: 'customers'}}
    items={companiesCollectionRaw}
    renderItem={(item) => {
      const {id, name, address} = item;
      const media = <Avatar customer size="md" name={name} />;

      return (
        <ResourceItem
          id={id}
          url={id}
          media={media}
          accessibilityLabel={`View details for ${name}`}
        >
          <Text variant="bodyMd" fontWeight="bold" as="h3">
            {name}
          </Text>
          <div>{address}</div>
        </ResourceItem>
      );
    }}
  />
  </Card>
  }

  return (
    <div className="company-select">
      <Select
        label={t("Selected company")}
        labelInline
        options={companiesCollection}
        onChange={handleCompanyChange}
        value={companyId}
        disabled={isLoading}
      />
      {createButton}
    </div>
  );
}

export default CompanySelect;

CompanySelect.propTypes = {
  onChange: PropTypes.func,
};

CompanySelect.defaultProps = {
  onChange: () => {},
};
