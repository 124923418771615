import { createSlice } from "@reduxjs/toolkit";
import { fetch } from "./products.service";

const ProductsSlice = createSlice({
  name: "products",
  initialState: {
    productsCollection: [],
    isLoading: false,
    isError: false,
    errMsg: null,
    queryValue: null,
    filtersValue: {},
  },
  /* eslint-disable no-param-reassign */
  reducers: {
    setLoading(state) {
      state.isLoading = true;
      state.isError = false;
    },
    setProducts(state, action) {
      state.isLoading = false;
      state.productsCollection = action.payload.productsCollection;
    },
    setError(state, action) {
      state.isError = true;
      state.errMsg = action.payload.errMsg;
    },
    setFilterValue(state, action) {
      const { key, value } = action.payload;
      state.filtersValue[key] = value;
    },
  },
  /* eslint-enable */
});

// Extract the action creators object and the reducer
const { actions, reducer } = ProductsSlice;

// Extract and export each action creator by name
export const { setLoading, setProducts, setError, setFilterValue } = actions;

// Export the reducer, either as a default or named export
export default reducer;

export const fetchProducts = (companyId, filtersValue) => async (dispatch) => {
  dispatch(setLoading());
  try {
    const { productsCollection } = await fetch(companyId, filtersValue);
    dispatch(setProducts({ productsCollection }));
  } catch (e) {
    dispatch(setError(""));
  }
};
