import validate from "./validate";

function changeHandler(formControls, onFormChangeHandler) {
  return (value, name) => {
    const validatedFormControls = JSON.parse(JSON.stringify(formControls));
    const updatedFormElement = validatedFormControls[name];
    updatedFormElement.value = value;

    const updatedFormElementValidation = validate(
      value,
      updatedFormElement.validationRules
    );
    updatedFormElement.isTouched = true;
    updatedFormElement.isValid = updatedFormElementValidation.isValid;
    updatedFormElement.validationError = !updatedFormElement.isValid
      ? updatedFormElementValidation.error
      : undefined;
    validatedFormControls[name] = updatedFormElement;

    let isFormValid = true;
    Object.keys(validatedFormControls).forEach((inputIdentifier) => {
      const validation = validate(
        validatedFormControls[inputIdentifier].value,
        validatedFormControls[inputIdentifier].validationRules,
        formControls
      );
      validatedFormControls[inputIdentifier].isValid = validation.isValid;
      validatedFormControls[inputIdentifier].validationError =
        !validation.isValid ? validation.error : undefined;
      isFormValid = validation.isValid && isFormValid;
    });
    onFormChangeHandler({ validatedFormControls, isFormValid });
  };
}

export default changeHandler;
