import { useEffect, useState } from "react";
import axios from "axios";
import PropTypes from "prop-types";
import _ from "lodash";
import { Select, Text } from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { API_URL } from "../../constants";


function ProjectColumnSelect({ value, onChange, isRequired, projectBoardId }) {
  const { t } = useTranslation();
  const [collection, setCollection] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { companyId } = useParams();
  const [error, setError] = useState("");

  const handleChange = (selectedValue) => {
    if (isRequired && !selectedValue) {
      setError("field_is_required");
    } else {
      setError("");
    }

    onChange(selectedValue);
  };

  useEffect(() => {
    async function fetchData() {
      try {
        setIsLoading(true);
        const collection = await fetch(companyId, projectBoardId);
        const dataCollection = _.map(collection, (row) => ({
          label: row.name,
          value: row.id,
        }));
        dataCollection.unshift({ label: " - ", value: "" });
        setCollection(dataCollection);
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
      }
    }
    fetchData();
  }, [companyId, projectBoardId]);


  return (
    <div className="project_column_select">
      <Select
          label={t("project_column")}
          options={collection}
          onChange={handleChange}
          value={value}
        />
    </div>
  );
}

export default ProjectColumnSelect;

ProjectColumnSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  // eslint-disable-next-line react/require-default-props
  value: PropTypes.string,
  projectBoardId: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
};

ProjectColumnSelect.defaultProps = {
  isRequired: false,
};

function fetch(companyId, projectBoardId) {
  const url = `${API_URL}/${companyId}/project-board-columns?projectBoardId=${projectBoardId}`;
  return axios.get(url).then((response) => response.data);
}
