import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  FormLayout,
  TextField,
  Form,
  Button,
  Banner,
  Select,
  Checkbox,
  Card,
  BlockStack,
  InlineStack,
  InlineGrid,
  Text,
} from "@shopify/polaris";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { fetchFilesTemplates } from 'containers/files-templates/files-templates-container.slice';
import FileCategorySelect from "components/file-category-select/file-category-select";
import { createFileTemplate, updateFileTemplate, fetchFileTemplateById } from "./files-template-form.service";

function FilesTemplateForm({ onSubmit, id }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { companyId } = useParams();
  const [name, setName] = useState('');
  const [serial, setSerial] = useState('');
  const [category, setCategory] = useState();
  const [customFields, setCustomFields] = useState([]);
  const [html, setHtml] = useState('');
  const [successMessage, setSuccessMessage] = useState();
  const [errMsg, setErrMsg] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const handleFormSubmit = async () => {
    const formData = {
      id,
      name,
      category,
      customFields,
      serial,
      html
    };
    const action = id ? updateFileTemplate : createFileTemplate;
    try {
      setIsLoading(true);
      const result = await action(formData, companyId);
      dispatch(fetchFilesTemplates(companyId));
      setSuccessMessage('ok');
      if (onSubmit) {
        onSubmit(result);
      }
      setIsLoading(false);
    } catch (e) {
      console.error(e);
      setErrMsg('error');
      setIsLoading(false);
    }
  };

  const fetchData = async (id) => {
    const data = await fetchFileTemplateById(companyId, id);
    
    setName(data.name);
    setCategory(data.category);
    setSerial(data.serial);
    if (data.customFields) {
      setCustomFields(data.customFields);
    } else {
      setCustomFields([]);
    }
    setHtml(data.html);
  };

  useEffect(() => {
    if (id) {
      fetchData(id);
    }
  }, [id]);

  const addCustomField = () => {
    setCustomFields([
      ...customFields,
      { id: Date.now(), type: "string", label: "", name: "", options: [], required: false }
    ]);
  };

  const updateCustomField = (index, key, value) => {
    const updatedFields = customFields.map((field, i) =>
      i === index ? { ...field, [key]: value } : field
    );
    setCustomFields(updatedFields);
  };

  const updateFieldOptions = (index, optionIndex, value) => {
    const updatedFields = customFields.map((field, i) => {
      if (i === index) {
        const updatedOptions = field.options.map((option, j) =>
          j === optionIndex ? value : option
        );
        return { ...field, options: updatedOptions };
      }
      return field;
    });
    setCustomFields(updatedFields);
  };

  const addOptionToField = (index) => {
    const updatedFields = customFields.map((field, i) => {
      if (i === index) {
        return { ...field, options: [...field.options, ""] };
      }
      return field;
    });
    setCustomFields(updatedFields);
  };

  const removeOptionFromField = (index, optionIndex) => {
    const updatedFields = customFields.map((field, i) => {
      if (i === index) {
        return { ...field, options: field.options.filter((_, j) => j !== optionIndex) };
      }
      return field;
    });
    setCustomFields(updatedFields);
  };

  const removeCustomField = (index) => {
    setCustomFields(customFields.filter((_, i) => i !== index));
  };

  return (
    <Form className="customer-form_form">
      <FormLayout>
        <div className="form-warnings">
          {errMsg && (
            <div className="">
              <Banner tone="critical">{t(errMsg)}</Banner>
            </div>
          )}
          {successMessage && (
            <div className="">
              <Banner tone="info">{t(successMessage)}</Banner>
            </div>
          )}
        </div>
        <TextField
          label={t("files_template_name")}
          id="name"
          name="name"
          value={name}
          onChange={setName}
        />
        <TextField
          label={t("files_template_serial")}
          id="serial"
          name="serial"
          value={serial}
          onChange={setSerial}
        />
        <FileCategorySelect
          onChange={setCategory}
          value={category}
          isRequired={true}
        />
        <TextField
          label={t("files_template_html")}
          id="html"
          name="html"
          value={html}
          multiline={10}
          maxHeight={400}
          onChange={setHtml}
        />

        {customFields && customFields.length > 0 && customFields.map((field, index) => (
          <Card key={field.id} sectioned>
            <InlineGrid columns="1fr auto">
              <Text as="h2" variant="headingSm">
                #{field.id}
              </Text>
              <Button destructive onClick={() => removeCustomField(index)}>
                {t("files_template_custom_remove")}
              </Button>
            </InlineGrid>
            <InlineGrid gap="4">
              <Select
                label={t("files_template_custom_type")}
                options={[
                  { label: t("files_template_custom_type_string"), value: "string" },
                  { label: t("files_template_custom_type_textarea"), value: "textarea" },
                  { label: t("files_template_custom_type_date"), value: "date" },
                  { label: t("files_template_custom_type_select"), value: "select" },
                  { label: t("files_template_custom_type_products"), value: "products" },
                ]}
                value={field.type}
                onChange={(value) => updateCustomField(index, "type", value)}
              />
              <Checkbox
                label={t("files_template_custom_required")}
                checked={field.required}
                onChange={(checked) => updateCustomField(index, "required", checked)}
              />
            </InlineGrid>
            <BlockStack gap="4">
              {field.type !== "products" && ( <BlockStack gap="4">
                  <TextField
                    label={t("files_template_custom_name")}
                    value={field.name}
                    onChange={(value) => updateCustomField(index, "name", value)}
                  />
                  <TextField
                    label={t("files_template_custom_label")}
                    value={field.label}
                    onChange={(value) => updateCustomField(index, "label", value)}
                  />
                </BlockStack>
              )}
              {field.type === "select" && (
                <BlockStack gap="4">
                  {field.options.map((option, optionIndex) => (
                    <InlineStack key={optionIndex} gap="4" blockAlign="end">
                      <TextField
                        label={`${t("files_template_custom_type_select_option")} ${optionIndex + 1}`}
                        value={option}
                        onChange={(value) => updateFieldOptions(index, optionIndex, value)}
                      />
                      <div>
                        <Button destructive onClick={() => removeOptionFromField(index, optionIndex)}>
                          {t("files_template_custom_remove_option")}
                        </Button>
                      </div>
                    </InlineStack>
                  ))}
                  <Button onClick={() => addOptionToField(index)}>
                    {t("files_template_custom_add_option")}
                  </Button>
                </BlockStack>
              )}
            </BlockStack>
          </Card>
        ))}

        <Button onClick={addCustomField}>{t("files_template_custom_add")}</Button>

        <Button
          primary
          onClick={handleFormSubmit}
          disabled={!name || !category || !html || !serial}
          loading={isLoading}
        >
          {t("save")}
        </Button>
      </FormLayout>
    </Form>
  );
}

export default FilesTemplateForm;
