/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/prop-types */
import { useEffect } from "react";
import {
  Page,
  Card,
} from "@shopify/polaris";
import { useSelector, useDispatch } from "react-redux";
import { fetchFilesTemplates, setDestroy } from "./files-templates-container.slice";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import DataTable from "components/datatable/Datatable"
import { setModalxComponent, setModalxComponentProps } from "features/modalx/modalx.slice";

function FilesTemplatesContainer() {
  const { collection } = useSelector(
    (state) => state.filesTemplatesReducer
  );
  
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { companyId } = useParams();

  const fetchData = (companyId) => {
    dispatch(fetchFilesTemplates(companyId));
  }

  const handleCreate = () => {
    dispatch(setModalxComponent("FilesTemplateForm"));
    // dispatch(setModalxComponentProps({ onSubmit: onFormSubmit }))
  };

  const onRowClick = ({ id }) => {
    dispatch(setModalxComponent("FilesTemplateForm"));
    dispatch(setModalxComponentProps({ id }))
  }

  useEffect(() => {
    fetchData(companyId)

    return () => {
      setDestroy(true);
    };
  }, [companyId]);

  if (!companyId || !collection) {
    return null
  }

  return (
    <Page
      fullWidth
      primaryAction={{
        content: t("files_templates_create"),
        onAction: handleCreate,
      }}
      title={t("files_templates")}
    >
      <Card>
        <DataTable
          collection={collection}
          columns={[
            { key: '#' },
            { key: 'name', name: t('files_templates_title'), onClick: onRowClick },
            { key: 'category', name: t('files_templates_category') }
          ]}
        />
      </Card>
    </Page>
  )
}

export default FilesTemplatesContainer
