import { createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import { API_URL } from "../../constants"

const initialState = {
  isLoading: true,
  isError: false,
  errMsg: "",
  collection: []
}

const filesCategoriesContainerSlice = createSlice({
  name: 'filesCategories',
  initialState,
  /* eslint-disable no-param-reassign */
  reducers: {
    setDestroy() {
      return initialState
    },
    setLoading(state, action) {
      state.loading = action.payload
    },
    setError(state, action) {
      state.isLoading = false
      state.isError = true
      state.errMsg = action.payload.error
    },
    setData(state, action) {
      state.collection = action.payload
      state.isLoading = false
    },
  },
})

const { actions, reducer } = filesCategoriesContainerSlice

export const { setDestroy, setLoading, setError, setData } = actions
export default reducer

export const fetchFilesCategories = (companyId) => async (dispatch) => {
  dispatch(setLoading())
  try {
    const data = await fetchData(companyId)
    dispatch(setData(data))
  } catch (e) {
    console.error(e)
    dispatch(setError("error"))
  }
}

const fetchData = (companyId) => {
  const url = `${API_URL}/${companyId}/files/fetch-file-categories`
  return axios.get(url).then((response) => response.data)
}
