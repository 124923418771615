/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/prop-types */
import {
  Text,
  Card,
  Box,
  BlockStack,
  InlineGrid,
  Button,
} from "@shopify/polaris";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { setModalxComponent, setModalxComponentProps } from "components/modalx/modalx.slice";
import Currency from 'components/format/currency/currency'

function CustomerDetails({ customer, onSubmit }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  
  const contactPersonNode = customer.contactPerson ? <Text as="p" variant="bodyMd">{customer.contactPerson}</Text> : <Text as="p" tone="subdued">{t("no_contact_person_provided")}</Text>;
  const emailNode = customer.email ? <Text as="p" variant="bodyMd"><a href={`mailto:${customer.email}`}>{customer.email}</a></Text> : <Text as="p" tone="subdued">{t("no_email_address_provided")}</Text>;
  const phoneNode = customer.phone ? <Text as="p" variant="bodyMd"><a href={`tel:${customer.phone}`}>{customer.phone}</a></Text> : <Text as="p" tone="subdued">{t("no_phone_number_provided")}</Text>;
  const addressNode = customer.address ? <Text as="p" variant="bodyMd">{customer.address}</Text> : <Text as="p" tone="subdued">{t("no_address_provided")}</Text>;
  const companyNameNode = customer.name ? <Text as="p" variant="bodyMd">{customer.name}</Text> : <Text as="p" tone="subdued">{t("no_name_provided")}</Text>;
  const companyCodeNode = customer.companyCode ? <Text as="p" variant="bodyMd">{customer.companyCode}</Text> : <Text as="p" tone="subdued">{t("no_registration_code_provided")}</Text>;
  const vatCodeNode = customer.vatCode ? <Text as="p" variant="bodyMd">{customer.vatCode}</Text> : <Text as="p" tone="subdued">{t("no_vat_code_provided")}</Text>;
  const maxAllowedDebtNode = customer.maxAllowedDebt ? <Text as="p" variant="bodyMd">{t("max_allowed_debt")}: <Currency isSymbol={true} value={customer.maxAllowedDebt} /></Text> : <Text as="p" tone="subdued">{t("no_max_allowed_debt_provided")}</Text>;
  const debtNode = <Text as="p" variant="bodyMd">{t("debt")}: <Currency isSymbol={true} value={customer._debt} /></Text>;
  const overdueDebtNode = <Text as="p" variant="bodyMd">{t("overdue_debt")}: <Currency isSymbol={true} value={customer._overdueDebt} /></Text>;
  const assignedUserNode = customer.assignedUser ? <Text as="p" variant="bodyMd">{t("assigned_user")}: {customer.assignedUser.name}</Text> : <Text as="p" tone="subdued">{t("no_assigned_person")}</Text>;
  const turnoverNode = <Text as="p" variant="bodyMd">{t("turnover")}: <Currency isSymbol={true} value={customer._turnover} /></Text>;
  
  const bankIbanNode = customer.bankIban ? <Text as="p" variant="bodyMd">{customer.bankIban}</Text> : <Text as="p" tone="subdued">{t("no_bank_iban_provided")}</Text>;
  const bankNameNode = customer.bankName ? <Text as="p" variant="bodyMd">{customer.bankName}</Text> : <Text as="p" tone="subdued">{t("no_bank_name_provided")}</Text>;
  const paymentDelayNode = customer.paymentDelay ? <Text as="p" variant="bodyMd">{t("payment_delay")}: {customer.paymentDelay}</Text> : <Text as="p" tone="subdued">{t("no_payment_delay_provided")}</Text>;
  const prepaymentPercentageNode = customer.prepaymentPercentage ? <Text as="p" variant="bodyMd">{t("prepayment_percentage")}: {customer.prepaymentPercentage}%</Text> : <Text as="p" tone="subdued">{t("no_prepayment_percentage_provided")}</Text>;
  const fineNode = customer.fine ? <Text as="p" variant="bodyMd">{t("fine")}: {customer.fine}%</Text> : <Text as="p" tone="subdued">{t("no_fine_provided")}</Text>;

  const handleCompanyDetailsEdit = () => {
    dispatch(setModalxComponent("CustomerForm"));
    dispatch(setModalxComponentProps({ customer, onSubmit }));
  };

  return (
    <Card roundedAbove="sm">
      <InlineGrid columns="1fr auto">
        <Text as="h2" variant="headingSm">
          {t("customer_details")}
        </Text>
        <Button
          variant="plain"
          onClick={handleCompanyDetailsEdit}
          accessibilityLabel="Preview"
        >
          {t("edit")}
        </Button>
      </InlineGrid>
      {companyNameNode}
      {companyCodeNode}
      {vatCodeNode}
      <BlockStack gap="200">
        <Box paddingBlockStart="200">
          <Text as="h3" variant="headingSm">
            {t("contacts")}
          </Text>
          {contactPersonNode}
          {emailNode}
          {phoneNode}
        </Box>
        <Box paddingBlock="200">
          <Text as="h3" variant="headingSm">
            {t("address")}
          </Text>
          {addressNode}
        </Box>
        <Box paddingBlock="200">
          <Text as="h3" variant="headingSm">
            {t("bank_details")}
          </Text>
          {bankIbanNode}
          {bankNameNode}
        </Box>
        <Box paddingBlock="200">
          <Text as="h3" variant="headingSm">
            {t("financials")}
          </Text>
          {maxAllowedDebtNode}
          {overdueDebtNode}
          {debtNode}
          {turnoverNode}
          {paymentDelayNode}
          {prepaymentPercentageNode}
          {fineNode}
          {assignedUserNode}
        </Box>
      </BlockStack>
    </Card>
  );
}

export default CustomerDetails;
