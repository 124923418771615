import {
  Link,
  DataTable as ShopifyDataTable,
  Button
} from '@shopify/polaris';
import Currency from "components/format/currency/currency";
import { useTranslation } from "react-i18next";
import PrettyDate from "components/format/PrettyDate";
import _ from 'lodash'

type Column = {
    key: string,
    name?: string,
    suffix?: string,
    type?: 'currency' | 'number' | 'string' | 'date' | 'downloadurl' | 'actionBtn' | 'clickableColumn' | 'clickabledate'
    isDisabledTotal?: boolean
    totalType?: 'sum' | 'average'
    url?: string
    onClick?: Function
    translationRequired?: boolean
}
type Props = {
    collection: any[],
    columns: Column[],
    isTotalsDisabled?: boolean,
}

const DataTable = ({
    collection,
    columns,
    isTotalsDisabled,
}: Props) => {
  const { t } = useTranslation();

  const totals: any[] = columns.map((column) => {
      if (column.type === 'currency' || column.type === 'number') {
          return 0
      }
      return ''
  })
  const tableData = collection.map((item, index) => {
      return columns.map((column, columnIndex) => {
          let value = _.get(item, column.key, '-');
          value = column.translationRequired ? t(value) : value;
          const suffixValue = column.suffix ? _.get(item, column.suffix) : undefined
          if (column.isDisabledTotal !== true && (column.type === 'currency' || column.type === 'number')) {
              if (!column.totalType || column.totalType === 'sum') {
                  totals[columnIndex] += value
              }
          }
          if (column.key === '#') {
            return index+1
          }
          switch (column.type) {
              case 'currency':
                return <Currency value={value} isSymbol={true} />
              case 'date':
                return <PrettyDate dateString={value} />
              case 'downloadurl':
              case 'actionBtn':
              case 'clickableColumn':
              case 'clickabledate':
                return (
                  <div style={{ cursor: 'pointer', height: '35px', display:'flex', alignItems:'center' }} onClick={() => column.onClick?.(item)}>
                    {column.type === 'clickabledate' ? (
                      value ? <PrettyDate dateString={value} showTime={true} /> : <span>-</span>
                    ) : (
                      column.type === 'downloadurl' || column.type === 'actionBtn' ? (
                        <Button target={column.type === 'downloadurl' ? '_blank' : undefined}>
                          {column.type === 'downloadurl' ? t('download') : t('edit')}
                        </Button>
                      ) : (
                        <>{value || '-'}</>
                      )
                    )}
                  </div>
                );
              default:
                if (column.url) {
                  return <Link removeUnderline url={_.get(item, column.url)} key={column.url}>{value}</Link>
                } else {
                  if (column.onClick) {
                    return <div style={{cursor: 'pointer'}} onClick={() => { if (column.onClick) column.onClick(item)}}><strong>{value}</strong></div>
                  }
                  return `${value}${suffixValue? ` ${suffixValue}` : ''}`
                }
          }
      })
  })
  const columnContentTypes: ('text' | 'numeric')[] = columns.map((column) => {
      if (column.type === 'currency' || column.type === 'number') {
          return 'numeric'
      } else {
          return 'text'
      }
  })

  const headings = columns.map((column) => column.name || '')
  const totalsFormatted: any[] | undefined = !isTotalsDisabled ? columns.map((column, index) => {
    if (column.isDisabledTotal === true) return ''
      if (column.type === 'currency') {
          return <Currency value={totals[index]} isSymbol={true} />
      }
      if (column.type === 'number') {
          return <Currency value={totals[index]} />
      }
      if (column.key === '#') {
        return collection.length
      }
      return ''
  }) : undefined
  
  return (
    <ShopifyDataTable
      columnContentTypes={columnContentTypes}
      headings={headings}
      rows={tableData}
      totalsName={{
        singular: '',
        plural: '',
      }}
      totals={totalsFormatted}
    />
  )
}

export default DataTable