import axios from "axios";
import { API_URL } from "constants";

export const createComment = async (formData, companyId, projectId) => {
  const url = `${API_URL}/${companyId}/projects/${projectId}/comments`;
  return axios.post(url, formData).then((response) => response.data);
}

export const updateComment = async (formData, companyId, projectId, commentId) => {
  const url = `${API_URL}/${companyId}/projects/${projectId}/comments/${commentId}`;
  return axios.patch(url, formData).then((response) => response.data);
}