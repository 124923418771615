import { useEffect, useState } from "react";
import _ from "lodash";
import { Select, Text } from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { fetchCustomFieldsByQuery } from 'services/custom-field.service';

interface CustomFieldelectProps {
  value: string;
  onChange: (selectedValue: string, name: string) => void;
  isRequired?: boolean;
  type: string;
  id: string;
}

interface CustomFieldObject {
  id: string;
  label: string;
  code: string;
  type: string;
}

const CustomFieldSelect = ({
  value,
  onChange,
  isRequired = false,
  type,
  id
}: CustomFieldelectProps) => {
  const { t } = useTranslation();
  const [collection, setCollection] = useState<{ label: string; value: string }[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { companyId } = useParams<{ companyId: string }>();
  const [error, setError] = useState<string>("");

  const handleChange = (selectedValue: string) => {
    if (isRequired && !selectedValue) {
      setError(t("field_is_required"));
    } else {
      setError("");
    }
    onChange(selectedValue, id);
  };

  useEffect(() => {
    async function fetchData() {
      try {
        setIsLoading(true);
        const data: CustomFieldObject[] = await fetchCustomFieldsByQuery(companyId, type);
        const dataCollection = data.map((row:CustomFieldObject) => ({
          label: row.code,
          value: row.code,
        }));
        dataCollection.unshift({ label: " - ", value: "" });
        setCollection(dataCollection);
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
      }
    }
    if (companyId) {
      fetchData();
    }
  }, [companyId, type]);

  return (
    <div className="custom_field-select">
      <Select
        label={t("select_field")}
        options={collection}
        value={value}
        disabled={isLoading}
        onChange={handleChange}
        error={error}
      />
    </div>
  );
}

export default CustomFieldSelect;
