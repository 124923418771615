import { useEffect, useState, useCallback } from "react";
import { useAppSelector, useAppDispatch } from "../../hooks.ts";
import {
  FormLayout,
  TextField,
  Form,
  Button,
  Checkbox,
  Spinner,
} from "@shopify/polaris";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import DatePickerx from "../datepicker/date-picker";
import UploadFile from "../upload-file/upload-file";
import UserSelect from "../user-select/user-select";
import Files from "../files/files";
import { fetchById, resolveById } from "../task/task.service";
import { fetchTasks } from "../tasks/tasks.slice";
import { submit } from "./task-create.slice.ts";

function TaskCreate() {
  const dispatch = useAppDispatch();
  const [title, setTitle] = useState();
  const [assignedTo, setAssignedTo] = useState();
  const [description, setDescription] = useState();
  const [files, setFiles] = useState();
  const [viewFiles, setViewFiles] = useState();
  const [isResolved, setIsResolved] = useState();
  const [isResolveLoading, setIsResolvedLoading] = useState();
  // eslint-disable-next-line max-len
  const [dueDate, setDueDate] = useState(new Date());
  const { t } = useTranslation();
  const { taskId } = useParams();
  const { isLoading } = useAppSelector((state) => state.taskCreateReducer);

  // const handleFormChange = (formData) => dispatch(setFormData(formData));
  const handleFormSubmit = async () => {
    const formData = {
      taskId,
      title,
      assignedTo,
      description,
      files,
      dueDate,
    };
    await dispatch(submit(formData));
  };
  const handleTitleChange = useCallback((value) => setTitle(value), []);
  const handleAssignedToChange = useCallback(
    (value) => setAssignedTo(value),
    []
  );
  const handleDueDateChange = useCallback((value) => setDueDate(value), []);
  const handleDescriptionChange = useCallback(
    (value) => setDescription(value),
    []
  );
  const handleFilesChange = useCallback((value) => setFiles(value), []);
  const isFormValid = () => title && description && dueDate && assignedTo;

  const handleIsResolved = useCallback(async () => {
    setIsResolved(!isResolved);
    setIsResolvedLoading(true);
    await resolveById(taskId);
    setIsResolvedLoading(false);
    dispatch(fetchTasks());
  }, [dispatch, isResolved, taskId]);

  useEffect(() => {
    async function fetchData() {
      try {
        const { taskEntity } = await fetchById(taskId);
        setTitle(taskEntity.title);
        setAssignedTo(taskEntity.assignedTo.id);
        setDescription(taskEntity.description);
        setDueDate(new Date(taskEntity.dueDate));
        setIsResolved(taskEntity.isResolved);
        setViewFiles(taskEntity.files);
      } catch (e) {
        // console.error(e);
      }
    }
    if (taskId) {
      fetchData();
    }
  }, [taskId]);

  const filesNode = viewFiles ? <Files files={viewFiles} /> : "";

  const submitNode = taskId ? (
    <Button loading={isLoading} disabled={!isFormValid()} submit>
      {t("update_task")}
    </Button>
  ) : (
    <Button loading={isLoading} disabled={!isFormValid()} submit>
      {t("create_task")}
    </Button>
  );

  const resolveNode = isResolveLoading ? (
    <div>
      <Spinner accessibilityLabel="Resolve loading" size="small" />
      <span>{t("resolved")}</span>
    </div>
  ) : (
    <Checkbox
      label={t("resolved")}
      checked={isResolved}
      onChange={handleIsResolved}
    />
  );

  return (
    <div className="task-create">
      <Form className="task-create_form" onSubmit={handleFormSubmit}>
        <FormLayout>
          <TextField
            label={t("title")}
            type="text"
            id="title"
            autoComplete="off"
            value={title}
            onChange={handleTitleChange}
          />
          <UserSelect
            label={t("assigned")}
            name="assignedTo"
            onChange={handleAssignedToChange}
            value={assignedTo}
          />
          <div style={{ display: "flex" }}>
            <div>
              <div>{t("due_date")}</div>
              <DatePickerx
                name="dueDate"
                onChange={handleDueDateChange}
                selected={dueDate}
              />
            </div>
            <div style={{ flex: "1" }} />
            <div>{resolveNode}</div>
          </div>
          <div>
            <TextField
              label={t("description")}
              type="text"
              id="description"
              autoComplete="off"
              multiline={10}
              value={description}
              onChange={handleDescriptionChange}
            />
          </div>
          <div>
            {filesNode}
            <UploadFile onChange={handleFilesChange} />
          </div>
          {submitNode}
        </FormLayout>
      </Form>
    </div>
  );
}

export default TaskCreate;
