import { useState, useEffect } from "react";
import { API_URL } from "../../../constants";
import axios from "axios";
import { useParams } from "react-router-dom";
import {
  FormLayout,
  Banner,
  Form,
  Button,
  TextField,
  Select,
} from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import { fetchCustomer } from "containers/customer/customer-container.slice";
import FileTemplateSelect from "components/file-template-select/file-template-select";
import { setIsModalxOpen } from "components/modalx/modalx.slice";
import { PostData, CustomField, Product } from "./types";
import { useAppDispatch } from "hooks";
import { fetchFileTemplateById } from 'containers/files-template-form/files-template-form.service'
import ProductsSelect from 'components/products-select/ProductsSelect'

function BuildPdfForm({ customerId }: { customerId: string }) {
  const [templateId, setTemplateId] = useState('')
  const [customFields, setCustomFields] = useState<CustomField[]>([])
  const [products, setProducts] = useState<Product[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [isSuccessful, setIsSuccessful] = useState(false)
  const [isFailed, setIsFailed] = useState(false)
  const { companyId } = useParams()
  const dispatch = useAppDispatch()

  const { t } = useTranslation();
  const isFormValid = () => templateId && customFields.every(field => !field.required || (field.value && field.value.length > 0));

  useEffect(() => {
    if (templateId) {
      fetchCustomFields(templateId);
    }
  }, [templateId]);

  const fetchCustomFields = async (templateId: string) => {
    try {
      const fileTemplateEntity = await fetchFileTemplateById(companyId, templateId)
      setCustomFields(fileTemplateEntity.customFields.map((field: CustomField) => ({ ...field, value: '' })));
    } catch (e) {
      console.error(e);
    }
  }

  const handleCustomFieldChange = (index: number, value: string) => {
    const updatedFields = customFields.map((field, i) => (i === index ? { ...field, value } : field));
    setCustomFields(updatedFields);
  }

  const handleFormSubmit = async () => {
    const formData: PostData = {
      templateId,
      customerId,
      customFields: {},
      products,
    };
    customFields.forEach(field => {
      formData.customFields[field.label] = field.value;
    });

    if (companyId) {
      try {
        setIsLoading(true);
        const fileEntity = await submit(companyId, formData);
        if (fileEntity.id)  {
          dispatch(fetchCustomer({ companyId, customerId }));
          setIsSuccessful(true);
          setTimeout(() => {
            dispatch(setIsModalxOpen(false));
          }, 1500);
        } else {
          setIsFailed(true);
          setIsLoading(false);
        }
      } catch (e) {
        setIsFailed(true);
        setIsLoading(false);
      }
    }
  }

  return (
    <div className="user-form">
      <Form onSubmit={handleFormSubmit}>
        <div className="form-warnings">
          {isFailed && (
            <div className="">
              <Banner tone="critical">{t('error')}</Banner>
            </div>
          )}
          {isSuccessful && (
            <div className="">
              <Banner tone="info">{t('ok')}</Banner>
            </div>
          )}
        </div>
        <FormLayout>
          <FileTemplateSelect
            onChange={setTemplateId}
            value={templateId}
            isRequired={true}
          />
          {customFields.map((field, index) => (
            <div key={field.id}>
              {field.type === 'string' && (
                <TextField
                  label={field.name}
                  value={field.value}
                  autoComplete=""
                  onChange={(value: string) => handleCustomFieldChange(index, value)}
                  requiredIndicator={field.required}
                />
              )}
              {field.type === 'textarea' && (
                <TextField
                  label={field.name}
                  value={field.value}
                  multiline={4}
                  autoComplete=""
                  onChange={(value: string) => handleCustomFieldChange(index, value)}
                  requiredIndicator={field.required}
                />
              )}
              {field.type === 'date' && (
                <TextField
                  label={field.name}
                  value={field.value}
                  type="date"
                  autoComplete=""
                  onChange={(value: string) => handleCustomFieldChange(index, value)}
                  requiredIndicator={field.required}
                />
              )}
              {field.type === 'select' && (
                <Select
                  label={field.name}
                  options={[{ label: t('files_template_custom_type_select_select_an_option'), value: '' }, ...field.options.map(option => ({ label: option, value: option }))]}
                  value={field.value}
                  onChange={(value: string) => handleCustomFieldChange(index, value)}
                  requiredIndicator={field.required}
                />
              )}
              {field.type === 'products' && (
                <ProductsSelect
                  onChange={(value: any) => {
                    if (JSON.stringify(products) !== JSON.stringify(value)) {
                      handleCustomFieldChange(index, value);
                      setProducts(value);
                    }
                  }}
                />
              )}
            </div>
          ))}
          <Button loading={isLoading} disabled={!isFormValid()} submit>
            {t("save")}
          </Button>
        </FormLayout>
      </Form>
    </div>
  );
}

export default BuildPdfForm;

function submit(companyId: string, data: PostData) {
  const url = `${API_URL}/${companyId}/files/build-pdf`;
  return axios.post(url, data).then((response) => response.data);
}
