import { createSlice } from "@reduxjs/toolkit";
import { fetchById } from "../customers/customers.service";

const initialState = {
  isLoading: true,
  isError: false,
  errMsg: "",
  companyName: "",
  companyCode: "",
};

const customerViewSlice = createSlice({
  name: "customerView",
  initialState,
  /* eslint-disable no-param-reassign */
  reducers: {
    setDestroy() {
      return initialState;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setError(state, action) {
      state.isLoading = false;
      state.isError = true;
      state.errMsg = action.payload.error;
    },
    setCustomer(state, action) {
      state.companyName = action.payload.name;
      state.companyCode = action.payload.companyCode;
      state.isLoading = false;
    },
  },
});

// Extract the action creators object and the reducer
const { actions, reducer } = customerViewSlice;

// Extract and export each action creator by name
export const { set, setDestroy, setLoading, setError, setCustomer } = actions;
// Export the reducer, either as a default or named export
export default reducer;

export const fethCustomer = (customerId) => async (dispatch) => {
  dispatch(setLoading());
  try {
    const { customerEntity } = await fetchById(customerId);
    dispatch(setCustomer(customerEntity));
  } catch (e) {
    dispatch(setError("not_saved"));
    return false;
  }
};
