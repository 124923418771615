import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { fetch } from "./company-select.service";

const CompanySelectSlice = createSlice({
  name: "companySelect",
  initialState: {
    companiesCollection: [],
    companiesCollectionRaw: [],
    isLoading: false,
    isError: false,
    errMsg: null,
    isUserMaster: false,
  },
  /* eslint-disable no-param-reassign */
  reducers: {
    setLoading(state) {
      state.isLoading = true;
      state.isError = false;
    },
    setCompanies(state, action) {
      state.isLoading = false;
      state.companiesCollection = action.payload.companiesCollection;
      state.companiesCollectionRaw = action.payload.companiesCollectionRaw;
      state.isUserMaster = action.payload.isUserMaster;
    },
    setError(state, action) {
      state.isError = true;
      state.errMsg = action.payload.errMsg;
    },
  },
  /* eslint-enable */
});

// Extract the action creators object and the reducer
const { actions, reducer } = CompanySelectSlice;

// Extract and export each action creator by name
export const { setLoading, setCompanies, setError } = actions;

// Export the reducer, either as a default or named export
export default reducer;

export const fetchCompanies = () => async (dispatch) => {
  dispatch(setLoading());
  try {
    const { companiesCollection, isUserMaster } =
      await fetch();
      dispatch(
        setCompanies({
          isUserMaster,
          companiesCollectionRaw: companiesCollection,
          companiesCollection: _.map(companiesCollection, (company) => ({
            label: company.name,
            value: company.id,
          })),
        })
      );
  } catch (e) {
    dispatch(setError(""));
  }
};
