import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  FormLayout,
  TextField,
  Form,
  Button,
  Banner,
  Select,
  Checkbox,
  Card,
} from "@shopify/polaris";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { submit, setError, destroy } from "./order-form.slice";
import { fetchById } from "../orders/orders.service";
import OrderItems from "../order-items/order-items";
import UploadFile from "../upload-file/upload-file";
import Files from "../files/files";
import CustomerSelect from "../customer-select/customer-select";
import {
  setModalxComponent,
  setModalxComponentProps,
} from "../modalx/modalx.slice";
import _ from "lodash";
import DatePickerInput from "../date-picker-input/date-picker-input";
import { format, parseISO } from "date-fns";
import "./style.scss";

function OrderForm() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { orderId } = useParams();
  const { isLoading, errMsg, successMessage } = useSelector(
    (state) => state.orderFormReducer
  );
  const [number, setNumber] = useState("");
  const [numberError, setNumberError] = useState("");
  const [title, setTitle] = useState("");
  const [titleError, setTitleError] = useState("");
  const [date, setDate] = useState("");
  const [expires, setExpires] = useState("");
  const [description, setDescription] = useState("");
  const [customer, setCustomer] = useState("");
  const [files, setFiles] = useState();
  const [viewFiles, setViewFiles] = useState();
  const [orderItems, setOrderItems] = useState([]);
  const [invoicePeriod, setInvoicePeriod] = useState({
    label: "1",
    value: "1",
  });
  const [invoiceDay, setInvoiceDay] = useState({ label: "1", value: "1" });
  const [nextInvoiceDate, setNextInvoiceDate] = useState();
  const [isPeriod, setIsPeriod] = useState(false);
  const monthOptions = _.map(
    Array.from({ length: 12 }, (_, i) => i + 1),
    (item) => ({ label: `${item}`, value: `${item}` })
  );
  const invoiceDayOptions = _.map(
    Array.from({ length: 31 }, (_, i) => i + 1),
    (item) => ({ label: `${item}`, value: `${item}` })
  );

  const handleFormSubmit = () => {
    const formData = {
      orderId,
      title,
      description,
      date,
      expires,
      number,
      customer,
      files,
      orderItems,
      invoicePeriod: parseInt(invoicePeriod),
      invoiceDay: parseInt(invoiceDay),
      nextInvoiceDate,
    };

    dispatch(submit(formData));
  };

  const handleNumberChange = (value) => {
    setNumber(value);

    if (!value) {
      setNumberError("field_is_required");
    } else {
      setNumberError("");
    }
  };

  const handleTitleChange = (value) => {
    setTitle(value);

    if (!value) {
      setTitleError("field_is_required");
    } else {
      setTitleError("");
    }
  };

  const handleFilesChange = useCallback((value) => setFiles(value), []);

  useEffect(() => {
    async function fetchData() {
      try {
        const { orderEntity } = await fetchById(orderId);
        setTitle(orderEntity.title);
        setNumber(orderEntity.number);
        setDescription(orderEntity.description);
        setDate(
          orderEntity.date
            ? format(parseISO(orderEntity.date), "yyyy-MM-dd")
            : ""
        );
        setExpires(
          orderEntity.expires
            ? format(parseISO(orderEntity.expires), "yyyy-MM-dd")
            : ""
        );
        setCustomer(orderEntity.customer);
        setViewFiles(orderEntity.files);
        setOrderItems(orderEntity.items);
        setInvoicePeriod(`${orderEntity.invoicePeriod}`);
        setInvoiceDay(`${orderEntity.invoiceDay}`);
        setNextInvoiceDate(
          orderEntity.nextInvoiceDate
            ? format(parseISO(orderEntity.nextInvoiceDate), "yyyy-MM-dd")
            : ""
        );

        if (
          orderEntity.invoicePeriod ||
          orderEntity.invoiceDay ||
          orderEntity.nextInvoiceDate
        ) {
          setIsPeriod(true);
        }
      } catch (e) {
        console.log(e);
        dispatch(setError("error_getting_data"));
      }
    }

    if (orderId) {
      fetchData();
    }

    return () => {
      dispatch(destroy());
    };
  }, [orderId, dispatch]);

  const handleOrderItemsChange = (items) => {
    setOrderItems(items);
  };

  const handleCreateInvoice = () => {
    const formData = {
      orderId,
      title,
      description,
      date,
      expires,
      number,
      customer,
      files,
      orderItems,
    };

    navigate("/app/orders");
    dispatch(setModalxComponent("InvoiceForm"));
    dispatch(setModalxComponentProps(formData));
  };

  const handleIsPeriod = (val) => {
    setIsPeriod(val);

    if (!val) {
      setInvoicePeriod("1");
      setInvoiceDay("1");
      setNextInvoiceDate("");
    }
  };

  const filesNode = viewFiles ? <Files files={viewFiles} /> : "";

  return (
    <div className="order-form">
      <Form className="order-form_form" onSubmit={handleFormSubmit}>
        <FormLayout>
          <div className="form-warnings">
            {errMsg && (
              <div className="">
                <Banner status="critical">{t(errMsg)}</Banner>
              </div>
            )}
            {successMessage && (
              <div className="">
                <Banner status="info">{t(successMessage)}</Banner>
              </div>
            )}
          </div>
          <TextField
            label={t("number")}
            id="number"
            name="number"
            value={number}
            error={t(numberError)}
            onChange={handleNumberChange}
          />
          <CustomerSelect
            label={t("customer")}
            value={customer}
            onChange={setCustomer}
            isRequired
          />
          <TextField
            label={t("title")}
            id="title"
            name="title"
            value={title}
            error={t(titleError)}
            onChange={handleTitleChange}
          />
          <TextField
            label={t("description")}
            id="description"
            name="description"
            value={description}
            onChange={setDescription}
          />
          <DatePickerInput
            name={t("expires")}
            value={expires}
            onChange={setExpires}
          />
          <DatePickerInput name={t("date")} value={date} onChange={setDate} />
          <Checkbox
            label={t("is_period")}
            checked={isPeriod}
            onChange={handleIsPeriod}
          />
          {isPeriod && (
            <Card>
              <div className="flex layout-row order-form-period">
                <div className="order-form-period__item">
                  <DatePickerInput
                    name={t("next_order_date")}
                    value={nextInvoiceDate}
                    onChange={setNextInvoiceDate}
                  />
                </div>
                <div className="order-form-period__item">
                  <Select
                    label={t("month")}
                    options={monthOptions}
                    onChange={setInvoicePeriod}
                    value={invoicePeriod}
                  />
                </div>
                <div className="order-form-period__item">
                  <Select
                    label={t("day")}
                    options={invoiceDayOptions}
                    onChange={setInvoiceDay}
                    value={invoiceDay}
                  />
                </div>
              </div>
            </Card>
          )}
          {filesNode}
          <UploadFile onChange={handleFilesChange} />
          <OrderItems items={orderItems} onChange={handleOrderItemsChange} />
          <div className="order-form__bottom">
            <Button
              submit
              disabled={!title || !number}
              loading={isLoading}
            >
              {t("save")}
            </Button>
            <Button onClick={handleCreateInvoice}>
              {t("create_invoice")}
            </Button>
          </div>
        </FormLayout>
      </Form>
    </div>
  );
}

export default OrderForm;
