import React from "react";
import PropTypes from "prop-types";
import { IndexTable, TextField, Button } from "@shopify/polaris";
import ProductSelect from "../../product-select/product-select";
import { XSmallIcon } from "@shopify/polaris-icons";
import { Props, Event, Item } from "./types";
import VatSelect from "./../../vat-select/vat-select";
import Currency from "../../../components/format/currency/currency";
import "./style.scss";

function OrderItem({ item, onChange, index, remove }: Props) {
  const handleChange = ({ value, name }: Event) => {
    const newItem = { ...item };
    newItem[name as keyof Item] = value;
    onChange(newItem);
  };

  const handleRemoveItem = (id: string) => {
    remove(id);
  };

  return (
    <>
      <IndexTable.Cell>
        <div>{index}</div>
      </IndexTable.Cell>
      <IndexTable.Cell>
        <ProductSelect
          value={item.product}
          onChange={(e: string) => handleChange({ value: e, name: "product" })}
          isRequired
        />
      </IndexTable.Cell>
      <IndexTable.Cell>
        <TextField
          label=""
          id="quantity"
          name="quantity"
          value={item.quantity}
          onChange={(e: string) => handleChange({ value: e, name: "quantity" })}
          autoComplete="off"
        />
      </IndexTable.Cell>
      <IndexTable.Cell>
        <TextField
          label=""
          id="unitPrice"
          name="unitPrice"
          value={item.unitPrice}
          onChange={(e: string) =>
            handleChange({ value: e, name: "unitPrice" })
          }
          autoComplete="off"
        />
      </IndexTable.Cell>
      <IndexTable.Cell>
        <VatSelect
          value={item.vat}
          onChange={(e: string) => handleChange({ value: e, name: "vat" })}
        />
      </IndexTable.Cell>
      <IndexTable.Cell>
        <TextField
          label=""
          id="description"
          name="description"
          value={item.description}
          onChange={(e: string) =>
            handleChange({ value: e, name: "description" })
          }
          autoComplete="off"
        />
      </IndexTable.Cell>
      <IndexTable.Cell>
        <div className="order-item__total">
          <Currency
            value={parseInt(item.quantity) * parseInt(item.unitPrice)}
            isSymbol
          />
        </div>
      </IndexTable.Cell>
      <IndexTable.Cell>
        <Button
          // @ts-ignore
          icon={XSmallIcon}
          onClick={() => handleRemoveItem(item.id)}
        />
      </IndexTable.Cell>
    </>
  );
}

export default OrderItem;

OrderItem.propTypes = {
  item: PropTypes.instanceOf(Object).isRequired,
  index: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
};
