import { useEffect } from "react";
import {
  Page,
  Card,
} from "@shopify/polaris";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "configureStore";
import { fetchCustomField, setDestroy } from "containers/custom-field/custom-field-container.slice";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import DataTable from "components/datatable/Datatable"
import { setModalxComponent, setModalxComponentProps } from "features/modalx/modalx.slice";

function CustomFieldContainer() {
  const { collection, isLoading } = useSelector(
    (state:RootState) => state.customFieldReducer
  );
  
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();
  const { companyId } = useParams();

  const fetchData = (companyId:string) => {
    if(companyId) dispatch(fetchCustomField(companyId));
  }

  const handleCreateCustomField = () => {
    dispatch(setModalxComponent("CustomFieldForm"));
  };

  const onRowClick = ({ id }:{id:string}) => {
    dispatch(setModalxComponent("CustomFieldForm"));
    dispatch(setModalxComponentProps({ id }))
  }

  useEffect(() => {
    if(companyId) fetchData(companyId)
    return () => {
      setDestroy();
    };
  }, [companyId]);

  if (!companyId || !collection) {
    return null
  }

  return (
    <Page
      fullWidth
      primaryAction={{
        content: t("custom_field_create"),
        onAction: handleCreateCustomField,
      }}
      title={t("custom_fields")}
    >
      <Card>
        <DataTable
          collection={collection}
          columns={[
            { key: '#' },
            { key: 'label', name: t('custom_field_label'), onClick: onRowClick },
            { key: 'code', name: t('custom_field_code'), onClick: onRowClick},
            { key: 'type', name: t('custom_field_type'), onClick: onRowClick, translationRequired:true},
          ]}
        />
      </Card>
    </Page>
  )
}

export default CustomFieldContainer
