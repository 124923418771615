import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "hooks";
import {
  Page,
  List,
  Text,
  InlineStack,
  BlockStack,
  Layout,
  Card,
} from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { fetchReservations } from "./reservations.slice";
import Loading from "components/loading/Loading";

function Reservations() {
  const dispatch = useAppDispatch();
  const { companyId } = useParams();
  const { t } = useTranslation();
 
  const {
    reservationsReducer: { reservationsCollection, isLoading },
  } = useAppSelector((state) => state);

  useEffect(() => {
    dispatch(fetchReservations(companyId))
  }, [dispatch, companyId])

  const nodes = reservationsCollection.map(({ title, description, items, dueDate, user, customer, meta }) => {
    const itemsNode = items.map(({ quantity, product: { title, sku } }) => <List.Item>[{sku}] {title} - {quantity}</List.Item>)
    return (
      <Card roundedAbove="sm">
        <BlockStack gap="">
          <BlockStack gap="200">
            <Text as="h2" variant="headingSm">
              {title}
            </Text>
            <Text as="p" variant="bodyMd">
              {description}
            </Text>
            <List type="number">{itemsNode}</List>
            <div>{user.name || user.externalCode}</div>
          </BlockStack>
          <InlineStack align="end">
            {/* <ButtonGroup>
              <Button
                onClick={() => {}}
                accessibilityLabel="veiksmas"
              >
                Veiksmas
              </Button>
              <Button variant="plain">Learn more</Button>
            </ButtonGroup> */}
          </InlineStack>
        </BlockStack>
      </Card>
    )
  })

  return (
    <Layout>
      <Layout.Section>
        <Page
          fullWidth
          title={t("reservations")}
        >
          {isLoading && <Loading />}
          {!isLoading && <BlockStack gap="500">{nodes}</BlockStack>}
        </Page>
      </Layout.Section>
      {/* {productId && (
        <Modal open titleHidden large onClose={handleProductClose}>
          <Modal.Section>
            <Outlet />
          </Modal.Section>
        </Modal>
      )} */}
    </Layout>
  );
}

export default Reservations;
