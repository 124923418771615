import { createSlice } from "@reduxjs/toolkit";
import { sendLoginUrl as sendLoginUrlService } from "../../../features/auth/auth.service";

const LoginSlice = createSlice({
  name: "login",
  initialState: {
    isLoading: false,
    errMsg: null,
    isEmailSent: false,
  },
  /* eslint-disable no-param-reassign */
  reducers: {
    setLoading(state) {
      state.isLoading = true;
    },
    setError(state, action) {
      state.isLoading = false;
      state.isEmailSent = false;
      state.errMsg = action.payload;
    },
    setIsEmailSent(state) {
      state.isLoading = false;
      state.isEmailSent = true;
      state.errMsg = null;
    },
  },
  /* eslint-enable */
});

// Extract the action creators object and the reducer
const { actions, reducer } = LoginSlice;
const { setLoading, setError, setIsEmailSent } = actions;

// Export the reducer, either as a default or named export
export default reducer;

export const sendLoginUrl =
  ({ email }) =>
  async (dispatch) => {
    dispatch(setLoading());
    try {
      await sendLoginUrlService({ email });
      dispatch(setIsEmailSent(true));
    } catch (e) {
      dispatch(setError("email_incorrect"));
    }
  };
