import { createSlice } from "@reduxjs/toolkit";
import { signUp, getInvitationData } from "../../../features/auth/auth.service";

const RegSlice = createSlice({
  name: "reg",
  initialState: {
    isFormValid: false,
    formControls: {
      email: {
        value: "",
        isValid: false,
        validationRules: {
          isRequired: true,
          isEmail: true,
        },
        isTouched: false,
      },
      // password: {
      //   value: "",
      //   isValid: false,
      //   validationRules: {
      //     isRequired: true,
      //     minLength: 8,
      //   },
      //   isTouched: false,
      // },
      // name: {
      //   value: "",
      //   isValid: false,
      //   validationRules: {
      //     isRequired: false,
      //   },
      //   isTouched: false,
      // },
      // phone: {
      //   value: "",
      //   isValid: false,
      //   validationRules: {
      //     isRequired: false,
      //   },
      //   isTouched: false,
      // },
      // companyName: {
      //   value: "",
      //   isValid: false,
      //   validationRules: {
      //     isRequired: true,
      //   },
      //   isTouched: false,
      // },
    },
    isLoading: false,
    isError: false,
    errMsg: null,
  },
  /* eslint-disable no-param-reassign */
  reducers: {
    setLoading(state) {
      state.isLoading = true;
    },
    setEmail(state, action) {
      state.isLoading = false;
      state.formControls.email.value = action.payload.email || "";
    },
    setError(state, action) {
      state.isLoading = false;
      state.isSuccessful = false;
      state.isSuccessfulInvitation = false;
      state.isError = true;
      state.errMsg = action.payload;
    },
    setFormData(state, action) {
      state.formControls = action.payload.validatedFormControls;
      state.isFormValid = action.payload.isFormValid;
    },
    setSuccess(state) {
      state.isSuccessful = true;
    },
    setSuccessInvitation(state) {
      state.isSuccessfulInvitation = true;
    },
  },
  /* eslint-enable */
});

// Extract the action creators object and the reducer
const { actions, reducer } = RegSlice;

// Extract and export each action creator by name
export const {
  setLoading,
  setEmail,
  setError,
  setFormData,
  setSuccess,
  setSuccessInvitation,
} = actions;

// Export the reducer, either as a default or named export
export default reducer;

export const submit = (formData, invitation) => async (dispatch) => {
  dispatch(setLoading());
  try {
    const { email, name, phone, password, companyName } = formData;

    const { id } = await signUp({
      email,
      name,
      phone,
      password,
      companyName,
      invitation,
    });
    if (id && invitation) {
      dispatch(setSuccessInvitation(true));
    } else if (id) {
      dispatch(setSuccess(true));
    } else {
      const e = "Error";
      dispatch(setError(e));
    }

    return true;
  } catch (e) {
    dispatch(setError(e.response.data));
    return false;
  }
};

export const fetchInvitation = (invitation) => async (dispatch) => {
  dispatch(setLoading());
  try {
    const { email } = await getInvitationData(invitation);
    dispatch(setEmail({ email }));
    return true;
  } catch (e) {
    dispatch(setError(e.response.data));
    return false;
  }
};
