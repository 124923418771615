/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/prop-types */
import { useEffect } from "react";
import {
  Page,
  ButtonGroup,
  Card,
  Text,
  BlockStack,
  InlineStack,
  InlineGrid,
  Button,
  Layout,
  IndexTable,
} from "@shopify/polaris"
import { useAppSelector, useAppDispatch } from "hooks"
import { fetchProduct, setDestroy } from "./product-container.slice"
import { setModalxComponent, setModalxComponentProps } from "components/modalx/modalx.slice";
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import "./style.scss"
import Loading from 'components/loading/Loading'
import PrettyDate from "components/format/PrettyDate";
import Currency from "components/format/currency/currency";
import DataTable from "components/datatable/Datatable";
import ProductDetails from "components/product/product-details/product-details"
import {
  EditIcon
} from '@shopify/polaris-icons';

function ProductContainer() {
  
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { productId, companyId } = useParams();
  const {
    productReducer: { product, isLoading },
  } = useAppSelector((state) => state);

  useEffect(() => {
    dispatch(fetchProduct(companyId, productId))
    return () => {
      dispatch(setDestroy())
    };
  },[dispatch, productId, companyId])

  if (isLoading) {
    return <Loading />
  }

  const finvaldaNode = (() => {
    const rowMarkup = Object.entries(product.meta).map(([key, value], index) => {
      return (
        <IndexTable.Row id={key} key={key} position={index}>
          <IndexTable.Cell>
            <div>{key}</div>
          </IndexTable.Cell>
          <IndexTable.Cell>
            <div>{value}</div>
          </IndexTable.Cell>
        </IndexTable.Row>);
    });

    return (
      <IndexTable
        resourceName={{
          singular: "product",
          plural: "products",
        }}
        itemCount={Object.entries(product.meta).length}
        headings={[
          { title: t("title") },
          { title: t("value") },
        ]}
        selectable={false}
      >
        {rowMarkup}
      </IndexTable>
    )
  })()

  return (
    <div className="product-container">
      <Layout>
        <Layout.Section>
          <ProductDetails product={product} />
          <Text as="h2" variant="headingSm">
            {t("product_stock")}
          </Text>
          <DataTable
            collection={product.stock}
            columns={[
              { key: '#' },
              { key: 'name', name: t('name') },
              { key: 'warehouse.name', name: t('warehouse') },
              { key: 'quantity', name: t('quantity'), type: 'number' },
            ]}
          />

          <Text as="h2" variant="headingSm">
            {t("product_reservations")}
          </Text>
          <DataTable
            collection={product.reservations}
            columns={[
              { key: '#' },
              { key: 'reservation.title', name: t('name') },
              { key: 'reservation.description', name: t('description') },
              { key: 'reservation.customer.name', name: t('customer') },
              { key: 'reservation.user.name', name: t('user') },
              { key: 'quantity', name: t('quantity'), type: 'number' },
            ]}
          />
        </Layout.Section>
        <Layout.Section variant="oneThird">
          <Text as="h2" variant="headingSm">
            {t("meta_data")}
          </Text>
          {finvaldaNode}
        </Layout.Section>
      </Layout>
    </div>
  )
}

export default ProductContainer
