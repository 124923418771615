import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Invoice, InvoicesState } from './types'
import { AppDispatch } from '../../configureStore';
import { fetch as fetchInvoices } from './invoices.service'

// Define the initial state using that type
const initialState: InvoicesState = {
  isLoading: false,
  errMsg: null,
  collection: [],
}

const InvoicesSlice = createSlice({
  name: "invoices",
  initialState,
  /* eslint-disable no-param-reassign */
  reducers: {
    setLoading(state) {
      state.isLoading = true
    },
    setError(state, action) {
      state.isLoading = false
      state.errMsg = action.payload
    },
    setCollection(state, action: PayloadAction<Invoice[]>) {
      state.isLoading = false
      state.errMsg = null
      state.collection = action.payload
    },
  },
  /* eslint-enable */
});

// Extract the action creators object and the reducer
const { actions, reducer } = InvoicesSlice;
const { setLoading, setError, setCollection } = actions;

// Export the reducer, either as a default or named export
export default reducer;

export const fetch = () =>
  async (dispatch: AppDispatch) => {
    dispatch(setLoading());
    try {
      const result = await fetchInvoices();
      dispatch(setCollection(result))
    } catch (e) {
      console.error(e)
      dispatch(setError('invoices fetch err'))
    }
  };
