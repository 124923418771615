import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux";
import {
  Button
} from "@shopify/polaris"
import { setModalxComponent, setModalxComponentProps } from "components/modalx/modalx.slice";

type BuildPdfParams = {
    customerId: string,
}

function BuildPdf({ customerId }: BuildPdfParams) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleOnClick = () => {
    dispatch(setModalxComponent("BuildPdfForm"));
    dispatch(setModalxComponentProps({ customerId }));
  }

  return (
    <Button onClick={handleOnClick}>{t('files_build_pdf')}</Button>
  )
}


export default BuildPdf;