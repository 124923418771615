import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchById } from "services/upload-file.service";
import { FileEntity } from 'containers/file/types';


// Define the types for your initial state
interface fileContainerState {
  isLoading: boolean;
  isError: boolean;
  errMsg: string;
  fileEntity: FileEntity | null;
}

// Define the initial state
const initialState: fileContainerState = {
  isLoading: true,
  isError: false,
  errMsg: "",
  fileEntity: null
};



// Create the slice
const FileContainerSlice = createSlice({
  name: "fileContainer",
  initialState,
  reducers: {
    setDestroy() {
      return initialState;
    },
    setLoading(state: fileContainerState, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setError(state: fileContainerState, action: PayloadAction<{ error: string }>) {
      state.isLoading = false;
      state.isError = true;
      state.errMsg = action.payload.error;
    },
    setFileContainer(state: fileContainerState, action: PayloadAction<any>) {
      state.fileEntity = action.payload;
      state.isLoading = false;
    },
  },
});

// Extract the action creators object and the reducer
const { actions, reducer } = FileContainerSlice;

// Extract and export each action creator by name
export const { setDestroy, setLoading, setError, setFileContainer } = actions;

// Export the reducer, either as a default or named export
export default reducer;

// Define the type for the dispatch function
export const fetchFileDetails = ({ fileId, companyId }: { fileId: string, companyId: string }) => async (dispatch: any) => {
  dispatch(setLoading(true));
  try {
    const data = await fetchById(fileId, companyId);
    dispatch(setFileContainer(data));
  } catch (e) {
    const error = "not_fetch";
    dispatch(setError({ error }));
    return false;
  } finally{
    dispatch(setLoading(false));
  }
};
