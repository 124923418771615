import { createSlice } from "@reduxjs/toolkit";
import { fetch as fetchUsers } from "services/user-select.service";
import { fetch } from "./customers.service";

const CustomersSlice = createSlice({
  name: "customers",
  initialState: {
    customersCollection: [],
    isLoading: false,
    isError: false,
    errMsg: null,
    queryValue: null,
    users: {},
    filtersValue: {},
  },
  /* eslint-disable no-param-reassign */
  reducers: {
    setLoading(state) {
      state.isLoading = true;
      state.isError = false;
    },
    setCustomers(state, action) {
      state.isLoading = false;
      state.customersCollection = action.payload.customersCollection;
    },
    setUsers(state, action) {
      for (let i=0; i<action.payload.usersCollection.length; i++) {
        const { id, name } = action.payload.usersCollection[i]
        state.users[id] = name
      }
    },
    setError(state, action) {
      state.isError = true;
      state.errMsg = action.payload.errMsg;
    },
    setFilterValue(state, action) {
      const { key, value } = action.payload;
      state.filtersValue[key] = value;
    },
  },
  /* eslint-enable */
});

// Extract the action creators object and the reducer
const { actions, reducer } = CustomersSlice;

// Extract and export each action creator by name
export const { setLoading, setCustomers, setUsers, setError, setFilterValue } = actions;

// Export the reducer, either as a default or named export
export default reducer;

export const fetchCustomers = (companyId, filtersValue) => async (dispatch, getState) => {
  dispatch(setLoading());
  try {
    const { customersCollection } = await fetch(companyId, filtersValue);
    dispatch(setCustomers({ customersCollection }));
    const { users } = getState().customersReducer;
    //if (Object.keys(users).length === 0) {
      const { usersCollection } = await fetchUsers(companyId);
      dispatch(setUsers({ usersCollection }));
    //}
  } catch (e) {
    dispatch(setError(""));
  }
};
