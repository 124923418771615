import axios from "axios";
import { API_URL } from "constants";

export const createProject = async (formData, companyId) => {
  const url = `${API_URL}/${companyId}/projects`;
  return axios.post(url, formData).then((response) => response.data);
}

export const updateProject = async (formData, companyId) => {
  const url = `${API_URL}/${companyId}/projects/${formData.id}`;
  return axios.patch(url, formData).then((response) => response.data);
}