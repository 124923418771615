/* eslint-disable no-use-before-define */
/* eslint-disable consistent-return */
import React from "react";
import PropTypes from "prop-types";
import { Props, LocalesArguments } from "./types";
const EMPTY_VALUE = "-";

export default function Currency({ isDecimal, value, isSymbol }: Props) {
  const formatValue = getValue(value, isSymbol, isDecimal);
  const title = getTitle();

  return (
    <span className="currency" title={`${title}`}>
      {formatValue}
    </span>
  );

  function getTitle() {
    try {
      if (!value) {
        return;
      }

      return value;
    } catch (e) {
      return EMPTY_VALUE;
    }
  }
}

export function getValue(value: any, isSymbol: any, isDecimal: any) {
  if (!value) {
    return EMPTY_VALUE;
  }

  if (isSymbol) {
    const options: LocalesArguments = {
      style: "currency",
      currency: "EUR",
      minimumFractionDigits: isDecimal ? 2 : 0,
      maximumFractionDigits: isDecimal ? 2 : 0,
    };

    return new Intl.NumberFormat("lt-LT", options).format(value);
  }

  const options: LocalesArguments = {
    minimumFractionDigits: isDecimal ? 2 : 0,
    maximumFractionDigits: isDecimal ? 2 : 0,
  };

  return new Intl.NumberFormat("lt-LT", options).format(value);
}

Currency.propTypes = {
  isDecimal: PropTypes.bool,
  value: PropTypes.number,
  isSymbol: PropTypes.bool,
};

Currency.defaultProps = {
  isDecimal: false,
  isSymbol: false,
  value: 0,
};
