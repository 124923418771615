import React from 'react';
import { Link } from '@shopify/polaris';
import { useSelector } from "react-redux";
import { RootState } from "configureStore";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";


interface Category {
  id: string;
  title: string;
  subcategories?: Category[];
  [key: string]: any;
}

const findParentPath = (
  categories: Category[],
  targetId: string,
  path: Category[] = []
): Category[] | null => {
  for (const category of categories) {
    const currentPath = [...path, category];

    if (category.id === targetId) {
      return currentPath;
    }

    if (category.subcategories) {
      const result = findParentPath(category.subcategories, targetId, currentPath);
      if (result) {
        return result;
      }
    }
  }

  return null;
};

const RenderPath: React.FC<{ path: Category[] }> = ({ path }) => {
  const { companyId } = useParams<{ companyId: string }>();
  if (!path || path.length === 0) return null;

  return (
    <div>
      {path.map((cat, index) => (
        <React.Fragment key={cat.id}>
          {index > 0 && <span> {' --> '} </span>}
          <Link url={`/app/${companyId}/documents/${cat.id}`}>{cat.title}</Link>
        </React.Fragment>
      ))}
    </div>
  );
};

const HeaderLine: React.FC<{ catId: string }> = ({ catId }) => {
  const { t } = useTranslation();
  const { categoriesData } = useSelector(
    (state: RootState) => state.documentCategoryReducer
  );

  const pathToCategory = Array.isArray(categoriesData) ? findParentPath(categoriesData, catId) : [];

  return (
    <div>
      {pathToCategory ? (
        <RenderPath path={pathToCategory} />
      ) : (
        <div>{t('category_not_found')}</div>
      )}
    </div>
  );
};

export default HeaderLine;
