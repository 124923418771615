import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "configureStore";
import {
  Layout,
  Page,
  Card,
  Text,
  BlockStack,
  TextContainer,
  Button,
  ButtonGroup,
  InlineStack,
  EmptyState,
  Spinner
} from "@shopify/polaris";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import UploadFile from "features/upload-file/upload-file";
import Files from "features/files/files";
import { setModalxComponent, setModalxComponentProps } from "features/modalx/modalx.slice";
import { fetchUserDetails, setDestroy } from 'containers/user/user-container.slice';



function UserContainer() {
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();
  const { userId, companyId } = useParams();

  const loadUserData = () => {
    if (userId && companyId){
      dispatch(fetchUserDetails({ userId, companyId }));
    }
  }

  const {userEntity, isLoading }  = useSelector(
    (state:RootState) => state.userContainerReducer
  );

  useEffect(() => {
    loadUserData()
    return () => {
      setDestroy();
    };
  }, [userId, companyId]);

  const handleOnFileChange = () => {
  }

  const handleEditClick = (id: string) => {
    dispatch(setModalxComponent("UserForm"))
    dispatch(setModalxComponentProps({id}))
  }

  if(!userEntity){
    return null
  }

  return (
    <Page
      fullWidth
      backAction={{ content: 'User-page', url: `/app/${companyId}/users` }}
      // title={name}
      titleMetadata={isLoading && <Spinner size="small" />}
    >
      <Layout>
        <Layout.Section>
          <BlockStack gap="500">
            <Card roundedAbove="sm">
              <div className="flex">
                <Text as="h2" variant="headingSm">
                  {t("files")}
                </Text>
                <div className="flex-grow"></div>
                <ButtonGroup>
                  <UploadFile
                    onChange={handleOnFileChange}
                    subject="user"
                    subjectId={userEntity.id}
                    categoryId={null}
                    isCatRequired={false}
                  />
                </ButtonGroup>
              </div>
              {userEntity.files.length > 0 ?
                <Files files={userEntity.files} /> :
                (
                  <EmptyState
                    heading={t('no_files')}
                    image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"
                  >
                    <UploadFile
                      onChange={handleOnFileChange}
                      subject="user"
                      subjectId={userEntity.id}
                      categoryId={null}
                      isCatRequired={false}
                    />
                  </EmptyState>
                )
              }

            </Card>
          </BlockStack>
        </Layout.Section>
        <Layout.Section variant="oneThird">
          <BlockStack gap="500">
            <Card roundedAbove="sm">
              <InlineStack align="end">
                <Button
                  variant="plain"
                  onClick={() => handleEditClick(userEntity.id)}
                  accessibilityLabel="Preview"
                >
                  {t("edit")}
                </Button>
              </InlineStack>
              <TextContainer>
                <Text as="p" variant="bodyMd">
                  <strong>{t('Email')}:</strong> {userEntity.email}
                </Text>
                {userEntity.name ?
                  <Text as="p" variant="bodyMd">
                    <strong>{t("name")}:</strong> {userEntity.name}
                  </Text>
                  :
                  <Text as="p" variant="bodyMd">{t("no_name_provided")}</Text>
                }
                {userEntity.phone ?
                  <Text as="p" variant="bodyMd">
                    <strong>{t("phone")}:</strong> {userEntity.phone}
                  </Text> :
                  <Text as="p" variant="bodyMd">{t("no_phone_number_provided")}</Text>}
                {userEntity.externalCode ?
                  <Text as="p" variant="bodyMd">
                    <strong>{t("external_code")}:</strong> {userEntity.externalCode}
                  </Text> :
                  <Text as="p" variant="bodyMd">{t("no_external_code_provided")}</Text>}
              </TextContainer>
            </Card>
          </BlockStack>
        </Layout.Section>
      </Layout>
    </Page>
  );
}

export default UserContainer;
