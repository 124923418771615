import { useTranslation } from "react-i18next";
import {
  Text,
  ResourceList,
  ResourceItem,
} from '@shopify/polaris';
import { Invoice } from 'types/Invoice'
import Currency from "components/format/currency/currency";
import PrettyDate from "components/format/PrettyDate";
import DataTable from "components/datatable/Datatable";
import { isMobile } from 'react-device-detect';

type Props = {
  invoices: Invoice[];
};

const Invoices = ({ invoices }: Props) => {
  const { t } = useTranslation();
  const resourceName = {
    singular: t('invoice'),
    plural: t('invoices'),
  };

  if (isMobile) {
    return (
      <ResourceList
          resourceName={resourceName}
          items={invoices}
          renderItem={(item) => {
            const { id, date, customerName, number, _amount, _paidAmount, paymentDueDate } = item;
            const _debt = _amount - _paidAmount;

            return (
              <ResourceItem
                id={id}
                url={id}
              >
                <div className="layout-row justify-space">
                  <div>{customerName}</div>
                  <Text variant="bodyMd" fontWeight="bold" as="h3" numeric>
                    {number}
                  </Text>
                </div>
                <div className="layout-row justify-space">
                  <Text as="span" alignment="end" numeric>{<Currency value={_amount} isSymbol={true} />}</Text>
                  {<Text as="span" alignment="end" numeric>{t('debt')}: <Currency value={_debt} isSymbol={true} /></Text>}
                </div>
                <div className="layout-row justify-space">
                  <PrettyDate dateString={date} />
                  <PrettyDate dateString={paymentDueDate} />
                </div>
              </ResourceItem>
            );
          }}
        />)
  }

  return (
    <DataTable
      collection={invoices}
      columns={[
        { key: '#' },
        { key: 'number', name: t('number') },
        { key: 'date', type: 'date', name: t('date') },
        { key: 'customerName', name: t('customer') },
        { key: '_amount', type: 'currency', name: t('total') },
        { key: '_debt', type: 'currency', name: t('debt') },
        { key: 'paymentDueDate', type: 'date', name: t('payment_due_date') }
      ]}
    />
  )
};

export default Invoices;
