import axios from "axios";
import { API_URL } from "../../constants";

export const fetch = (filtersValue) => {
  const url = `${API_URL}/products/fetch`;
  return axios.post(url, { filtersValue }).then((response) => response.data);
};

export const post = (data) => {
  const url = `${API_URL}/products/post`;
  return axios.post(url, data).then((response) => response.data);
};

export const fetchById = (productId) => {
  const url = `${API_URL}/products/fetchById/${productId}`;
  return axios.get(url).then((response) => response.data);
};
