import {
    Text,
    Card,
    Box,
    BlockStack,
    InlineGrid,
    Button,
  } from "@shopify/polaris";
  import { useDispatch } from "react-redux";
  import { useTranslation } from "react-i18next";
  import { setModalxComponent, setModalxComponentProps } from "components/modalx/modalx.slice";
  import ProjectColumnSelect from 'components/project-column-select/project-column-select'
  import UserSelect from 'features/user-select/user-select'
  
  function ProjectDetails({ id, projectBoardId, name, description, projectBoardColumnEntity, userEntity, updateHandler }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    
    const projectName = name ? <Text as="span">{t('title')}  <Text as="span" variant="bodyMd"> :{name}</Text></Text> : <Text as="p" tone="subdued">{t("no_name_provided")}</Text>;
    const projectDescription = description ? <Text as="span">{t('description')}  <Text as="span" variant="bodyMd"> :{description}</Text> </Text>: <Text as="p" tone="subdued">{t("no_description_provided")}</Text>;

  
    const handleEditProjectClick = () => {
        dispatch(setModalxComponent('ProjectForm'))
        dispatch(setModalxComponentProps({ id: id, projectBoardId }))
    };

    const projectColumnHandler = (val) => {
      updateHandler(val, 'project_column')
    }

    const userHandler = (val) => {
      updateHandler(val, 'user')
    }
  
    return (
      <Card roundedAbove="sm">
        <InlineGrid columns="1fr auto">
          <Text as="h2" variant="headingSm">
          </Text>
          <Button
            variant="plain"
            onClick={handleEditProjectClick}
            accessibilityLabel="Preview"
          >
            {t("edit")}
          </Button>
        </InlineGrid>
        <Box paddingBlockStart="200" paddingBlockEnd='200'>
          <ProjectColumnSelect
            value={projectBoardColumnEntity.id}
            onChange={projectColumnHandler}
            projectBoardId={projectBoardId}
            isRequired={true}
          />
        </Box>
        <Box paddingBlockStart="200" paddingBlockEnd='200'>
          <UserSelect
            onChange={userHandler}
            value={userEntity.id}
          />
        </Box>
        <InlineGrid>
            {projectName}
        </InlineGrid>

        <InlineGrid>
            {projectDescription}
        </InlineGrid>
      </Card>
    );
  }
  
  export default ProjectDetails;
  